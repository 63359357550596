import * as React from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import Stack from "@mui/material/Stack";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepConnector, { stepConnectorClasses } from "@mui/material/StepConnector";

const color = "#FF6200";
const revertColor = "#FFF";

const QontoConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 10,
    left: "calc(-50% + 12px)",
    right: "calc(50% + 12px)"
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: color
    }
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: color
    }
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor: theme.palette.mode === "dark" ? theme.palette.grey[800] : "#FF6200",
    borderTopWidth: 3,
    borderRadius: 1
  }
}));

const QontoStepIconRoot = styled("div")(({ theme, ownerState }) => ({
  color: theme.palette.mode === "dark" ? theme.palette.grey[700] : "#FFF",
  display: "flex",
  height: 22,
  alignItems: "center",
  ...(ownerState.active && {
    color
  }),
  "& .QontoStepIcon-completedIcon": {
    color,
    zIndex: 1,
    fontSize: 18
  },
  "& .QontoStepIcon-circle": {
    width: 26,
    height: 26,
    borderRadius: "50%",
    backgroundColor: "currentColor",
    border: "2px solid #FF6200"
  },
  "& .QontoStepIcon-circle-completed": {
    backgroundColor: color
  }
}));

function QontoStepIcon(props) {
  const { active, completed, className } = props;

  return (
    <QontoStepIconRoot ownerState={{ active }} className={className}>
      {completed ? (
        <div className="QontoStepIcon-circle QontoStepIcon-circle-completed" />
      ) : (
        <div className="QontoStepIcon-circle" />
      )}
    </QontoStepIconRoot>
  );
}

QontoStepIcon.propTypes = {
  active: PropTypes.bool,
  className: PropTypes.string,
  completed: PropTypes.bool
};

QontoStepIcon.defaultProps = {
  active: false,
  className: "default",
  completed: false
};

export default function StepComponent({ currentStep, steps }) {
  let stepCount = 0;
  return (
    <Stack sx={{ width: "100%" }} spacing={4}>
      <Stepper alternativeLabel activeStep={currentStep} connector={<QontoConnector />}>
        {steps.map(label => (
          <Step key={Math.random()}>
            <StepLabel StepIconComponent={QontoStepIcon} className="stepper_label">
              <b>{label}</b>
            </StepLabel>
            {stepCount <= currentStep ? (
              <span className="stepper_count" style={{ color: revertColor }}>
                {++stepCount}
              </span>
            ) : (
              <span className="stepper_count" style={{ color: color }}>
                {++stepCount}
              </span>
            )}
          </Step>
        ))}
      </Stepper>
    </Stack>
  );
}
