export const module11 = value => {
  let base = 2;
  let module11 = 11;

  let codes = {
    1: 1,
    2: 2,
    3: 3,
    4: 4,
    5: 5,
    6: 6,
    7: 7,
    8: 8,
    9: 9,
    10: "K",
    11: 0
  };

  if (value.length < 7) {
    return true;
  }

  const checkDigit = value.substring(value.length - 1);
  value = value.substring(0, value.length - 1);

  const numbersArray = value.split("");
  const numbersArrayReverse = numbersArray.reverse("");

  const multiplier = numbersArrayReverse.map(function(number) {
    const cl = parseFloat(number * base);
    base = base === 7 ? 2 : ++base;
    return cl;
  });

  const sum = multiplier.reduce(function(previousValue, currentValue) {
    return currentValue + previousValue;
  });

  let validDigit = module11 - parseFloat(sum % module11);

  validDigit = codes[validDigit];

  return validDigit.toString() === checkDigit;
};
