const SellerServiceFieldMap = {
  "sellerData.email": "adminEmail",
  "sellerData.displayName": "fantasyName",
  "sellerData.businessName": "shopName",
  "sellerData.businessRegNumber": "rutCompany",
  "sellerData.businessRegistrationType": "businessRegistrationType",
  "sellerData.businessRegistrationCategory": null,
  "sellerData.country": "country",
  "sellerData.economicActivityCode": "economicActivity",
  "sellerData.legalForm": "legalForm",
  "sellerData.geography": null,
  "sellerData.hasConflictOfInterest": "conflictOfInterest",
  "sellerData.personName": "personNameWithConflictOfInterest",
  "sellerData.typeOfConflict": "typeOfConflict",
  "sellerData.knowsApep": "hasPepRelationship",
  "sellerData.pepExecutiveName": "pepName",
  "sellerData.kinshipType": "relationshipWithPep",
  "sellerData.crimePreventionFreeCompetition": "crimePrevention",
  "sellerData.crimePreventionFreeCompetitionDetail": "crimePreventionObservation",
  "sellerData.confirmTerm": "confirmTerm",
  "sellerData.operatorCode": null,
  "sellerData.personalInfoAuthorization": "personalInfoAuthorization",
  "sellerData.acceptSearchingCentralRisk": "acceptSearchingCentralRisk",
  "sellerData.bankName": "bankName",
  "sellerData.bankAccountNumber": "bankAccountNumber",
  "sellerData.isTaxResponsible": "isTaxResponsible",

  "sellerData.address.address2code": "municipalHeadOffice",
  "sellerData.address.addressStreet": "addressHeadOffice",
  "sellerData.address.country": "country",

  "sellerData.contacts.contact:Admin.documentNumber": "adminDocumentNumber",
  "sellerData.contacts.contact:Admin.firstName": "adminFirstName",
  "sellerData.contacts.contact:Admin.lastName": "adminLastName",
  "sellerData.contacts.contact:Admin.documentType": null,
  "sellerData.contacts.contact:Admin.email": "adminEmail",
  "sellerData.contacts.contact:Admin.phoneNumber": "adminPhone",

  "sellerData.contacts.contact:PersonInCharge.documentNumber": "legalRepDocumentNumber",
  "sellerData.contacts.contact:PersonInCharge.firstName": "legalRepName",
  "sellerData.contacts.contact:PersonInCharge.lastName": "legalRepLastName",
  "sellerData.contacts.contact:PersonInCharge.documentType": null,
  "sellerData.contacts.contact:PersonInCharge.email": "legalRepEmail",
  "sellerData.contacts.contact:PersonInCharge.phoneNumber": "adminPhone",

  "sellerData.warehouses.warehouse.warehouseType:Shipment.fullName": "shipmentWarehouseContactName",
  "sellerData.warehouses.warehouse.warehouseType:Shipment.email": "shipmentWarehouseContactEmail",
  "sellerData.warehouses.warehouse.warehouseType:Shipment.phoneNumber": "shipmentWarehouseContactPhone",
  "sellerData.warehouses.warehouse.warehouseType:Shipment.address2code": "municipalShipmentWarehouse",
  "sellerData.warehouses.warehouse.warehouseType:Shipment.addressStreet": "addressShipmentWarehouse",
  "sellerData.warehouses.warehouse.warehouseType:Shipment.country": "country",
  "sellerData.warehouses.warehouse.warehouseType:Shipment.customercareGps": null,

  "sellerData.warehouses.warehouse.warehouseType:Return.fullName": "returnWarehouseContactName",
  "sellerData.warehouses.warehouse.warehouseType:Return.email": "returnWarehouseContactEmail",
  "sellerData.warehouses.warehouse.warehouseType:Return.phoneNumber": "returnWarehouseContactPhone",
  "sellerData.warehouses.warehouse.warehouseType:Return.address2code": "municipalReturnWarehouse",
  "sellerData.warehouses.warehouse.warehouseType:Return.addressStreet": "addressReturnWarehouse",
  "sellerData.warehouses.warehouse.warehouseType:Return.country": "country",
  "sellerData.warehouses.warehouse.warehouseType:Return.customercareGps": null,

  "/sellerData.email": "adminEmail",
  "/sellerData.displayName": "fantasyName",
  "/sellerData.businessName": "shopName",
  "/sellerData.businessRegNumber": "rutCompany",
  "/sellerData.businessRegistrationType": "businessRegistrationType",
  "/sellerData.businessRegistrationCategory": null,
  "/sellerData.country": "country",
  "/sellerData.economicActivityCode": "economicActivity",
  "/sellerData.legalForm": null,
  "/sellerData.geography": null,
  "/sellerData.hasConflictOfInterest": "conflictOfInterest",
  "/sellerData.personName": "personNameWithConflictOfInterest",
  "/sellerData.typeOfConflict": "typeOfConflict",
  "/sellerData.knowsApep": "hasPepRelationship",
  "/sellerData.pepExecutiveName": "pepName",
  "/sellerData.kinshipType": "relationshipWithPep",
  "/sellerData.crimePreventionFreeCompetition": "crimePrevention",
  "/sellerData.crimePreventionFreeCompetitionDetail": "crimePreventionObservation",
  "/sellerData.confirmTerm": "confirmTerm",
  "/sellerData.operatorCode": null,

  "/sellerData/address.address2code": "municipalHeadOffice",
  "/sellerData/address.addressStreet": "addressHeadOffice",
  "/sellerData/address.country": "country",

  "/sellerData/contacts/contact/0.documentNumber": "adminDocumentNumber",
  "/sellerData/contacts/contact/0.firstName": "adminFirstName",
  "/sellerData/contacts/contact/0.lastName": "adminLastName",
  "/sellerData/contacts/contact/0.documentType": null,
  "/sellerData/contacts/contact/0.email": "adminEmail",
  "/sellerData/contacts/contact/0.phoneNumber": "adminPhone",

  "/sellerData/contacts/contact/1.documentNumber": "legalRepDocumentNumber",
  "/sellerData/contacts/contact/1.firstName": "legalRepName",
  "/sellerData/contacts/contact/1.lastName": "legalRepLastName",
  "/sellerData/contacts/contact/1.documentType": null,
  "/sellerData/contacts/contact/1.email": "legalRepEmail",
  "/sellerData/contacts/contact/1.phoneNumber": "adminPhone",

  "/sellerData/warehouses/warehouse/0/address.fullName": "shipmentWarehouseContactName",
  "/sellerData/warehouses/warehouse/0/address.email": "shipmentWarehouseContactEmail",
  "/sellerData/warehouses/warehouse/0/address.phoneNumber": "shipmentWarehouseContactPhone",
  "/sellerData/warehouses/warehouse/0/address.address2code": "municipalShipmentWarehouse",
  "/sellerData/warehouses/warehouse/0/address.addressStreet": "addressShipmentWarehouse",
  "/sellerData/warehouses/warehouse/0/address.country": "country",
  "/sellerData/warehouses/warehouse/0/address.customercareGps": null,

  "/sellerData/warehouses/warehouse/1/address.fullName": "returnWarehouseContactName",
  "/sellerData/warehouses/warehouse/1/address.email": "returnWarehouseContactEmail",
  "/sellerData/warehouses/warehouse/1/address.phoneNumber": "returnWarehouseContactPhone",
  "/sellerData/warehouses/warehouse/1/address.address2code": "municipalReturnWarehouse",
  "/sellerData/warehouses/warehouse/1/address.addressStreet": "addressReturnWarehouse",
  "/sellerData/warehouses/warehouse/1/address.country": "country",
  "/sellerData/warehouses/warehouse/1/address.customercareGps": null
};

export default SellerServiceFieldMap;
