import React from "react";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const Element = props => {
    const {t} = useTranslation();
    return (
        <Button 
            className="input_button_prev"
            variant="default" 
            type="submit" 
            name={props.fieldConfig.name}
            id={props.fieldConfig.id ?? props.fieldConfig.name}
            onClick={props.onClick}
        >{t(props.fieldConfig.default)}</Button>
    )
}

export default Element;
