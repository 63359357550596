export const actionTypes = {
  MOVE_NEXT_STEP: "MOVE_NEXT_STEP",
  GOTO_NEXT_STEP: "GOTO_NEXT_STEP",
  GOTO_PREV_STEP: "GOTO_PREV_STEP",
  PRESERVE_GLOBAL_STATE: "PRESERVE_GLOBAL_STATE",
  RESET_GLOBAL_STATE: "RESET_GLOBAL_STATE",
  SET_COUNTRY: "SET_COUNTRY",
  RESET_COUNTRY: "RESET_COUNTRY",
  SUBMIT_FORM: "SUBMIT_FORM",
  INVALIDATE_CURRENT_STEP: "INVALIDATE_CURRENT_STEP",
  ACCORDIONS_ACTIVE_COMPLETE: "ACCORDIONS_ACTIVE_COMPLETE",
  USER_IS_DRAGGING: "USER_IS_DRAGGING",
  USER_ISNT_DRAGGING: "USER_ISNT_DRAGGING",
  AUTOFILL_ACTION: "AUTOFILL_ACTION",
  SET_SELLER_CREATION_ERRORS: "SET_SELLER_CREATION_ERRORS",
  SET_IS_REQUIRED: "SET_IS_REQUIRED",
  SET_HIDDEN_FIELDS: "SET_HIDDEN_FIELDS",
  OVERWRITE_FORM_CONFIG: "OVERWRITE_FORM_CONFIG",
  OVERWRITE_FIELD_CONFIG: "OVERWRITE_FIELD_CONFIG",
  TOGGLE_OVERLAY: "TOGGLE_OVERLAY",
  SET_ASYNC_VALIDATIONS: "SET_ASYNC_VALIDATIONS",
  SET_DOCUMENT: "SET_DOCUMENT",
  DELETE_DOCUMENT: "DELETE_DOCUMENT",
  SET_FIELD_MUST_RUN_VALIDATIONS: "SET_FIELD_MUST_RUN_VALIDATIONS",
  DELETE_FIELD_MUST_RUN_VALIDATIONS: "DELETE_FIELD_MUST_RUN_VALIDATIONS",
  SET_CONFIRMED_VALUES: "SET_CONFIRMED_VALUES",
  SET_FORM_REGISTRATION_STATUS: "SET_FORM_REGISTRATION_STATUS"
};

export const REASON_SEPARATOR = ",";
