import { Col, Row } from 'react-bootstrap';
import InputSubmit from  "../Input/InputSubmit";
import fistImage from "../../../static/images/fist.png";
import { useStateValue } from '../../../store/StateProvider';
import constants from '../../../config/constants';

const SuccessPage = () => {
    const [{formValues}] = useStateValue();

    const subtitle = `Ya hiciste tu parte, ahora es nuestro turno. En las próximas 24 horas te contactaremos al correo ${ formValues[constants.INPUT_ID_ADMIN_EMAIL] ?? formValues[constants.INPUT_ID_LEGAL_REPRESENTATIVE_EMAIL] ?? 'que ingresaste'}. Por ahora, puedes revisar nuestras capacitaciones de Falabella Seller Center.`;
    
    return (
        <section className="content">
            <Row>
                <Col>
                    <div className="content_box_centered">
                        <div className="title_image">
                            <img className="bullet_img" alt="ok" src={fistImage} />&nbsp;
                        </div>
                        <h3 className="title">¡Felicidades, recibimos tu solicitud de registro!</h3>
                        <p className="sub_title">{ subtitle }</p>
                        <div className="button_container">
                            <InputSubmit fieldConfig={{name: "Submit", default: "buttons.trainings"}}  />
                        </div>
                    </div>
                </Col>
            </Row>
        </section>
    )
}

export default SuccessPage;
