import React from "react";
import Spinner from "./Spinner";

function SpinnerOverlay() {
  return (
    <div className="spinner-overlay">
      <div className="overlay" />
      <div className="overlay-element">
        <Spinner size={"45px"} speed={0.75} />
      </div>
    </div>
  );
}

export default SpinnerOverlay;
