import React from "react";
import { Button } from "react-bootstrap";

const SubmitControl = props => {
    let  fieldClass = "";
    fieldClass += props.fieldConfig.class; 
    
    return (
        <Button 
            variant="success" 
            className={fieldClass}
            type="submit" 
            name={props.fieldConfig.name}
            id={props.fieldConfig.name}
        >{props.fieldConfig.default}</Button>
    )
}

export default SubmitControl;
