import React from "react";
import { useTranslation } from "react-i18next";
import ProgressBar from "../../../components/ProgressBar/ProgressBar.js";
import FormContainer from '../../FormContainer/FormContainer.js';
import { useStateValue } from "../../../store/StateProvider.js";

const Formpage = () => {
  const { t } = useTranslation();
  const [{ formConfig }] = useStateValue();

    return (
      <section className="content">
        <div className="content_plain">
          <h1>{t("content.millionsPeoples")}</h1>
          <h4 className='lato'>{t("content.registerInvitation")}</h4>
        </div>
        <ProgressBar
          formConfig={formConfig}
        />
        <FormContainer
          formConfig={formConfig}
        />
      </section>
    )
};
export default Formpage;