import React from "react";

const Spinner = (props) => {
    const { color, speed, size } = props;
    
    const style = {
        borderTop: `2px solid ${color}`,
        borderRight: `2px solid ${color}`,
        width: size,
        height: size,
        animation: `spinner ${speed}s linear infinite`
    };
  
    return (
        <div className="spinner" style={style}></div>
    );
  }
  
  Spinner.defaultProps = {
    color: "var(--orange-strong)",
    speed: 0.5,
    size: "20px"
  };
  
  export default Spinner;
  