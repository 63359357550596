import React from "react";
import { Button } from "react-bootstrap";

const ButtonControl = ({ fieldConfig, onClick }) => {
    let fieldId = fieldConfig.id ? fieldConfig.id : fieldConfig.name;

    return (
        <Button 
            className={fieldConfig.class}
            variant="default"
            type="button" 
            name={fieldConfig.name}
            id={fieldId}
            onClick={onClick}
        >{fieldConfig.default}</Button>
    )
}

export default ButtonControl;
