import constants from "../config/constants";

const CustomHandler = (customHandlerMethod, data) => {
  const getFormattedRutNaturalPerson = data => {
    if (/^\d+$/g.test(data)) {
      return data;
    }

    if (data.length < 8) {
      return data.replace(/[^\d]/g, "");
    }

    if (/[k]/g.test(data.substring(data.length - 1))) {
      return data.replace(/[k]/g, "K");
    }

    return data.replace(/[^\dkK]/g, "");
  };

  const getFormattedRutLegalPerson = data => {
    if (/^\d+$/g.test(data)) {
      return data;
    }

    if (data.length < 9) {
      return data.replace(/[^\d]/g, "");
    }

    if (/[k]/g.test(data.substring(data.length - 1))) {
      return data.replace(/[k]/g, "K");
    }

    return data.replace(/[^\dkK]/g, "");
  };

  const getOnlyLettersAndSpaces = data => {
    return data.replace(/[^a-zA-Z\s\u00f1\u00d1]/g, "");
  };

  const getOnlyNumbers = data => {
    return data.replace(/[^\d]/g, "");
  };

  const getOnlyLettersAndNumbers = data => {
    return data.replace(/[^A-Za-z0-9]/g, "");
  };

  const getOnlyLettersNumbersAndSpaces = data => {
    return data.replace(/[^A-Za-z0-9\s\u00f1\u00d1]/g, "");
  };

  const getOnlyLettersNumbersSpacesAndSpecialCharacters = data => {
    return data.replace(/[^0-9A-Za-z&'.,\s]+$/, "");
  };

  const getValidEmailCharacters = data => {
    return (data === ".") ? "" : data.replace(/[^0-9A-Za-z&'.@!#$%&’*+-/=?^_`{|}~\s]+$/, "");
  };

  switch (customHandlerMethod) {
    case constants.INPUT_HANDLER_FORMATTED_RUT_NATURAL_PERSON:
      return getFormattedRutNaturalPerson(data);
    case constants.INPUT_HANDLER_FORMATTED_RUT_LEGAL_PERSON:
      return getFormattedRutLegalPerson(data);
    case constants.INPUT_HANDLER_ONLY_NUMBERS:
      return getOnlyNumbers(data);
    case constants.INPUT_HANDLER_ONLY_LETTERS_AND_SPACES:
      return getOnlyLettersAndSpaces(data);
    case constants.INPUT_HANDLER_ONLY_LETTERS_AND_NUMBERS:
      return getOnlyLettersAndNumbers(data);
    case constants.INPUT_HANDLER_ONLY_LETTERS_NUMBERS_AND_SPACES:
      return getOnlyLettersNumbersAndSpaces(data);
    case constants.INPUT_HANDLER_ONLY_LETTERS_NUMERS_SPACES_AND_ESPECIAL:
      return getOnlyLettersNumbersSpacesAndSpecialCharacters(data);
    case constants.INPUT_HANDLER_ONLY_VALID_EMAILS_CHARS:
      return getValidEmailCharacters(data);
    default:
      return data;
  }
};

export default CustomHandler;
