import axios from "axios";
import constants from "../../config/constants";

const COUNTRY_ID = JSON.parse(process.env.REACT_APP_COUNTRY_ID);

const KEY_ID = "id";
const KEY_ADDRESSCODE = "address2code";
const KEY_MUNICIPAL_CODE = "municipalCode";
const KEY_BANK_CODE = "sellerBankCode";

const VALUE_CODE = "code";
const VALUE_ECONOMIC_ACTIVITY = "economicActivityName";
const VALUE_REGION = "name";
const VALUE_CITY = "name";
const VALUE_MUNICIPAL = "name";
const VALUE_MUNICIPALNAME = "municipalname";
const VALUE_BANK = "sellerBankName";

const OUTPUT_FORMAT_REGIONS_OPTION_LIST = "regionsOptionList";
const OUTPUT_FORMAT_CITY_OPTION_LIST = "cityOptionList";
const OUTPUT_FORMAT_MUNICIPALS = "municipalOptionList";
const OUTPUT_FORMAT_ECONOMIC_ACTIVITY_LIST = "economicActivityList";
const OUTPUT_FORMAT_BANK_LIST = "banksOptionList";
const OUTPUT_FORMAT_FPAY_EMAIL_VALIDATION = "emailExistinFpay";


const getFormattedOutput = (sourceData, format, valueKey, labelKey) => {
  if (sourceData === undefined) {
    return [];
  }

  let output;
  switch (format) {
    case OUTPUT_FORMAT_FPAY_EMAIL_VALIDATION:
      output = sourceData.data.exist;
      break;
    case OUTPUT_FORMAT_ECONOMIC_ACTIVITY_LIST:
      output = sourceData.map(item => {
        return `${item[labelKey]} - ${item[valueKey]}`;
      });
      break;
    case OUTPUT_FORMAT_REGIONS_OPTION_LIST:
    case OUTPUT_FORMAT_CITY_OPTION_LIST:
    case OUTPUT_FORMAT_MUNICIPALS:
    case OUTPUT_FORMAT_BANK_LIST:
    default:
      output = sourceData.map(item => {
        return {
          label: item[labelKey],
          value: item[valueKey]
        };
      });
      break;
  }

  return output;
};

const getHeaders = () => {
  let headers = {
    "Content-Type": "Application/json"
  };

  return headers;
};

const getFpayEmailValidations = async (params) => {
  const { email, country } = params;
  let url = `${process.env.REACT_APP_SERVICE_URL_BFF}/${constants.END_POINT_FPAY_EMAIL_VALIDATION}`;
  let options = {
    headers: getHeaders()
  };

  if (country && email){
    options.params = {
      emailId: email,
      country: country.toLowerCase()
    };
  }

  return await axios.get(url, options)
    .then(success => {
      const emailExistence = success.data.data;
      if(emailExistence.exist === true) window._satellite.track('existant Fpay account', {email})
      return success.data;
    })
    .catch(() => {
      return { data: { exist: false }};
    });

}

const getEconomicActivityEndpointData = async params => {
  let url = process.env.REACT_APP_SERVICE_URL_BFF;
  if (params.countryCode !== undefined) {
    url += `/${constants.END_POINT_ECONOMIC_ACTIVITY}/${COUNTRY_ID[params.countryCode]}`;
  }

  const response = await axios.get(url, {
    headers: getHeaders()
  });

  return response.data.data;
};

const getRegionsEndpointData = async params => {
  let url = process.env.REACT_APP_SERVICE_URL_BFF;
  if (params.countryCode !== undefined) {
    url += `/${constants.END_POINT_REGIONS}/${COUNTRY_ID[params.countryCode]}`;
  }

  const response = await axios.get(url, {
    headers: getHeaders()
  });

  return response.data.data;
};

const getBanksEndpointData = async params => {
  let url = process.env.REACT_APP_SERVICE_URL_BFF;
  if (params.countryCode !== undefined) {
    url += `/${constants.END_POINT_BANKS}/${COUNTRY_ID[params.countryCode]}`;
  }

  const response = await axios.get(url, {
    headers: getHeaders()
  });

  return response.data.data;
};

const getCityEndpointData = async params => {
  if (params.regionId === undefined || !params.regionId) {
    return;
  }

  let url = process.env.REACT_APP_SERVICE_URL_BFF;
  url += `/${constants.END_POINT_CITY}/${params.regionId}`;

  const response = await axios.get(url, {
    headers: getHeaders()
  });

  return response.data.data;
};

const getCountryCitiesEndpointData = async params => {
  let url = process.env.REACT_APP_SERVICE_URL_BFF;
  url += `/${constants.END_POINT_FACO_COUNTRY_CITY}/${COUNTRY_ID[params.countryCode]}`;

  const response = await axios.get(url, {
    headers: getHeaders()
  });
  
  return response.data.data;
}

const getMunicipalEndpointData = async params => {
  if (params.cityId === undefined || !params.cityId) {
    return;
  }

  let url = process.env.REACT_APP_SERVICE_URL_BFF;
  url += `/${constants.END_POINT_MUNICIPAL}/${params.cityId}`;

  const response = await axios.get(url, {
    headers: getHeaders()
  });

  return response.data.data;
};

const getRegionMunicipalEndpointData = async params => {
  if (params.regionId === undefined || !params.regionId) {
    return;
  }

  let url = process.env.REACT_APP_SERVICE_URL_BFF;
  url += `/${constants.END_POINT_FACL_REGION_MUNICIPALS}/${params.regionId}`;

  const response = await axios.get(url, {
    headers: getHeaders()
  });

  return response.data.data;
};

export const postUploadFile = file => {
  const url = `${process.env.REACT_APP_SERVICE_URL_BFF}/${constants.END_POINT_UPLOAD_DOCUMENT}`;
  const axiosConfig = {
    headers: {
      "content-type": "multipart/form-data"
    }
  };
  const formData = new FormData();
  formData.append("file", file);

  return axios.post(url, formData, axiosConfig);
};

export const createSellerService = async payload => {
  let url = process.env.REACT_APP_SERVICE_URL_BFF;
  url += `/${constants.END_POINT_SELLER}`;

  try {
    const response = await axios.post(url, payload, {
      headers: getHeaders()
    });

    if(response.status === 201) {
      window._satellite.track('successful', response.data)
    }else{
      window._satellite.track('error', {})
    }

    return response;
  } catch (error) {
    return error.response;
  }
};

const serviceBff = async (endPoint, params) => {
  let sourceData;
  switch (endPoint) {
    case constants.END_POINT_FPAY_EMAIL_VALIDATION:
      sourceData = await getFpayEmailValidations(params);
      return getFormattedOutput(sourceData, OUTPUT_FORMAT_FPAY_EMAIL_VALIDATION);
    case constants.END_POINT_ECONOMIC_ACTIVITY:
      sourceData = await getEconomicActivityEndpointData(params);
      return getFormattedOutput(sourceData, OUTPUT_FORMAT_ECONOMIC_ACTIVITY_LIST, VALUE_ECONOMIC_ACTIVITY, VALUE_CODE);
    case constants.END_POINT_BANKS:
      sourceData = await getBanksEndpointData(params);
      return getFormattedOutput(sourceData, OUTPUT_FORMAT_BANK_LIST, KEY_BANK_CODE, VALUE_BANK);
    case constants.END_POINT_REGIONS:
      sourceData = await getRegionsEndpointData(params);
      return [
        { label: "Ingresa una región", value: "" },
        ...getFormattedOutput(sourceData, OUTPUT_FORMAT_REGIONS_OPTION_LIST, KEY_ID, VALUE_REGION)
      ];
    case constants.END_POINT_CITY:
      sourceData = await getCityEndpointData(params);
      return [
        { label: "Ingresa una ciudad", value: "" },
        ...getFormattedOutput(sourceData, OUTPUT_FORMAT_CITY_OPTION_LIST, KEY_ID, VALUE_CITY)
      ];
    case constants.END_POINT_FACO_COUNTRY_CITY:
      sourceData = await getCountryCitiesEndpointData(params);
      return [
        { label: "Ingresa una departamento", value: "" },
        ...getFormattedOutput(sourceData, OUTPUT_FORMAT_CITY_OPTION_LIST, KEY_ID, VALUE_CITY)
      ];
    case constants.END_POINT_MUNICIPAL:
      sourceData = await getMunicipalEndpointData(params);
      return [
        { label: "Ingresa un municipio", value: "" }, 
        ...getFormattedOutput(sourceData, OUTPUT_FORMAT_MUNICIPALS, KEY_MUNICIPAL_CODE, VALUE_MUNICIPAL)
      ];
    case constants.END_POINT_FACL_REGION_MUNICIPALS:
      sourceData = await getRegionMunicipalEndpointData(params);
      return [
        { label: "Ingresa una comuna", value: "" }, 
        ...getFormattedOutput(sourceData, OUTPUT_FORMAT_MUNICIPALS, KEY_ADDRESSCODE, VALUE_MUNICIPALNAME)
      ];
    default:
      return [];
  }
};

export default serviceBff;
