import React from "react";
import "./ProgressBar.scss"
import StepComponent from "../UI/Stepper/Stepper";
import { useStateValue } from "../../store/StateProvider";

const ProgressBar = ({formConfig}) => {
  const [{currentStep}] = useStateValue();
  const steps = formConfig.filter(step => step.section.stepperLabel).map(step => step.section.stepperLabel);

  return (
    formConfig[currentStep].section.stepperNumber ?
      <div className="progressBar">
          <StepComponent steps={steps} currentStep={currentStep} />
      </div>
    :
    null
  )
}

export default ProgressBar;
