import React from "react";
import { Button } from "react-bootstrap";
import iconClip from "../../../static/svgs/icon-clip.svg";

const ButtonWithIcon = (props) => {
    const {id, onClick, text, className, useIcon, icon, iconAlt} = props;
    return (
        <Button 
            className={className}
            type="button" 
            name={`btn-reduced-${id}`}
            id={`btn-reduced-${id}`}
            onClick={onClick}
        >
            {text}
            { 
                useIcon &&
                <img src={icon} alt={iconAlt}></img>
            }
        </Button>
    )
}

ButtonWithIcon.defaultProps = {
    text: `Adjuntar documento `,
    useIcon: true,
    icon: iconClip,
    iconAlt: "clip",
    className: "btn btn-light d-md-none"
};

export default ButtonWithIcon;