class Warehouse {
  constructor(warehouseType, fullName, email, phoneNumber, address, customercareGps) {
    this.warehouse = {
      warehouseType: warehouseType,
      fullName: fullName,
      email: email,
      phoneNumber: +phoneNumber,
      address: address,
      customercareGps: customercareGps
    };
  }

  getData() {
    return this.warehouse;
  }
}

export default Warehouse;
