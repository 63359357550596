import chileFormConfig from "./CL/form";
import colombiaFormConfig from "./CO/form";
import peruFormConfig from "./PE/form";
import constants from "./constants";
import colombiaLegalPerson from "./CO/legalPerson";
import colombiaNaturalPerson from "./CO/naturalPerson";

const COUNTRY_MAP = {
  CL: chileFormConfig,
  CO: colombiaFormConfig,
  PE: peruFormConfig
};

const ADDITIONAL_FLOWS = {
  CO: {
    [constants.NATURAL_PERSON_VALUE]: colombiaNaturalPerson,
    [constants.LEGAL_PERSON_VALUE]: colombiaLegalPerson
  }
};
const flowSelectorByTypeOfPersonAndCountry = (typeOfPerson, country) => {
  return ADDITIONAL_FLOWS[country][typeOfPerson];
};

const existsAdditionalFlow = (typeOfPerson, country) => {
  return Boolean(ADDITIONAL_FLOWS[country]?.[typeOfPerson]);
};

export const formConfigSelector = (country, legalForm) => {
  if (legalForm && constants.ADDITIONAL_FLOWS_COUNTRYARR.includes(country)) {
    return ADDITIONAL_FLOWS[country][legalForm];
  }
  return COUNTRY_MAP[country];
};

export const overwriteFormConfig = (formValues, country) => {
  const formConfigOverwritten = [...formConfigSelector(country)];
  if (formValues[constants.TYPE_OF_PERSON] && existsAdditionalFlow(formValues[constants.TYPE_OF_PERSON], country)) {
    const typeOfPersonFlow = flowSelectorByTypeOfPersonAndCountry(formValues[constants.TYPE_OF_PERSON], country);
    formConfigOverwritten.push(...typeOfPersonFlow);
  }

  return formConfigOverwritten;
};
