class Seller {
  constructor() {
    this.sellerData = {
      email: null,
      displayName: null,
      businessName: null,
      businessRegNumber: null,
      businessRegistrationType: null,
      businessRegistrationCategory: null,
      address: {},
      contacts: { contact: [] },
      country: null,
      economicActivityCode: null,
      legalForm: null,
      warehouses: { warehouse: [] },
      geography: null,
      hasConflictOfInterest: null,
      personName: null,
      typeOfConflict: null,
      knowsApep: null,
      pepExecutiveName: null,
      kinshipType: null,
      crimePreventionFreeCompetition: null,
      crimePreventionFreeCompetitionDetail: null,
      confirmTerm: null,
      documents: { file: [] },
      operatorCode: null
    };
  }

  setDocuments(documents) {
    this.sellerData.documents.file = documents;
  }

  setWarehouses(warehouse) {
    this.sellerData.warehouses.warehouse.push(warehouse);
  }

  setContacts(contact) {
    this.sellerData.contacts.contact.push(contact);
  }

  setAddress(address) {
    this.sellerData.address = address;
  }

  setDeclarationsInfo({
    conflictOfInterest,
    personNameWithConflictOfInterest,
    typeOfConflict,
    hasPepRelationship,
    pepName,
    relationshipWithPep,
    crimePrevention,
    crimePreventionObservation,
    confirmTerm,
    personalInfoAuthorization,
    acceptSearchingCentralRisk,
    importedProducts,
    inProgramAML
  }) {
    this.sellerData.hasConflictOfInterest = conflictOfInterest;
    this.sellerData.personName = personNameWithConflictOfInterest;
    this.sellerData.typeOfConflict = typeOfConflict;
    this.sellerData.knowsApep = hasPepRelationship;
    this.sellerData.pepExecutiveName = pepName;
    this.sellerData.kinshipType = relationshipWithPep;
    this.sellerData.crimePreventionFreeCompetition = crimePrevention;
    this.sellerData.crimePreventionFreeCompetitionDetail = crimePreventionObservation ?? "";
    this.sellerData.confirmTerm = confirmTerm;
    this.sellerData.personalInfoAuthorization = personalInfoAuthorization;
    this.sellerData.acceptSearchingCentralRisk = acceptSearchingCentralRisk;

    if (importedProducts) {
      this.sellerData.importedProducts = importedProducts;
    }

    if (inProgramAML) {
      this.sellerData.inProgramAML = inProgramAML;
    }
  }

  setBankInfo({ bankName, bankAccountNumber, bankAccountType }) {
    this.sellerData.bankName = bankName?.label ?? "";
    this.sellerData.bankAccountNumber = bankAccountNumber ?? "";
    this.sellerData.bankAccountType = bankAccountType ?? "";
  }

  setBusinessInfo({ rutCompany, businessRegistrationType, businessRegistrationCategory, economicActivity, legalForm }) {
    this.sellerData.businessRegNumber = rutCompany;
    this.sellerData.businessRegistrationType = businessRegistrationType;
    this.sellerData.businessRegistrationCategory = businessRegistrationCategory;
    this.sellerData.economicActivityCode = economicActivity.split("-")[0].trim();
    this.sellerData.legalForm = legalForm;
  }

  setBasicInfo({ countryName, adminEmail, legalRepEmail, shopName, fantasyName, operatorCode, geography, legalRepName, legalRepLastName }) {
    this.sellerData.country = countryName;
    this.sellerData.email = adminEmail ?? legalRepEmail;
    this.sellerData.businessName = shopName ?? `${legalRepName} ${legalRepLastName}`;
    this.sellerData.displayName = fantasyName;
    this.sellerData.geography = geography;
    this.sellerData.operatorCode = operatorCode;
  }

  getData() {
    return this.sellerData;
  }
}

export default Seller;
