import React from "react";
import "./Page.scss";
import FirstPage from "../UI/Pages/FirstPage";
import SuccessPage from "../UI/Pages/SuccessPage";
import { useStateValue } from "../../store/StateProvider";
import FormPage from "../UI/Pages/FormPage";

const Page = () => {
  const [{ country, isFormSubmitted }] = useStateValue();

  if (!country) {
    return <FirstPage />
  } 
  
  if (isFormSubmitted) {
    return <SuccessPage />
  }

  return <FormPage />;
};
export default Page;
