import Seller from "./schema/Seller";
import Address from "./schema/Address";
import Contact from "./schema/Contact";
import constants from "../config/constants";
import CustomercareGps from "./schema/CustomercareGps";
import Warehouse from "./schema/Warehouse";
import { createSellerService } from "../components/Integrations/serviceBff";
import SellerServiceFieldMap from "../constants/SellerServiceFieldMap";
import FormConfig from "../config/CL/form";
import Document from "./schema/Document";

export const createSeller = async (country, formValues, documents) => {
  const SellerData = new Seller();
  const formData = getPreparedFormData(country, formValues);

  const sellerAddress = new Address(formData.addressHeadOffice, formData.municipalHeadOffice, country);
  const contactAdmin = getContactAdmin(formData);
  const contactPersonInCharge = getContactPersonInCharge(formData);
  const shipmentWarehouse = getShipmentWarehouse(country, formData);
  const returnWarehouse = getReturnWarehouse(country, formData);
  const formDocuments = getDocuments(country, documents);

  SellerData.setBasicInfo(formData);
  SellerData.setBusinessInfo(formData);
  SellerData.setBankInfo(formData);
  SellerData.setDeclarationsInfo(formData);
  SellerData.setAddress(sellerAddress.getData());
  SellerData.setContacts(contactAdmin.getData());
  SellerData.setContacts(contactPersonInCharge.getData());
  SellerData.setWarehouses(shipmentWarehouse.getData());
  SellerData.setWarehouses(returnWarehouse.getData());
  SellerData.setDocuments(formDocuments);

  const sellerPayload = SellerData.getData();

  const serviceResponse = await createSellerService({ sellerData: sellerPayload });
  const response = prepareResponse(serviceResponse);

  return response;
};

const prepareResponse = serviceResponse => {
  let response = {
    status: "success",
    errors: {},
    message: ""
  };

  if (serviceResponse.data.errors !== undefined && serviceResponse.data.errors.length) {
    response.status = "error";
    response.message = "Errors in form.";
    response.errors = serviceResponse.data.errors[0].detail.map(error => {
      const fieldName = SellerServiceFieldMap[`${error.target}.${error.property}`]
        ? SellerServiceFieldMap[`${error.target}.${error.property}`]
        : error.property;
      return {
        path: error.target,
        field: fieldName ? fieldName : "common_error",
        errorMessage: error.message,
        section: getFieldSectionAndSubSection(fieldName)
      };
    });
  }

  return response;
};

const getFieldSectionAndSubSection = fieldName => {
  let location = {};

  if (!fieldName) {
    location.section = 1;
    return location;
  }

  FormConfig.forEach(section => {
    if (section.fields && fieldExist(fieldName, section.fields)) {
      location.section = section.section.id;
      return location;
    } else if (section.subSections) {
      section.subSections.forEach(subSection => {
        if (subSection.fields && fieldExist(fieldName, subSection.fields)) {
          location.section = section.section.id;
          location.subSection = subSection.id;
          return location;
        }
      });
    }
  });

  return location;
};

const fieldExist = (fieldName, fields) => {
  let fieldExist = false;
  fields.forEach(field => {
    if (field.name === fieldName) {
      fieldExist = true;
    }
  });

  return fieldExist;
};

const getDocuments = (country, formData) => {
  const documents = [];
  Object.entries(constants.DOCUMENTS_PER_COUNTRY[country]).forEach(([documentIdentifier, documentType]) => {
    if (!formData[documentIdentifier]) {
      return;
    }
    const document = getSingleDocument(documentType, formData[documentIdentifier]);
    return documents.push(document.getData());
  });

  return documents;
};

const getSingleDocument = (documentType, formData) => {
  const { originalFileName, fileName, url, mime, size } = formData;
  return new Document(originalFileName, fileName, url, mime, size, documentType);
};

const getReturnWarehouse = (country, formData) => {
  const shipmentAddress = new Address(formData.addressReturnWarehouse, formData.municipalReturnWarehouse, country);
  const gps = new CustomercareGps("0.0", "0.0");

  return new Warehouse(
    "Return",
    formData[constants.INPUT_ID_RETURN_WAREHOUSE_CONTACT_NAME],
    formData[constants.INPUT_ID_RETURN_WAREHOUSE_CONTACT_EMAIL],
    formData[constants.INPUT_ID_RETURN_WAREHOUSE_CONTACT_PHONE],
    shipmentAddress.getData(),
    gps.getData()
  );
};

const getShipmentWarehouse = (country, formData) => {
  const shipmentAddress = new Address(formData.addressShipmentWarehouse, formData.municipalShipmentWarehouse, country);
  const gps = new CustomercareGps("0.0", "0.0");

  return new Warehouse(
    "Shipment",
    formData[constants.INPUT_ID_SHIPMENT_WAREHOUSE_CONTACT_NAME],
    formData[constants.INPUT_ID_SHIPMENT_WAREHOUSE_CONTACT_EMAIL],
    formData[constants.INPUT_ID_SHIPMENT_WAREHOUSE_CONTACT_PHONE],
    shipmentAddress.getData(),
    gps.getData()
  );
};

const getContactPersonInCharge = formData => {
  return new Contact(
    "PersonInCharge",
    formData[constants.INPUT_ID_LEGAL_REPRESENTATIVE_DOCUMENT_NUMBER],
    formData[constants.INPUT_ID_LEGAL_REPRESENTATIVE_NAME],
    formData[constants.INPUT_ID_LEGAL_REPRESENTATIVE_LASTNAME],
    formData[constants.INPUT_ID_LEGAL_REPRESENTATIVE_DOCUMENT_TYPE] ?? "RUT",
    formData[constants.INPUT_ID_LEGAL_REPRESENTATIVE_EMAIL],
    formData[constants.INPUT_ID_LEGAL_REPRESENTATIVE_PHONE]
  );
};

const getContactAdmin = formData => {
  const adminKeys = [
    constants.INPUT_ID_ADMIN_DOCUMENT_NUMBER, 
    constants.INPUT_ID_ADMIN_FIRST_NAME, 
    constants.INPUT_ID_ADMIN_LAST_NAME, 
    constants.INPUT_ID_ADMIN_EMAIL, 
    constants.INPUT_ID_ADMIN_PHONE
  ];

  if(!adminKeys.every(key => key in formData)) {
    return new Contact(
      "Admin",
      formData[constants.INPUT_ID_LEGAL_REPRESENTATIVE_DOCUMENT_NUMBER],
      formData[constants.INPUT_ID_LEGAL_REPRESENTATIVE_NAME],
      formData[constants.INPUT_ID_LEGAL_REPRESENTATIVE_LASTNAME],
      formData[constants.INPUT_ID_LEGAL_REPRESENTATIVE_DOCUMENT_TYPE] ?? "RUT",
      formData[constants.INPUT_ID_LEGAL_REPRESENTATIVE_EMAIL],
      formData[constants.INPUT_ID_LEGAL_REPRESENTATIVE_PHONE]
    );
  }

  return new Contact(
    "Admin",
    formData[constants.INPUT_ID_ADMIN_DOCUMENT_NUMBER],
    formData[constants.INPUT_ID_ADMIN_FIRST_NAME],
    formData[constants.INPUT_ID_ADMIN_LAST_NAME],
    formData[constants.INPUT_ID_ADMIN_DOCUMENT_TYPE] ?? "RUT",
    formData[constants.INPUT_ID_ADMIN_EMAIL],
    formData[constants.INPUT_ID_ADMIN_PHONE]
  );
};

const getPreparedFormData = (country, formValues) => {
  formValues.countryName = getCountryName(country);
  formValues.operatorCode = getOperatorCode(country);
  formValues.geography = "national";
  formValues.businessRegistrationType = constants.DEFAULT_BUSINESS_REGISTRATION_TYPE[country];
  formValues.businessRegistrationCategory = getBusinessRegistartionCategory(country);
  if (formValues.legalForm === undefined) {
    formValues.legalForm = "legal_person";
  }

  if (formValues.conflictOfInterest === constants.NO) {
    formValues.personNameWithConflictOfInterest = "";
    formValues.typeOfConflict = "";
  }

  if (formValues.hasPepRelationship === constants.NO) {
    formValues.pepName = "";
    formValues.relationshipWithPep = "";
  }

  formValues.crimePrevention = formValues.crimePrevention === constants.NO;
  if (formValues.crimePreventionObservation === undefined) {
    formValues.crimePreventionObservation = "";
  }

  formValues.personalInfoAuthorization = Boolean(formValues.personalInfoAuthorization);
  formValues.acceptSearchingCentralRisk = Boolean(formValues.acceptSearchingCentralRisk);

  formValues.confirmTerm = true;

  return formValues;
};

const getCountryName = country => {
  return constants.VALID_COUNTRIES[country].name ?? "";
};

const getOperatorCode = country => {
  return constants.VALID_COUNTRIES[country].operatorCode ?? "";
};

const getBusinessRegistartionCategory = country => {
  return "";
};
