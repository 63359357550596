import React from "react";
import { ReactComponent as WarningIconSvg } from "../../../static/svgs/warningIcon.svg";

const WarningIcon = ({ color = "var(--blue-text-warning)" }) => {
  return (
    <div className="warning-icon-container">
      <WarningIconSvg style={{ fill: color }} />
    </div>
  );
};

export default WarningIcon;
