import React, { useEffect } from "react";
import { Row, Form } from "react-bootstrap";
import Alert from "../UI/Elements/Alert";
import FormElement from "../FormElement/FormElement";
import NavigationPanel from "../NavigationPanel/NavigationPanel";
import AccordionElement from "../UI/Accordion/AccordionElement";
import { useStateValue } from "../../store/StateProvider";
import { actionTypes } from "../../utils/constants";
import { stepId, getFields, didAsyncValidationPass } from "../../utils/formData";
import { isValidForm } from "../../utils/validator";
import { getSellerCreationErrors } from "../../utils/validator";
import { countryHaveAnyFlowDisabled } from "../../config/CountryConfigurations";
import constants from "../../config/constants";
import "./FormContainer.scss";

const FormContainer = ({ formConfig }) => {
  const [{ currentStep, formValues, sellerCreationErrors, asyncValidationsByStep, country }, dispatch] = useStateValue();
  const errorMessage = getSellerCreationErrors("common_error", sellerCreationErrors);
  const section = formConfig[currentStep];

  useEffect(() => {
    dispatch({
      type: actionTypes.INVALIDATE_CURRENT_STEP,
      payload: {
        step: stepId(section),
        buttonStatus: didAsyncValidationPass(asyncValidationsByStep, currentStep) && isValidForm(getFields(section), formValues)
      }
    });
  }, [formValues]); // eslint-disable-line

  let sectionTitle = "";
  let sectionSubtitle = "";
  if (section.section.title !== undefined && section.section.title !== "") {
    sectionTitle = (
      <Row className="text-center">
        <h1 className="form_title">{section.section.title}</h1>
      </Row>
    );
  }

  if (section.section.subTitle !== undefined && section.section.subTitle !== "") {
    sectionSubtitle = (
      <Row className="text-center">
        <h1 className="form_subtitle">{section.section.subTitle}</h1>
      </Row>
    );
  }

  if (section.section.type !== undefined && section.section.type === constants.SECTION_TYPE_ACCORDION) {
    return (
      <>
        {sectionTitle}
        <div className="content__fields_no_bg">
          <AccordionElement section={section} />
        </div>
        <NavigationPanel stepsCount={formConfig.length} section={section} />
      </>
    );
  } else {
    return (
      <>
        {sectionTitle}
        <div className="content__fields">
          <Form className="form_wrapper">
            <div className={`label_error text_strong text_center ${!!errorMessage ? "show" : "hidden"}`}>
              <span>{errorMessage}</span>
            </div>
            {sectionSubtitle}
            <FormElement section={section} />
            {
              section.section.alertConfig &&
              section.section.alertConfig.enabled &&
              countryHaveAnyFlowDisabled(country) &&
              <Alert
                className={section.section.alertConfig.className}
                text={section.section.alertConfig.text}
                color={section.section.alertConfig.color}
                backgroundColor={section.section.alertConfig.backgroundColor}
              />
            }
          </Form>
        </div>
        <NavigationPanel stepsCount={formConfig.length} section={section} />
      </>
    );
  }
};

export default FormContainer;
