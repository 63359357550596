import { React, useState, useEffect } from "react";
import { useStateValue } from "../../../store/StateProvider";
import dataSourceAggregator from "../../Integrations/dataSourceAggregator";
import { actionTypes } from "../../../utils/constants";

const DropdownSearchControl = ({ fieldConfig, fieldValidator, removeErrorMessage, hasError }) => {
  const optionLabelKey = `optionLabel_${[fieldConfig.id]}`;
  let fieldClass = "form-select input_plain ";
  fieldClass += fieldConfig.class;
  if (hasError) {
    fieldClass += " has_error is-invalid";
  }

  const [{ country, currentStep, formValues }, dispatch] = useStateValue();
  let dataSource = fieldConfig.dataSource ?? {};
  dataSource.globalStateValues = { country, currentStep, ...formValues };

  const [fieldValue, setFieldValue] = useState(formValues[fieldConfig.name] ?? "");
  const [options, setOptions] = useState([]);

  useEffect(() => {
    const setupOptions = async () => {
      const sourceData = await dataSourceAggregator(fieldConfig.dataSource);
      if (sourceData !== undefined && sourceData !== "" && sourceData !== null) {
        setOptions(sourceData);
      }
    };

    setupOptions();
    setFieldValue(formValues[fieldConfig.name] ?? fieldConfig.default);
  }, [fieldConfig.dataSource, formValues, fieldConfig.name, fieldConfig.default]);

  const fieldValueChangeHandler = event => {
    setFieldValue(event.target.value);
    dispatch({
      type: actionTypes.PRESERVE_GLOBAL_STATE,
      payload: {
        [fieldConfig.id]: event.target.value,
        [optionLabelKey]: event.target.options[event.target.selectedIndex].text
      }
    });
  };

  return (
    <>
      <select
        className={fieldClass}
        name={fieldConfig.name}
        id={fieldConfig.id}
        data-cy={fieldConfig.id}
        placeholder={fieldConfig.placeHolder}
        value={fieldValue}
        onBlur={() => fieldValidator()}
        onChange={fieldValueChangeHandler}
        onClick={removeErrorMessage}>
        {options.map(dropdownOption => {
          return (
            <option key={dropdownOption.value} value={dropdownOption.value}>
              {dropdownOption.label}
            </option>
          );
        })}
      </select>
    </>
  );
};

export default DropdownSearchControl;
