import React from "react";
import Page from "../Page/Page";
import "./App.scss";
import { useStateValue } from "../../store/StateProvider";
import { actionTypes } from "../../utils/constants";
import SpinnerOverlay from "../UI/Elements/SpinnerOverlay";

export const App = () => {

  const [{ isDragging, showOverlay }, dispatch] = useStateValue();

  const handleDragOver = () => {
    if (!isDragging) {
      dispatch({
        type: actionTypes.USER_IS_DRAGGING
      });
    }
  };

  const handleDragLeave = (event) => {
    if (event.currentTarget.contains(event.relatedTarget)) return;
    if (isDragging) {
      dispatch({
        type: actionTypes.USER_ISNT_DRAGGING
      });
    }
  };

  const FalabellaLogo = "https://images.falabella.com/v3/assets/blt7c5c2f2f888a7cc3/blta6ecea82cc374fd6/65a69977cdbb964526c4f3e5/falabella.com_green_icon_mobile.svg";

  return (
    <div 
      className="container_form"
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
    >
      { showOverlay &&
        <SpinnerOverlay />
      }
      <section className="header">
        <div className="logo">
          <img alt="Falabella Logo" src={FalabellaLogo}></img>
        </div>
      </section>
      <Page />
      <section className="footer_form"></section>
    </div>
  );
};
