import constants from "../constants";

const bankSection = [
    {
        type: constants.INPUT_TYPE_LEGEND,
        id: "bank-legend",
        title: {
            value: "Datos bancarios",
        },
        label: {
            value: "Ingresa los datos de la cuenta donde quieres recibir los pagos de tus ventas.",
        }
    },
    {
        class: "search-icon",
        id: "bankName",
        label: "Banco destino",
        labelEn: "Bank",
        name: "bankName",
        order: 1,
        placeHolder: "Selecciona una opción",
        required: true,
        type: constants.INPUT_TYPE_SEARCH_TEXT_BOX,
        remoteValidation:[constants.INPUT_ID_DOCUMENT_RUT],
        dataSource: {
            type: constants.DATASOURCE_TYPE_API,
            source: constants.SERVICE_BFF,
            endpoint: "sellerBanks",
            params: [
                {
                    name: "countryCode",
                    sourceType: "GlobalStateValue",
                    sourceId: "country"
                }
            ]
        },
        validators: [
            {
                type: constants.VALIDATOR_NOT_EMPTY,
                errorMessage: "Debes seleccionar una opción de la lista."
            }
        ]
    },
    {
        class: "",
        containerClass: "",
        dataSource: "",
        default: "",
        fakeValue: "",
        id: "bankAccountType",
        label: "Tipo de cuenta",
        labelClass: "",
        labelDescription: "",
        name: "bankAccountType",
        placeHolder: "",
        required: true,
        type: constants.INPUT_TYPE_DROPDOWN,
        options: [
            {
                id: "bankAccountType_1",
                label: "Cuenta corriente",
                value: "Cuenta corriente"
            },
            {
                id: "bankAccountType_2",
                label: "Cuenta de Ahorro",
                value: "Cuenta de Ahorro"
            }
        ],
            validators: [
                {
                    type: constants.VALIDATOR_NOT_EMPTY,
                    errorMessage: "Debes seleccionar una opción."
                }
            ]
    },
    {
        class: "",
        containerClass: "",
        default: "",
        id: "bankAccountNumber",
        label: "Número de cuenta",
        labelEn: "",
        labelClass: "",
        labelDescription: "",
        name: "bankAccountNumber",
        placeHolder: "Ej. 0001 0001001",
        required: true,
        customHandler: constants.INPUT_HANDLER_ONLY_NUMBERS,
        type: constants.INPUT_TYPE_TEXT,
        maxLength: "50",
        remoteValidation: [constants.INPUT_ID_DOCUMENT_BANK_CERTIFICATE],
        validators: [
            {
                type: constants.VALIDATOR_MIN_LENGTH,
                errorMessage: "Debes ingresar un número de cuenta válido.",
                data: {
                    minLength: 5
                }
            },
            {
                type: constants.VALIDATOR_NOT_EMPTY,
                errorMessage: "Debes ingresar un número de cuenta válido."
            }
        ]
    },
    {
        class: "",
        containerClass: "",
        dataSource: "",
        id: "documentBankCertificate",
        label: "Certificado bancario",
        labelClass: "",
        labelDescription: "Debe tener una vigencia menor a 30 días y coincidir con el nombre de la empresa y NIT.",
        boxDescription: "1 Archivo permitido JPG, PNG o PDF.",
        name: "documentBankCertificate",
        type: constants.INPUT_TYPE_FILE,
        required:true,
        attachTextButton: "Adjunta",
        attachTextLabel: "o arrastra el archivo",
        dropTextMessage: "Suelta el archivo en esta área",
        accept: ".jpg,.jpeg,.png,.pdf",
        validators: [
            {
                type: constants.VALIDATOR_FILE_SIZE,
                errorMessage: "El archivo excede el límite permitido, no debe superar 3MB.",
                data: {
                    maxAllowedSize: 3e6
                }
            },
            {
                type: constants.VALIDATOR_FILE_TYPE,
                errorMessage: "Archivo inválido, adjunta solo JPG, PNG o PDF.",
                data: {
                    allowedFileTypes: ["jpg", "jpeg", "png", "pdf"]
                }
            },
            {
                type: constants.VALIDATOR_HAS_CURRENT_VALUE,
                errorMessage: "Solo puedes subir 1 archivo. Si quieres adjuntar un archivo diferente, tienes que eliminar el actual."
            },
            {
                type: constants.VALIDATOR_NOT_EMPTY,
                errorMessage: "Este campo es obligatorio, adjunta el certificado bancario"
            }
        ]
    }
];

export default bankSection;
