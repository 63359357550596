import React from "react";
import Legend from "../UI/Elements/Legend";
import InputText from "../UI/Input/InputText";
import InputDropdown from "../UI/Input/InputDropdown";
import InputRadio from "../UI/Input/InputRadio";
import InputCheckbox from "../UI/Input/InputCheckbox";
import InputTextarea from "../UI/Input/InputTextarea";
import InputFile from "../UI/Input/InputFile";
import InputButton from "../UI/Input/InputButton";
import InputSubmit from "../UI/Input/InputSubmit";
import InputReset from "../UI/Input/InputReset";
import InputFieldGroup from "../UI/Input/InputFieldGroup";
import InputLabel from "../UI/Input/InputLabel";
import InputDropFile from "../UI/Input/InputDropFile";
import InputSearch from "../UI/Input/InputSearch";
import InputDropdownSearch from "../UI/Input/InputDropdownSearch";
import "../UI/Input/Input.scss";
import constants from "../../config/constants";

const FormElement = ({ section }) => {

  return (
    <>
      {section.fields.map(field => {
        switch (field.type) {
          case constants.INPUT_TYPE_TEXT:
            return (
              <InputText
                fieldConfig={field}
                key={field.id}
              />
            );
          case constants.INPUT_TYPE_DROPDOWN:
            return (
              <InputDropdown
                fieldConfig={field}
                key={field.id}
              />
            );
          case constants.INPUT_TYPE_DROPDOWN_SEARCH:
            return (
              <InputDropdownSearch
                fieldConfig={field}
                key={field.id}
              />
            );
          case constants.INPUT_TYPE_RADIO:
            return (
              <InputRadio
                fieldConfig={field}
                key={field.id}
              />
            );
          case constants.INPUT_TYPE_CHECKBOX:
            return (
              <InputCheckbox
                fieldConfig={field}
                key={field.id}
              />
            );
          case constants.INPUT_TYPE_TEXTAREA:
            return <InputTextarea fieldConfig={field} key={field.id} />;
          case constants.INPUT_TYPE_FILE:
            return <InputFile fieldConfig={field} key={field.id} />;
          case constants.INPUT_TYPE_BUTTON:
            return <InputButton fieldConfig={field} key={field.id} />;
          case constants.INPUT_TYPE_SUBMIT:
            return <InputSubmit fieldConfig={field} key={field.id} />;
          case constants.INPUT_TYPE_RESET:
            return <InputReset fieldConfig={field} key={field.id} />;
          case constants.INPUT_TYPE_FIELDGROUP:
            return (
              <InputFieldGroup
                fieldConfig={field}
                key={field.id}
              />
            );
          case constants.INPUT_TYPE_LABEL:
            return <InputLabel fieldConfig={field} key={field.id} />;
          case constants.INPUT_TYPE_DROPFILE:
            return (
              <InputDropFile
                fieldConfig={field}
                key={field.id}
              />
            );
          case constants.INPUT_TYPE_SEARCH_TEXT_BOX:
            return (
              <InputSearch
                fieldConfig={field}
                key={field.id}
              />
            );
          case constants.INPUT_TYPE_LEGEND:
            return (
              <Legend 
                key={field.id}
                config={field}
              />
            );
          case constants.INPUT_TYPE_CUSTOM_HTML:
            return field.default;
          default:
            return "";
        }
      })}
    </>
  );
};

export default FormElement;
