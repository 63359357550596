class Document {
    constructor(originalFileName, fileName, url, mime, size, documentType) {
      this.document = {};
      this.document.originalFileName = originalFileName;
      this.document.fileName = fileName;
      this.document.url = url;
      this.document.mime = mime;
      this.document.size = size;
      this.document.documentType = documentType;
    }
  
    getData() {
      return this.document;
    }
  }
  
  export default Document;
  