import React from "react";
import WarningIcon from "./WarningIcon";

const Alert = props => {
  const { backgroundColor, color, text, className } = props;

  const style = {
    backgroundColor: backgroundColor,
    color: color
  };

  return (
    <div id="warning_countryCode" className={className} style={style}>
      <div className="icon">
        <WarningIcon color={color} />
      </div>
      <p className="alert-content" dangerouslySetInnerHTML={{ __html: text }}></p>
    </div>
  );
};

Alert.defaultProps = {
  className: "alert-box-warning",
  backgroundColor: "var(--blue-box-warning)",
  color: "var(--blue-text-warning)"
};

export default Alert;
