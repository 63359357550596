class Contact {
  constructor(contactType, documentNumber, firstName, lastName, documentType, email, phoneNumber) {
    this.contact = {};
    this.contact.contactType = contactType;
    this.contact.documentNumber = documentNumber;
    this.contact.firstName = firstName;
    this.contact.lastName = lastName;
    this.contact.documentType = documentType;
    this.contact.email = email;
    this.contact.phoneNumber = +phoneNumber;
  }

  getData() {
    return this.contact;
  }
}

export default Contact;
