import React from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { useStateValue } from "../../store/StateProvider";
import { isLegalRut } from "../../utils/utils";
import constants from "../../config/constants";
import AdobeAnalyticsTitle from "../../constants/AdobeAnalyticsButtonTags";

const Head = () => {
    const [{ country, currentStep, isFormSubmitted, confirmedValues, submitStatus }] = useStateValue();
    let prevSection = currentStep;
    let section = currentStep;

    if (country) section = currentStep + 1;
    if (isFormSubmitted) {
        section = AdobeAnalyticsTitle.length - 1;
        prevSection = section - 1;
    }

    const setChileanTypeOfPerson = () => {
        if (!confirmedValues[constants.INPUT_ID_BUSSINESS_REG_NUMBER]) {
            return '';
        }

        return isLegalRut(confirmedValues[constants.INPUT_ID_BUSSINESS_REG_NUMBER]) ? constants.LEGAL_PERSON : constants.NATURAL_PERSON;
    }

    const setColombianTypeOfPerson = () => {
        if (!confirmedValues[constants.TYPE_OF_PERSON]) {
            return '';
        }

        return constants.TYPE_OF_PERSON_MAP[confirmedValues[constants.TYPE_OF_PERSON]];
    }

    const setTypeOfPerson = () => {
        switch (country) {
            case 'CL':
            default:
                return setChileanTypeOfPerson();
            case 'CO':
                return setColombianTypeOfPerson();
        }
    }

    const typeOfPerson = setTypeOfPerson();

    const dataLayer = {
        page: {
            title: country && AdobeAnalyticsTitle[section].section,
            previousPageTitle: country && AdobeAnalyticsTitle[prevSection].section,
            previousPageURL: "",
        },
        seller: {
            sellerId: "",
            operator: "",
            Country: country ?? '',
            typeOfPerson: typeOfPerson,
            businessDocumentType: constants.DEFAULT_BUSINESS_REGISTRATION_TYPE[country] ?? '',
            businessDocumentNumber: confirmedValues[constants.INPUT_ID_BUSSINESS_REG_NUMBER] ?? '',
            sellerRegistrationStatus: submitStatus,
        }
    }

    const setDataLayerByTypeOfPerson = () => {
        const dataToSet = { ...dataLayer };

        if (typeOfPerson === constants.LEGAL_PERSON) {
            dataToSet.seller.adminFirstName = confirmedValues[constants.INPUT_ID_ADMIN_FIRST_NAME] ?? '';
            dataToSet.seller.adminLastName = confirmedValues[constants.INPUT_ID_ADMIN_LAST_NAME] ?? '';
            dataToSet.seller.adminEmail = confirmedValues[constants.INPUT_ID_ADMIN_EMAIL] ?? '';
            dataToSet.seller.adminPhone = confirmedValues[constants.INPUT_ID_ADMIN_PHONE] ?? '';
        } else if (typeOfPerson === constants.NATURAL_PERSON) {
            dataToSet.seller.adminFirstName = confirmedValues[constants.INPUT_ID_LEGAL_REPRESENTATIVE_NAME] ?? '';
            dataToSet.seller.adminLastName = confirmedValues[constants.INPUT_ID_LEGAL_REPRESENTATIVE_LASTNAME] ?? '';
            dataToSet.seller.adminEmail = confirmedValues[constants.INPUT_ID_LEGAL_REPRESENTATIVE_EMAIL] ?? '';
            dataToSet.seller.adminPhone = confirmedValues[constants.INPUT_ID_LEGAL_REPRESENTATIVE_PHONE] ?? '';
        }

        return dataToSet;
    }

    return (
        <HelmetProvider>
            <Helmet>
                <script src={constants.ADOBE_ANALYTICS_URL} async></script>
                <script>
                    {`
                    window.data = window.data || {};
                    window.data = Object.assign(window.data, ${JSON.stringify(setDataLayerByTypeOfPerson())});
                `}
                </script>
            </Helmet>
        </HelmetProvider>
    );
}

export default Head;
