import constants from "../config/constants";

export const getFields = section => {
  let fields = [];
  if (section.fields !== undefined) {
    section.fields.map(field => {
      if (field.subFields !== undefined) {
        return field.subFields.map(subField => fields.push(subField));
      }
      return fields.push(field);
    });
  }

  if (section.subSections !== undefined) {
    section.subSections.map(subSection => {
      return (
        subSection.fields !== undefined &&
        subSection.fields.map(field => {
          if (field.subFields !== undefined) {
            return field.subFields.map(subField => fields.push(subField));
          }
          return fields.push(field);
        })
      );
    });
  }

  return fields;
};

export const changeRequiredAttribute = (formConfig, fieldId, isRequired) => {
  if (formConfig.id === fieldId) {
    formConfig.required = isRequired;
    return formConfig;
  }

  Object.keys(formConfig).forEach(key => {
    const value = formConfig[key];

    if (Array.isArray(value)) {
      value.forEach(item => {
        if (typeof item === "object" && item !== null) {
          changeRequiredAttribute(item, fieldId, isRequired);
        }
      });
    } else if (typeof value === "object" && value !== null) {
      changeRequiredAttribute(value, fieldId, isRequired);
    }
  });

  return formConfig;
};

export const setHiddenField = (hiddenFields, fieldId, isHidden) => {
  if (isHidden) {
    return [...hiddenFields, fieldId];
  }

  return hiddenFields;
}

export const stepId = section => {
  return section.section.id ?? null;
};

export const defaultAccordionNavigators = subSections => {
  return subSections.map(item => (item.showNavigation ? item.id : null)).filter(Boolean);
};

export const defaultAutoFillSections = subSections => {
  return subSections.reduce((acc, curr) => {
    if (curr.autofill) {
      acc[curr.id] = false;
    }
    return acc;
  }, {});
};
export const defaultAccordionItemClass = (subSections, accordionsEnabled) => {
  return subSections.reduce((acc, curr) => {
    acc[curr.id] =
      curr.enabled || accordionsEnabled.includes(curr.id)
        ? constants.ACCORDION_ITEM_ENABLED_CLASS
        : constants.ACCORDION_ITEM_DISABLED_CLASS;

    return acc;
  }, {});
};

export const activateAllAccordionItems = subSections => {
  return subSections.reduce((acc, curr) => {
    acc[curr.id] = constants.ACCORDION_ITEM_ENABLED_CLASS;

    return acc;
  }, {});
};

export const enableAccordionItem = (subSections, itemId) => {
  return {
    ...subSections,
    [itemId]: constants.ACCORDION_ITEM_ENABLED_CLASS
  };
};

export const getAutoFillBehavior = (sectionId, subSections) => {
  return subSections.reduce((acc, curr) => {
    if (curr.id === sectionId) {
      acc = curr.autofill.behavior;
    }
    return acc;
  }, {});
};

export const getNextSubSectionFromSectionId = (sectionId, subSections) => {
  return subSections.reduce((acc, curr) => {
    if (curr.id === sectionId) {
      acc = curr.onNavigateActions.next.params.nextSubSection;
    }
    return acc;
  }, {});
};

export const getAffectededSectionsByAutofill = (sectionId, subSections) => {
  return subSections.reduce((acc, curr) => {
    if (curr.id === sectionId) {
      acc = curr.autofill.affectedSections;
    }
    return acc;
  }, {});
};

export const checkIfKeyExistInObject = (obj, keySearched) => {
  for (const key in obj) {
    if (key === keySearched) {
      return true;
    }

    if (typeof obj[key] === 'object' && checkIfKeyExistInObject(obj[key], keySearched)) {
      return true;
    }
  }

  return false;
}

export const generateRandomId = (length = 8) => {
  const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let id = '';

  for (let i = 0; i < length; i++) {
    const randomChar = chars.charAt(Math.floor(Math.random() * chars.length));
    id += randomChar;
  }

  return id;
}

export const getModifiedFileName = (fieldId, country, rutCompany) => {
  const documentName = checkIfKeyExistInObject(constants.DOCUMENTS_PER_COUNTRY[country], fieldId) ? constants.DOCUMENTS_PER_COUNTRY[country][fieldId] : "";
  const formattedDocumentNumber = rutCompany ? `_${rutCompany}` : "";
  const id = (documentName || formattedDocumentNumber) ? `_${generateRandomId()}` : generateRandomId();

  return `${documentName}${formattedDocumentNumber}${id}`;
}

export const didAsyncValidationPass = (asyncValidationsByStep, step) => {
  const { [step]: asyncValidationValue = true } = asyncValidationsByStep;
  return asyncValidationValue;
}

export const overwriteFieldConfig = (formConfig, fieldName, newFieldConfig) => {

  const updateField = (field) => {
    if (field.name === fieldName) {
      return { ...field, ...newFieldConfig };
    }
    return field;
  };

  const updateFields = (fields) => fields.map(updateField);

  const updateSubSection = (subSection) => ({
    ...subSection,
    fields: subSection.fields ? updateFields(subSection.fields) : subSection.fields,
  });

  const updateSubSections = (subSections) => subSections.map(updateSubSection);

  const updateSection = (section) => ({
    ...section,
    fields: section.fields ? updateFields(section.fields) : section.fields,
    subSections: section.subSections ? updateSubSections(section.subSections) : section.subSections,
  });

  const updateSections = (sections) => sections.map(updateSection);

  return updateSections(formConfig);
}
