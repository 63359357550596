import React, { useEffect, useState } from "react";
import ButtonControl from "../../UI/InputControls/ButtonControl";
import { isValidForm } from "../../../utils/validator";
import { getFields } from "../../../utils/formData";
import "./AccordionNavigation.scss";
import constants from "../../../config/constants";

const AccordionNavigation = ({ section, activeKeyChanger, handleClickAccordionNavigator, formValues, onSimulateClick, handleSubtitle }) => {

  const [isButtonDisabled, setIsButtonDisabled] = useState(true);

  useEffect(() => {
    setIsButtonDisabled(!isValidForm(getFields(section), formValues));
  }, [formValues]); // eslint-disable-line

  const focusSubSection = params => {
    activeKeyChanger(params.nextSubSection);
  }

  const subsectionHandler = (sectionNavigateAction) => {
    switch (sectionNavigateAction.handler) {
      case constants.ACTION_HANDLER_FOCUS_SUBSECTION:
        focusSubSection(sectionNavigateAction.params);
        break;
      case constants.ACTION_HANDLER_RENDER_SUBTITLE_AND_FOCUS_SUBSECTION:
        handleSubtitle(sectionNavigateAction.params);
        focusSubSection(sectionNavigateAction.params);
        break;
      case constants.ACTION_HANDLER_RENDER_SUBTITLE_AND_ACTIVATE_PAGE_NAVIGATION:
        handleSubtitle(sectionNavigateAction.params);
        onSimulateClick();
        break;
      case constants.ACTION_HANDLER_ACTIVATE_PAGE_NAVIGATION:
        onSimulateClick();
        break;
      default:
        return true;
    }
  }

  const nextStepHandler = () => {
    if (
      isValidForm(getFields(section), formValues) &&
      section.onNavigateActions.next !== undefined &&
      section.onNavigateActions.next !== ""
    ) {
      handleClickAccordionNavigator(section.id, section.onNavigateActions.next.params.nextSubSection);
      subsectionHandler(section.onNavigateActions.next);
    }
  }

  return (
    <div className="accordion_navigation">
      <ButtonControl
        fieldConfig={{
          name: "Submit",
          default: "Continuar",
          class: `input_button_simple ${isButtonDisabled ? 'continue_button_disabled' : ''}`,
          id: section.navigationButtonId
        }}
        onClick={nextStepHandler}
      />
    </div>
  );
}

export default AccordionNavigation;
