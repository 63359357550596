import React, { useRef, useState, useEffect } from "react";
import constants from "../../../config/constants";
import { Form } from "react-bootstrap";
import fileUploadIcon from "../../../static/svgs/fileUploadIcon.svg";
import ButtonControl from "./ButtonControl";
import iconTrash from "../../../static/svgs/icon-trash.svg";
import { useStateValue } from "../../../store/StateProvider";
import { actionTypes } from "../../../utils/constants";
import { postUploadFile } from "../../Integrations/serviceBff";
import Spinner from "../Elements/Spinner";
import { getModifiedFileName } from "../../../utils/formData";
import ButtonWithIcon from "../Elements/ButtonWithIcon";

const FileControl = ({ fieldValidator, fieldConfig, removeErrorMessage, errorMessage }) => {
  const [{ isDragging, documents, fieldsMustRunValidations, country, formValues }, dispatch] = useStateValue();
  const [ originalFile, setOriginalFile ] = useState(null);
  const [fileName, setFileName] = useState(() => documents[fieldConfig.id]?.originalFileName ?? "");
  const [attachTextLabel, setAttachTextLabel] = useState(fieldConfig.attachTextLabel);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();
  const inputRef = useRef(null);

  useEffect(() => {
    setError(errorMessage);
  }, [errorMessage]);

  const handleButtonClick = () => {
    inputRef.current.click();
  };

  const storageFileInformation = (file, response, originalFileName) => {
    const fileValues = {
      [fieldConfig.id]: {
        originalFileName: originalFileName,
        fileName: file.name,
        url: response.data.data,
        mime: file.type,
        size: file.size
      }
    };
    dispatch({
      type: actionTypes.SET_DOCUMENT,
      payload: fileValues
    });
    dispatch({
      type: actionTypes.PRESERVE_GLOBAL_STATE,
      payload: {[fieldConfig.id]: file}
    });
  };

  const uploadFileAction = async (file, originalFileName) => {
    await postUploadFile(file)
      .then(success => {
        setIsLoading(false);
        setFileName(originalFileName);
        storageFileInformation(file, success, originalFileName);
      })
      .catch(() => {
        setError("Hubo un error al  cargar el archivo, intenta nuevamente.");
        dispatch({
          type: actionTypes.PRESERVE_GLOBAL_STATE,
          payload: { [fieldConfig.id]: null }
        });
        return false;
      });
  };

  const handleUploadAction = file => {
    removeErrorMessage();

    if (fieldValidator(fieldConfig.validators, file, fieldConfig.id, fileName)) {
      setIsLoading(true);
      setOriginalFile(file);
      const newFileName = getModifiedFileName(fieldConfig.id, country, formValues[constants.INPUT_ID_BUSSINESS_REG_NUMBER]);
      const renamedFile = new File([file], `${newFileName}.${file.name.split('.').pop()}`, { type: file.type});
      inputRef.current.value = null;
      uploadFileAction(renamedFile, file.name);
    }
  };

  const handleFileSelect = e => {
    handleUploadAction(e.target.files[0]);
  };

  const handleClearFile = () => {
    setFileName(null);
    setOriginalFile(null);
    removeErrorMessage();
    dispatch({
      type: actionTypes.PRESERVE_GLOBAL_STATE,
      payload: { [fieldConfig.id]: null }
    });
    dispatch({
      type: actionTypes.DELETE_DOCUMENT,
      payload: fieldConfig.id
    });
    fieldValidator(fieldConfig.validators, null, fieldConfig.id, null);
  };

  const handleDrop = e => {
    e.preventDefault();
    e.stopPropagation();
    dispatch({
      type: actionTypes.USER_ISNT_DRAGGING
    });
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      handleUploadAction(e.dataTransfer.files[0]);
    }
  };

  useEffect(() => {
    setAttachTextLabel(isDragging ? "Suelta el archivo en esta área" : fieldConfig.attachTextLabel);
  }, [isDragging, fieldConfig.attachTextLabel]);

  useEffect(() => {
    if (fieldsMustRunValidations.includes(fieldConfig.id) && !originalFile && !fileName) {
      fieldValidator(fieldConfig.validators, originalFile, fieldConfig.id, fileName);
    }
  }, [fieldsMustRunValidations]); // eslint-disable-line

  return (
    <>
      <ButtonWithIcon
        id={fieldConfig.id}
        onClick={handleButtonClick}
      />
      <section
        className={isDragging ? "file-container d-none d-md-flex drag" : "file-container d-none d-md-flex"}
        onDragOver={e => e.preventDefault()}
        onDrop={e => handleDrop(e)}
        onClick={removeErrorMessage}>
        <img src={fileUploadIcon} alt="fileUploadIcon" />
        <Form.Control
          name={fieldConfig.name}
          type="file"
          ref={inputRef}
          accept={fieldConfig.accept}
          hidden
          onChange={handleFileSelect}
        />
        <div className="inline-label">
          {fieldConfig.attachTextButton && !isDragging && (
            <ButtonControl
              fieldConfig={{
                name: `attach-button-${fieldConfig.id}`,
                default: fieldConfig.attachTextButton,
                class: "button-title",
                id: `attach-button-${fieldConfig.id}`
              }}
              onClick={handleButtonClick}
            />
          )}

          <Form.Label className="title">{attachTextLabel}</Form.Label>
        </div>
        {fieldConfig.boxDescription && (
          <Form.Label className="description">{!isDragging ? fieldConfig.boxDescription : "\u00A0"}</Form.Label>
        )}
      </section>
      <Form.Label className={`label_error ${!!error ? "show" : "hidden"}`} id={`label_error_${fieldConfig.id}`}>
        <span id={`label_error_message_${fieldConfig.id}`}>{error}</span>
      </Form.Label>
      {isLoading && (
        <div className="file-info">
          <Spinner />
        </div>
      )}
      {fileName && (
        <div className="file-info">
          <span>{fileName}</span>
          <img src={iconTrash} alt="trash-icon" onClick={handleClearFile} />
        </div>
      )}
    </>
  );
};

export default FileControl;
