import React, { useState } from "react";
import { InputGroup, Form } from "react-bootstrap";
import TextControl from "../InputControls/TextControl";
import DropdownControl from "../InputControls/DropdownControl";
import RadioControl from "../InputControls/RadioControl";
import CheckboxControl from "../InputControls/CheckboxControl";
import TextareaControl from "../InputControls/TextareaControl";
import FileControl from "../InputControls/FileControl";
import ButtonControl from "../InputControls/ButtonControl";
import SubmitControl from "../InputControls/SubmitControl";
import ResetControl from "../InputControls/ResetControl";
import ThumbnailDropdownControl from "../InputControls/ThumbnailDropdownControl";
import { useStateValue } from "../../../store/StateProvider";
import { getSellerCreationErrors, getFieldValidationErrors } from "../../../utils/validator";
import constants from "../../../config/constants";

const InputFieldGroup = ({ fieldConfig }) => {
  const [{ country, sellerCreationErrors, formValues }] = useStateValue();
  const [errorMessage, setErrorMessage] = useState(getSellerCreationErrors(fieldConfig.id, sellerCreationErrors));

  const labelClass = `input_label_plain ${fieldConfig.labelClass}`;

  const setError = error => {
    setErrorMessage(error);
  };

  const executeFieldValidation = (validators, fieldId) => {
    const error = getFieldValidationErrors(validators, formValues, fieldId);
    setError(error);
  };

  const idFieldToValidate = fieldConfig.id.split("_")[0];

  return (
    <>
      <Form.Label className={labelClass}>{fieldConfig.label}</Form.Label>
      <Form.Label className="label_description">{fieldConfig.labelDescription}</Form.Label>
      <InputGroup className="mb-3">
        {fieldConfig.subFields.map(field => {
          let containerClass = "mb3 ";
          containerClass += field.containerClass;

          switch (field.type) {
            case constants.INPUT_TYPE_TEXT:
              return (
                <div className={containerClass} key={field.id}>
                  <TextControl
                    fieldConfig={field}
                    key={field.id}
                    fieldValidator={executeFieldValidation}
                    removeErrorMessage={setError.bind(this, null)}
                    hasError={!!errorMessage}
                  />
                  <Form.Label 
                    className={`label_error ${!!errorMessage ? "show" : "hidden"}`} 
                    id={`label_error_${idFieldToValidate}`}>
                    <span id={`label_error_message_${idFieldToValidate}`}>{errorMessage}</span>
                  </Form.Label>
                </div>
              );
            case constants.INPUT_TYPE_DROPDOWN:
              return (
                <div className={containerClass} key={field.id}>
                  <DropdownControl
                    fieldConfig={field}
                    key={field.id}
                    fieldValidator={executeFieldValidation}
                    removeErrorMessage={setError.bind(this, null)}
                  />
                </div>
              );
            case constants.INPUT_TYPE_RADIO:
              return (
                <div className={containerClass} key={field.id}>
                  <RadioControl
                    fieldConfig={field}
                    key={field.id}
                    fieldValidator={executeFieldValidation}
                    removeErrorMessage={setError.bind(this, null)}
                  />
                </div>
              );
            case constants.INPUT_TYPE_CHECKBOX:
              return (
                <div className={containerClass} key={field.id}>
                  <CheckboxControl
                    fieldConfig={field}
                    key={field.id}
                    fieldValidator={executeFieldValidation}
                    removeErrorMessage={setError.bind(this, null)}
                  />
                </div>
              );
            case constants.INPUT_TYPE_TEXTAREA:
              return (
                <div className={containerClass} key={field.id}>
                  <TextareaControl
                    fieldConfig={field}
                    key={field.id}
                    fieldValidator={executeFieldValidation}
                    removeErrorMessage={setError.bind(this, null)}
                  />
                </div>
              );
            case constants.INPUT_TYPE_FILE:
              return (
                <div className={containerClass} key={field.id}>
                  <FileControl
                    fieldConfig={field}
                    key={field.id}
                    fieldValidator={executeFieldValidation}
                    removeErrorMessage={setError.bind(this, null)}
                  />
                </div>
              );
            case constants.INPUT_TYPE_BUTTON:
              return (
                <div className={containerClass} key={field.id}>
                  <ButtonControl
                    fieldConfig={field}
                    key={field.id}
                    fieldValidator={executeFieldValidation}
                    removeErrorMessage={setError.bind(this, null)}
                  />
                </div>
              );
            case constants.INPUT_TYPE_SUBMIT:
              return (
                <div className={containerClass} key={field.id}>
                  <SubmitControl
                    fieldConfig={field}
                    key={field.id}
                    fieldValidator={executeFieldValidation}
                    removeErrorMessage={setError.bind(this, null)}
                  />
                </div>
              );
            case constants.INPUT_TYPE_RESET:
              return (
                <div className={containerClass} key={field.id}>
                  <ResetControl
                    fieldConfig={field}
                    key={field.id}
                    fieldValidator={executeFieldValidation}
                    removeErrorMessage={setError.bind(this, null)}
                  />
                </div>
              );
            case constants.INPUT_TYPE_THUMBNAILDROPDOWN:
              return (
                <div className={containerClass} key={field.id}>
                  <ThumbnailDropdownControl
                    fieldConfig={field}
                    key={field.id}
                    fieldValidator={executeFieldValidation}
                    removeErrorMessage={setError.bind(this, null)}
                  />
                </div>
              );
            case constants.INPUT_TYPE_CUSTOM_HTML:
              return (
                <div className={containerClass} key={field.id}>
                  {field.value}
                </div>
              );
            case constants.INPUT_TYPE_LABEL:
              return (
                <div className={containerClass} key={field.id}>
                  <Form.Label id={field.id} className={field.class}>
                    {constants.VALID_COUNTRIES[country]?.phoneCode ?? ''}
                  </Form.Label>
                </div>
              );
            default:
              return "";
          }
        })}
      </InputGroup>
    </>
  );
};

export default InputFieldGroup;
