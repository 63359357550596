import { actionTypes } from "../utils/constants";
import { formConfigSelector, overwriteFormConfig } from "../config/formConfigurator";
import { changeRequiredAttribute, overwriteFieldConfig, setHiddenField } from "../utils/formData";
import constants from "../config/constants";

export const initialState = {
  country: null,
  countryPersistent: null,
  currentStep: 0,
  isFormSubmitted: false,
  submitStatus: constants.FORM_REGISTRATION_STATUS.default,
  showOverlay: false,
  asyncValidationsByStep: {},
  fieldsMustRunValidations: [],
  stepsOvercome: [],
  accordionsEnabled: [],
  accordionsCompleted: [],
  accordionsActivate: {},
  formValues: {},
  confirmedValues: {},
  documents: {},
  isDragging: false,
  sellerCreationErrors: [],
  formConfig: [],
  hiddenFields: []
};

const reducer = (state, action) => {
  switch (action.type) {
    case actionTypes.SET_DOCUMENT:
      return {
        ...state,
        documents: { ...state.documents, ...action.payload }
      };
    case actionTypes.DELETE_DOCUMENT:
      if (state.documents[action.payload]) {
        delete state.documents[action.payload];
      }
      return {
        ...state
      };
    case actionTypes.SET_FIELD_MUST_RUN_VALIDATIONS:
      return {
        ...state,
        fieldsMustRunValidations: [...state.fieldsMustRunValidations, ...action.payload]
      };
    case actionTypes.DELETE_FIELD_MUST_RUN_VALIDATIONS:
      return {
        ...state,
        fieldsMustRunValidations: state.fieldsMustRunValidations.filter(field => field !== action.payload)
      };
    case actionTypes.SET_ASYNC_VALIDATIONS:
      return {
        ...state,
        asyncValidationsByStep: { ...state.asyncValidationsByStep, ...action.payload }
      };
    case actionTypes.TOGGLE_OVERLAY:
      return {
        ...state,
        showOverlay: action.payload
      };
    case actionTypes.ACCORDIONS_ACTIVE_COMPLETE:
      return {
        ...state,
        accordionsActivate: {
          ...state.accordionsActivate,
          [state.currentStep]: action.affected
        },
        accordionsCompleted: [...state.accordionsCompleted, action.current],
        accordionsEnabled: [...state.accordionsEnabled, action.affected]
      };
    case actionTypes.AUTOFILL_ACTION:
      return {
        ...state,
        accordionsActivate: {
          ...state.accordionsActivate,
          [state.currentStep]: action.affected[0]
        },
        accordionsCompleted: [...state.accordionsCompleted, action.current, ...action.affected],
        accordionsEnabled: [...state.accordionsEnabled, ...action.affected]
      };
    case actionTypes.SET_COUNTRY:
      if (state.formValues.legalForm) {
        initialState.formValues.legalForm = state.formValues.legalForm;
      }

      return {
        ...(state.countryPersistent === action.payload ? state : initialState),
        country: action.payload,
        countryPersistent: action.payload,
        formConfig: formConfigSelector(action.payload, state.formValues.legalForm)
      };
    case actionTypes.RESET_COUNTRY:
      return {
        ...state,
        country: initialState.country,
        formConfig: initialState.formConfig
      };
    case actionTypes.RESET_GLOBAL_STATE:
      return {
        ...initialState
      };
    case actionTypes.GOTO_NEXT_STEP:
      if (state.accordionsActivate[state.currentStep]) {
        delete state.accordionsActivate[state.currentStep];
      }
      return {
        ...state,
        confirmedValues: { ...state.formValues },
        stepsOvercome: [...state.stepsOvercome, action.payload],
        currentStep: state.currentStep + 1
      };
    case actionTypes.SET_CONFIRMED_VALUES:
      return {
        ...state,
        confirmedValues: { ...state.formValues }
      };
    case actionTypes.INVALIDATE_CURRENT_STEP:
      return {
        ...state,
        stepsOvercome: state.stepsOvercome.filter(step => step !== action.payload.step)
      };
    case actionTypes.GOTO_PREV_STEP:
      return {
        ...state,
        currentStep: state.currentStep - 1
      };
    case actionTypes.PRESERVE_GLOBAL_STATE:
      return {
        ...state,
        formValues: { ...state.formValues, ...action.payload },
        currentStep: state.currentStep
      };
    case actionTypes.OVERWRITE_FORM_CONFIG:
      return {
        ...state,
        formConfig: overwriteFormConfig(state.formValues, state.country),
        formValues: { [constants.TYPE_OF_PERSON]: state.formValues[constants.TYPE_OF_PERSON] },
        confirmedValues: { [constants.TYPE_OF_PERSON]: state.formValues[constants.TYPE_OF_PERSON] },
        documents: initialState.documents,
        fieldsMustRunValidations: initialState.fieldsMustRunValidations,
        stepsOvercome: [...state.stepsOvercome, action.payload],
        currentStep: state.currentStep + 1
      };
    case actionTypes.OVERWRITE_FIELD_CONFIG:
      return {
        ...state,
        formConfig: overwriteFieldConfig(state.formConfig, action.payload.targetField, action.payload.newFieldConfig),
        formValues: { ...state.formValues, [action.payload.targetField]: "" }
      };
    case actionTypes.SUBMIT_FORM:
      return {
        ...state,
        isFormSubmitted: true
      };
    case actionTypes.SET_FORM_REGISTRATION_STATUS:
      return {
        ...state,
        submitStatus: action.payload
      };
    case actionTypes.USER_IS_DRAGGING:
      return {
        ...state,
        isDragging: true
      };
    case actionTypes.USER_ISNT_DRAGGING:
      return {
        ...state,
        isDragging: false
      };
    case actionTypes.SET_SELLER_CREATION_ERRORS:
      return {
        ...state,
        currentStep: action.payload.step,
        sellerCreationErrors: action.payload.errors
      };
    case actionTypes.SET_IS_REQUIRED:
      return {
        ...state,
        formConfig: changeRequiredAttribute(state.formConfig, action.payload.id, action.payload.value)
      };
    case actionTypes.SET_HIDDEN_FIELDS:
      return {
        ...state,
        formConfig: changeRequiredAttribute(state.formConfig, action.payload.id, action.payload.value),
        hiddenFields: setHiddenField(state.hiddenFields, action.payload.id, !action.payload.value)
      };
    default:
      return state;
  }
};

export default reducer;
