import { React, useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import CustomHandler from "../../../utils/CustomHandler";
import { useStateValue } from "../../../store/StateProvider";
import { actionTypes } from "../../../utils/constants";

const TextControl = ({ fieldConfig, removeErrorMessage, fieldValidator, hasError, additionalFieldValidator}) => {
    const [{ formValues, fieldsMustRunValidations }, dispatch] = useStateValue();
    let  fieldClass = "input_plain";
    fieldClass += fieldConfig.class; 
    if (hasError) {
        fieldClass += " has_error is-invalid"
    }

    const [fieldValue, setFieldValue] = useState(formValues[fieldConfig.name] ?? fieldConfig.default);

    const checkRemoteValidations = () => {
        if(fieldConfig.remoteValidation){
            dispatch({
                type: actionTypes.SET_FIELD_MUST_RUN_VALIDATIONS,
                payload: fieldConfig.remoteValidation,
            });
        }
    }

    const fieldValueChangeHandler = event => {
        const value = CustomHandler(fieldConfig.customHandler, event.target.value);
        dispatch({
            type: actionTypes.PRESERVE_GLOBAL_STATE,
            payload: { [fieldConfig.id] : value }
        });
        setFieldValue(value);
        fieldValidator(fieldConfig.validators, fieldConfig.id);
        checkRemoteValidations();
    }

    useEffect(() => {
        setFieldValue(formValues[fieldConfig.name] ?? fieldConfig.default);
    }, [formValues, fieldConfig]);

    useEffect(() => {
        if (fieldsMustRunValidations.includes(fieldConfig.id)) {
            fieldValidator(fieldConfig.validators, fieldConfig.id);
        }
    }, [fieldsMustRunValidations]); // eslint-disable-line

    return (
        <Form.Control
            className={fieldClass}
            name={fieldConfig.name}
            value={fieldValue}
            id={fieldConfig.id}
            data-cy={fieldConfig.id}
            type="text"
            maxLength={fieldConfig.maxLength}
            placeholder={fieldConfig.placeHolder}
            onBlur={additionalFieldValidator}
            onKeyUp={fieldValueChangeHandler}
            onChange={fieldValueChangeHandler}
            onClick={removeErrorMessage}
        />
    )
}

export default TextControl;
