import React from "react";
import InputSubmit from "../UI/Input/InputSubmit";
import InputPrev from "../UI/Input/InputPrev";
import { actionTypes } from "../../utils/constants";
import { useStateValue } from "../../store/StateProvider";
import { getFields, stepId, didAsyncValidationPass } from "../../utils/formData";
import { createSeller } from "../../models/SellerModel";
import { isValidForm } from "../../utils/validator";
import AdobeAnalyticsButtonTags from "../../constants/AdobeAnalyticsButtonTags";
import constants from "../../config/constants";
import "./NavigationPanel.scss";

const NavigationPanel = ({ stepsCount, section }) => {
  const [{ currentStep, stepsOvercome, formValues, country, asyncValidationsByStep, documents }, dispatch] = useStateValue();
  const formFields = getFields(section);
  const sectionId = stepId(section);
  const nextButtonId = AdobeAnalyticsButtonTags[currentStep].next;
  const stepComplete = stepsOvercome.includes(sectionId) ?? false;

  const getFirstSectionIdHavingError = errors => {
    let sectionId = stepsCount;
    errors.forEach(error => {
      sectionId = sectionId > error.section.section ? error.section.section : sectionId;
    });

    return sectionId;
  };

  const renderSellerCreationErrors = errors => {
    const firstSectionIdWithError = getFirstSectionIdHavingError(errors);
    if (firstSectionIdWithError) {
      dispatch({
        type: actionTypes.SET_SELLER_CREATION_ERRORS,
        payload: {
          step: firstSectionIdWithError - 1,
          errors: errors
        }
      });
    }
  };

  const overwriteFormConfig = () => {
    dispatch({
      type: actionTypes.OVERWRITE_FORM_CONFIG,
    });
  }

  const dispatchSubmit = () => {
    dispatch({
      type: actionTypes.SUBMIT_FORM
    });
  }

  const isLastStep = () => {
    return currentStep > 0 && currentStep+1 >= stepsCount;
  }

  const toggleOverlay = showOverlay => {
    dispatch({
      type: actionTypes.TOGGLE_OVERLAY,
      payload: showOverlay
    });
  };

  const setFormRegistrationStatus = status => {
    if (status in constants.FORM_REGISTRATION_STATUS) {
      dispatch({
        type: actionTypes.SET_FORM_REGISTRATION_STATUS,
        payload: constants.FORM_REGISTRATION_STATUS[status]
      });
    }
  }
  
  const submitForm = async (country) => {
    switch (country){
      case "PE":
        return dispatchSubmit();
      case "CL":
      case "CO":
      default:
        toggleOverlay(true);
        const sellerResponse = await createSeller(country, formValues, documents);
        toggleOverlay(false);
        if (sellerResponse.status) {
          setFormRegistrationStatus(sellerResponse.status)
        }
        if (sellerResponse.status === "error") {
          return renderSellerCreationErrors(sellerResponse.errors);
        }
        return dispatchSubmit();
    }

  }
  
  const followingButtonIsEnabled = () => {
    return didAsyncValidationPass(asyncValidationsByStep, currentStep) && isValidForm(formFields, formValues);
  }

  const nextStepHandler = async () => {
    if (!followingButtonIsEnabled()) {
      return null;
    }

    if( 
      section.section.followingButtonAction && 
      section.section.followingButtonAction === constants.FOLLOWING_BUTTON_ACTION_OVERWRITE_FORM_CONFIG
    ) {
      return overwriteFormConfig();
    }

    if (isLastStep()) {
      return submitForm(country);
    }

    dispatch({
      type: actionTypes.GOTO_NEXT_STEP,
      payload: sectionId
    });
  };

  const prevStepHandler = () => {
    let type = currentStep > 0 ? actionTypes.GOTO_PREV_STEP : actionTypes.RESET_COUNTRY;
    dispatch({
      type: type
    });
  };

  return (
    <div className="navigation">
      <InputPrev
        fieldConfig={{ id: AdobeAnalyticsButtonTags[currentStep].back, name: "Previous", default: "buttons.previous" }}
        onClick={prevStepHandler}
      />
      <InputSubmit
        className={stepComplete || followingButtonIsEnabled() ? "" : "submit_button_disbled"}
        id={nextButtonId}
        fieldConfig={{ name: "Submit", default: isLastStep() ? "buttons.send" : "buttons.next" }}
        onClick={nextStepHandler}
      />
    </div>
  );
}

export default NavigationPanel;
