import React, { useCallback, useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import InputSubmit from "../Input/InputSubmit";
import ThumbnailDropdownControl from "../InputControls/ThumbnailDropdownControl";
import DropdownControl from "../InputControls/DropdownControl";
import CountryConfigurations, {
  countryHaveAnyFlowDisabled,
  alertFlowDisabledText,
  isMessageDisclaimerEnabledPerCountry,
  alertDisclaimerMessageText,
  TypeOfPersonConfigurations
} from "../../../config/CountryConfigurations";
import { actionTypes } from "../../../utils/constants";
import { useStateValue } from "../../../store/StateProvider";
import Alert from "./Alert";
import AdobeAnalyticsButtonTags from "../../../constants/AdobeAnalyticsButtonTags";
import constants from "../../../config/constants";

const CountrySelector = ({ countryUser, setCountryUser, geoLocationCountry }) => {
  // eslint-disable-next-line
  const [{ formValues }, dispatch] = useStateValue();
  const { t } = useTranslation();
  const [error, setError] = useState(false);
  const [warning, setWarning] = useState(false);
  const [alertFlowDisabled, setAlertFlowDisabled] = useState(false);
  const [alertDisclaimerMessage, setAlertDisclaimerMessage] = useState(false);

  const isValidCountryAndTypeOfPerson = useCallback(() => {
    return isValidCountry(countryUser) && formValues.legalForm;
  }, [countryUser, formValues.legalForm]);

  useEffect(() => {
    if (countryUser) {
      setAlertFlowDisabled(countryHaveAnyFlowDisabled(countryUser));
      setAlertDisclaimerMessage(isMessageDisclaimerEnabledPerCountry(countryUser));
    }
  }, [countryUser]);

  useEffect(() => {
    if (isValidCountryAndTypeOfPerson()) {
      setError(false);
    }
  }, [isValidCountryAndTypeOfPerson]);

  const setValidateSelectedCountry = countryCode => {
    setWarning(countryCode !== geoLocationCountry ?? false);
    setError(!countryCode ?? true);
    setCountryUser(countryCode);
    resetTypeOfPerson();
  };

  const resetTypeOfPerson = () => {
    dispatch({
      type: actionTypes.PRESERVE_GLOBAL_STATE,
      payload: { legalForm: "" }
    });
  };

  const isValidCountry = selectedCountry => Boolean(selectedCountry) && constants.VALID_COUNTRIES[selectedCountry];

  const setCountryAndNavigate = () => {
    if (isValidCountryAndTypeOfPerson()) {
      dispatch({
        type: actionTypes.SET_COUNTRY,
        payload: countryUser
      });
    }

    setError(true);
    return false;
  };

  return (
    <Col lg={{ span: 6, offset: 1 }}>
      <Row>
        <Col>
          <div className={`content_plain ${alertDisclaimerMessage ? 'no-padding' : ''}`}>
            <h1>{t("content.millionsPeoples")}</h1>
            <h4 className="lato">{t("content.registerInvitation")}</h4>
          </div>
        </Col>
      </Row>
      {alertFlowDisabled && (
        <Alert
          className="alert-box-warning mb-10 text-left"
          text={alertFlowDisabledText(countryUser)}
          color="var(--mustard-heavy)"
          backgroundColor="var(--mustard-light)"
        />
      )}
      {alertDisclaimerMessage && (
        <Alert
          className="alert-box-warning mb-10 text-left"
          text={alertDisclaimerMessageText(countryUser)}
          color="var(--mustard-heavy)"
          backgroundColor="var(--mustard-light)"
        />
      )}
      <Row>
        <Col>
          <div className="content_box shaded center">
            <h1>{t("content.whereYouWillSell")}</h1>
            <Col xl={{ span: 6, offset: 3 }}>
              <label className="input_label_plain_country">{t("country.country")}</label>
              <ThumbnailDropdownControl
                fieldConfig={CountryConfigurations(countryUser)}
                fieldValue={countryUser}
                key="contryCode"
                onChangeHandler={setValidateSelectedCountry}
              />
              {constants.VALID_COUNTRIES.hasOwnProperty(countryUser) && (
                <>
                  <label className="input_label_plain_country">{t("Tipo de persona")}</label>
                  <DropdownControl
                    fieldConfig={TypeOfPersonConfigurations(countryUser)}
                    key={constants.TYPE_OF_PERSON}
                    fieldValidator={() => { }}
                  />
                </>
              )}
              {warning && <Alert text={t("content.countryMismatchWarning")} />}
              {error && <Alert text={t("country.selectCountryAndTypeOfPerson")} color="var(--white)" backgroundColor="var(--red)" />}
            </Col>
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <div className="form_footer">
            <InputSubmit
              className={isValidCountryAndTypeOfPerson() ? "" : "submit_button_disbled"}
              id={AdobeAnalyticsButtonTags[0].countrySelection}
              fieldConfig={{ name: "Submit", default: "buttons.next" }}
              onClick={() => setCountryAndNavigate()}
            />
          </div>
        </Col>
      </Row>
    </Col>
  );
};

export default CountrySelector;
