import React, { useEffect, useState } from "react";
import { Dropdown, DropdownButton } from "react-bootstrap";

const ThumbnailDropdownControl  = ({ fieldConfig, onChangeHandler }) => {
    let  fieldClass = "outline-secondary input_plain width_100 thumbnail_dropdown";
    fieldClass += fieldConfig.class; 

  const [ dropdownValue, setDropdownValue ] = useState({});

    useEffect(() => {
        setDropdownValue({
            alt: fieldConfig.defaultOption.value,
            src: fieldConfig.defaultOption.thumbnail,
            label: fieldConfig.defaultOption.label  
        });
    },[fieldConfig.defaultOption]);
    
    const getDropdownOptionByCountry = (country) => {
        return fieldConfig.options.find((option) => option.value === country);
    }

    const thumbnailDropdownItemClickHandler = value => {
        const option = getDropdownOptionByCountry(value);
        setDropdownValue({
            alt: option.value,
            src: option.thumbnail,
            label: option.label  
        });

        onChangeHandler(value);
    }

    return (
        <DropdownButton
            variant={fieldClass}
            title={
                <div>
                    {/* TODO: create a css class to add style to this image (also use it in Dropdown.Item below) */}
                    <img 
                        alt={dropdownValue.alt} 
                        src={dropdownValue.src}
                    />
                    &nbsp;
                    {dropdownValue.label}
                </div>
            }
            name={fieldConfig.name}
            id={fieldConfig.id}
            data-cy={fieldConfig.id}
        >
            {fieldConfig.options.map( dropdownOption => {
                return (
                    <Dropdown.Item 
                        key={dropdownOption.id}
                        id={dropdownOption.id}
                        value={dropdownOption.value}
                        className="thumbnail_dropdown_item"
                        onClick={() => thumbnailDropdownItemClickHandler(dropdownOption.value)}
                    >
                        <img 
                            alt={dropdownOption.value} 
                            src={dropdownOption.thumbnail} 
                        />
                        &nbsp;
                        {dropdownOption.label}
                    </Dropdown.Item>
                )
            })}
        </DropdownButton>
    )
}

export default ThumbnailDropdownControl;
