import { empty } from "object-path";
import { module11 } from "./module11";
import { isLegalRut } from "./utils";
import constants from "../config/constants";

export const getFieldValidationErrors = (validators, formValues, fieldId, prevFieldValue = null) => {
  const fieldValue = formValues[fieldId];
  let error = null;
  validators.map(validator => {
    switch (validator.type) {
      case constants.VALIDATOR_RUT:
        if (!isEmpty(fieldValue) && !module11(fieldValue)) {
          error = validator.errorMessage;
        }
        break;
      case constants.VALIDATOR_RUT_IS_NATURAL_PERSON:
        if (!isEmpty(fieldValue) && isLegalRut(fieldValue)) {
          error = validator.errorMessage;
        }
        break;
      case constants.VALIDATOR_RUT_IS_LEGAL_PERSON:
        if (!isEmpty(fieldValue) && !isLegalRut(fieldValue)) {
          error = validator.errorMessage;
        }
        break;
      case constants.VALIDATOR_REGEX:
        if (!isEmpty(fieldValue) && !validator.data.regex.test(fieldValue)) {
          error = validator.errorMessage;
        }
        break;
      case constants.VALIDATOR_EMAIL:
        if (!isEmpty(fieldValue) && !isValidEmail(fieldValue)) {
          error = validator.errorMessage;
        }
        break;
      case constants.VALIDATOR_FILE_TYPE:
        if (
          !isEmpty(fieldValue) &&
          !isEmpty(fieldValue.type) &&
          !validator.data.allowedFileTypes.includes(fieldValue.type.split("/")[1])
        ) {
          error = validator.errorMessage;
        }
        break;
      case constants.VALIDATOR_FILE_SIZE:
        if (!isEmpty(fieldValue) && hasExceededAllowedFileSize(fieldValue, validator.data.maxAllowedSize)) {
          error = validator.errorMessage;
        }
        break;
      case constants.VALIDATOR_HAS_CURRENT_VALUE:
        if (prevFieldValue && !isEmpty(fieldValue)) {
          error = validator.errorMessage;
        }
        break;
      case constants.VALIDATOR_EQUAL_FIELD_VALUE:
        if (!equalFieldValue(fieldValue, formValues[validator.data.sourceFieldId])) {
          error = validator.errorMessage;
        }
        break;
      case constants.VALIDATOR_CONFIRMATION_FIELD_VALUE:
        if (
          !isEmpty(fieldValue) &&
          formValues[validator.data.sourceFieldId] &&
          !isEmpty(formValues[validator.data.sourceFieldId]) &&
          !equalFieldValue(fieldValue, formValues[validator.data.sourceFieldId])
        ) {
          error = validator.errorMessage;
        }
        break;
      case constants.VALIDATOR_VALID_OPTION:
        if (validator.options && !validator.options.includes(fieldValue)) {
          error = validator.errorMessage;
        }
        break;
      case constants.VALIDATOR_IS_NUMERIC:
        if (!isEmpty(fieldValue) && !isNumeric(fieldValue)) {
          error = validator.errorMessage;
        }
        break;
      case constants.VALIDATOR_MIN_LENGTH:
        if (!isEmpty(fieldValue) && validator.data.minLength > fieldValue.length) {
          error = validator.errorMessage;
        }
        break;
      case constants.VALIDATOR_MAX_LENGTH:
        if (!isEmpty(fieldValue) && validator.data.maxLength < fieldValue.length) {
          error = validator.errorMessage;
        }
        break;
      case constants.VALIDATOR_MAX_VALUE:
        if (!isEmpty(fieldValue) && parseFloat(validator.data.maxValue) < parseFloat(fieldValue)) {
          error = validator.errorMessage;
        }
        break;
      case constants.VALIDATOR_MIN_VALUE:
        if (!isEmpty(fieldValue) && parseFloat(validator.data.minValue) > parseFloat(fieldValue)) {
          error = validator.errorMessage;
        }
        break;
      case constants.VALIDATOR_IS_CHECKED:
        if (!fieldValue) {
          error = validator.errorMessage;
        }
        break;
      case constants.VALIDATOR_NOT_EMPTY:
        if (isEmpty(fieldValue)) {
          error = validator.errorMessage;
        }
        break;
      default:
        error = null;
    }

    return true;
  });

  return error;
};

export const getSellerCreationErrors = (fieldId, errors) => {
  let fieldError = undefined;
  let errorMessage = null;
  if (errors.length) {
    fieldError = errors.find(error => {
      return error.field === fieldId;
    });

    if (fieldError) {
      errorMessage = fieldError.errorMessage;
    }
  }

  return errorMessage;
};

export const getFormValidationErrors = (formFields, formValues) => {
  const errors = formFields.map(field => {
    const fieldError = getFieldValidationErrors(field.validators, formValues, field.id);
    return fieldError && { field: field.id, error: fieldError };
  });

  return errors.filter(error => error);
};

export const isValidForm = (formFields, formValues) => {
  const isValid = formFields.every(field => {
    if (field.required && getFieldValidationErrors(field.validators ?? [], formValues, field.id)) {
      return false;
    }

    return true;
  });

  return isValid;
};

const isEmpty = value => {
  if (value === "" || value === undefined || !value) {
    return true;
  }

  return false;
};

const isNumeric = value => {
  const regex = /^\d+$/;
  return regex.test(value);
};

const isValidEmail = value => {
  let regex = /^[A-Za-z0-9.!#$%&+_-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}$/;

  if (value.length < 25) {
    regex = /^\w+([\\.-]?[\w!#$%&+_-]+)*@\w+([\\.-]?\w+)*(\.\w{2,3})+$/;
  }

  return regex.test(value);
};

const equalFieldValue = (value, sourceValue) => {
  if (!empty(sourceValue) && sourceValue === value) {
    return true;
  }

  return false;
};

const hasExceededAllowedFileSize = (file, maxAllowedSize) => {
  return file.size > maxAllowedSize;
};
