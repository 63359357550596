import React from "react";
import { Form } from "react-bootstrap";
import { useStateValue } from "../../../store/StateProvider";
import ResetControl from "../InputControls/TextControl"

const InputReset = ({ fieldConfig }) => {
    const [{ hiddenFields }] = useStateValue();

    return (
        <Form.Group
            className={`mb3 ${fieldConfig.containerClass} ${hiddenFields.includes(fieldConfig.id) ? 'hidden' : ''}`}
            id={`container_${fieldConfig.id}`}
            controlId={fieldConfig.name}
        >
            <ResetControl fieldConfig={fieldConfig} />
        </Form.Group>
    )
}

export default InputReset;
