import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import TextControl from "../InputControls/TextControl";
import { useStateValue } from "../../../store/StateProvider";
import { getSellerCreationErrors, getFieldValidationErrors } from "../../../utils/validator";
import AsyncErrorValidation from "../../AsyncErrorValidation/AsyncErrorValidation";

const InputText = ({ fieldConfig }) => {
  const [{ hiddenFields, sellerCreationErrors, formValues }] = useStateValue();
  const [errorMessage, setErrorMessage] = useState(getSellerCreationErrors(fieldConfig.id, sellerCreationErrors));
  const [asyncError, setAsyncError] = useState(false);
  const [asyncValidationTriggerer, setAsyncValidationTriggerer] = useState(false);

  const labelClass = `input_label_plain ${fieldConfig.labelClass}`;

  const additionalErrorHandler = value => {
    setAsyncError(value);
  }

  const removeErrorMessages = () => {
    setErrorMessage(null);
    setAsyncError(null);
  };

  const executeFieldValidation = () => {
    const error = getFieldValidationErrors(fieldConfig.validators, formValues, fieldConfig.id);
    setErrorMessage(error);

    return error;
  };

  const executeAsyncValidations = () => {
    setAsyncValidationTriggerer(false);
    if (fieldConfig.asyncValidators && !getFieldValidationErrors(fieldConfig.validators, formValues, fieldConfig.id)) {
      setAsyncValidationTriggerer(true);
    }
  };

  useEffect(() => {
    executeAsyncValidations();
  }, []); // eslint-disable-line

  return (
    <Form.Group
      className={`mb3 ${fieldConfig.containerClass} ${hiddenFields.includes(fieldConfig.id) ? 'hidden' : ''}`}
      id={`container_${fieldConfig.id}`}
    >
      <Form.Label className={labelClass}>{fieldConfig.label}</Form.Label>
      <Form.Label className="label_description">{fieldConfig.labelDescription}</Form.Label>
      <TextControl
        fieldConfig={fieldConfig}
        fieldValidator={executeFieldValidation}
        additionalFieldValidator={() => executeAsyncValidations()}
        removeErrorMessage={() => removeErrorMessages()}
        hasError={!!errorMessage || asyncError}
      />
      {errorMessage &&
        <Form.Label className="label_error" id={`label_error_${fieldConfig.id}`}>
          <span id={`label_error_message_${fieldConfig.id}`}>{errorMessage}</span>
        </Form.Label>
      }
      {fieldConfig.asyncValidators &&
        <AsyncErrorValidation
          trigger={asyncValidationTriggerer}
          triggererHandler={setAsyncValidationTriggerer}
          error={asyncError}
          errorHandler={additionalErrorHandler}
          fieldConfig={fieldConfig}
          showOverlay={true}
        />
      }
    </Form.Group>
  );
};

export default InputText;
