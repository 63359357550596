import axios from "axios";

const END_POINT_DEFAULT = '/';

const getFormattedOutput = (sourceData, format) => {
    let output;

    if (format === "list") {
        output = sourceData.data.data.map((item => item.first_name))
    }

    return output;
}

const getDefaultEndpointData = async () => {
    const url = process.env.REACT_APP_SERVICE_URL_TEST;
    return await axios.get(url);
}

const serviceTest = async (endPoint, params) => {
    switch (endPoint) {
        case END_POINT_DEFAULT:
            let sourceData = await getDefaultEndpointData();
            return getFormattedOutput(sourceData, "list");
        default:
            return [];
    }
}

export default serviceTest;