const AdobeAnalyticsButtonTags = [
  {
    section: "sellerRegistrationCountrySelection",
    countrySelection: "btn-next-countrySelection",
    back: "btn-back-peopleInformation",
    next: "btn-next-peopleInformation"
  },
  {
    section: "sellerRegistrationPeopleInformation",
    back: "btn-back-companyInformation",
    next: "btn-next-companyInformation"
  },
  {
    section: "sellerRegistrationBusinessInformation",
    administrator: "btn-continue-administratorInformation",
    legal: "btn-continue-LegalRepInformation",
    shipmentWHContact: "btn-continue-shipWHContactInformation",
    returnWHContact: "btn-continue-retWHContactInformation",
    back: "btn-back-companyInformation",
    next: "btn-next-companyInformation"
  },
  {
    section: "sellerRegistrationTypeOfPersonSelection",
    businessAddress: "btn-continue-businessAddress",
    shipmentWH: "btn-continue-shipmentWHAddress",
    returnWH: "btn-continue-returnWHAddress",
    back: "btn-back-addressesInformation",
    next: "btn-next-addressesInformation"
  },
  {
    section: "sellerRegistrationAddressesInformation",
    conflictInterest: "btn-continue-conflictInterest",
    personExpPol: "btn-continue-personExpPol",
    crimePreventionFreeCompetition: "btn-continue-crimePreventionFreeCompetition",
    attached: "btn-continue-anexos",
    termsConditions: "btn-continue-termsConditions",
    back: "btn-back-declarationsInformation",
    next: "btn-next-declarationsInformation"
  },
  {
    section: "sellerRegistrationDeclarationsInformation"
  },
  {
    section: "sellerRegistrationSuccess"
  }
];

export default AdobeAnalyticsButtonTags;
