import React, { useState, useEffect, useRef } from "react";
import { useStateValue } from "../../../store/StateProvider";
import { actionTypes } from "../../../utils/constants";

const CheckboxControl = ({fieldConfig, removeErrorMessage, fieldValidator}) => {
    let fieldClass = "form-check-input ";
    fieldClass += fieldConfig.class;
    const [{formValues}, dispatch] = useStateValue();
    const [labelMandatory, setLabelMandatory] = useState( fieldConfig.labelMandatory ? true : false )
    const [checked, setChecked] = useState(formValues[fieldConfig.id] ? true : false);
    const prevValueRef = useRef();

    const fieldValueChangeHandler = () => {
        setChecked(!checked);
        setLabelMandatory(!checked);
    }

    useEffect(() => {
        if (prevValueRef.current !== undefined && prevValueRef.current !== checked) {
            fieldValidator(checked);
            dispatch({
                type: actionTypes.PRESERVE_GLOBAL_STATE,
                payload: { [fieldConfig.id] : checked ? fieldConfig.value : ""}
            });
        }
        prevValueRef.current = checked;
    }, [checked]); // eslint-disable-line

    return (
        <label htmlFor={fieldConfig.name} className={fieldConfig.labelClass}>
            <input
                type="checkbox"
                checked={checked}
                className={fieldClass}
                name={fieldConfig.name}
                id={fieldConfig.name}
                defaultValue={fieldConfig.default}
                label={fieldConfig.placeHolder}
                onClick={removeErrorMessage}
                onChange={fieldValueChangeHandler}
            />
            <span className="cb_label_text">
                {(labelMandatory) && <span className="span_mandatory">{fieldConfig.labelMandatory}</span>}
                {fieldConfig.placeHolder}
                {(fieldConfig.labelLink !== undefined) ?
                    <a 
                        href={fieldConfig.labelLink.url}
                        target={fieldConfig.labelLink.target} 
                        className={fieldConfig.labelLink.class}>
                        {fieldConfig.labelLink.text}
                    </a>
                    : ""
                }
            </span>
        </label>
    )
}

export default CheckboxControl;
