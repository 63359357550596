import React from "react";
import ReactDOM from "react-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import Head from "./components/Head/head";
import { App } from "./components/App/App";
import "./i18n";
import "./index.scss";
import { StateProvider } from "./store/StateProvider";
import reducer, { initialState } from "./store/reducer";

ReactDOM.render(
  <React.StrictMode>
    <StateProvider initialState={initialState} reducer={reducer}>
      <Head />
      <App />
    </StateProvider>
  </React.StrictMode>, document.getElementById("root"));
