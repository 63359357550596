import React from "react";

const Legend = (props) => {
    const config = {
        ...Legend.defaultProps.config,
        ...props.config
    }

    return (
        <fieldset data-testid="fieldset" className={config.class}>
            <legend data-testid="legend">{config.title.value}</legend>
            {
                config.label &&
                config.label.value &&
                <label data-testid="label">{config.label.value}</label>
            }
        </fieldset >
    );
}

Legend.defaultProps = {
    config: {
        class: "fieldset",
        title: {
            value: ''
        }
    }
};

export default Legend;