import React from "react";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const Element = props => {
    const {t} = useTranslation();
    let className = "input_button_next";
    if (props.className !== undefined) {
        className += ` ${props.className}`
    }
    let id = (props.id !== undefined) ? props.id : props.fieldConfig.name;

    return (
        <Button
            className={className}
            variant="default" 
            type="submit" 
            name={props.fieldConfig.name}
            id={id}
            onClick={props.onClick}
        >{t(props.fieldConfig.default)}</Button>
    )
}

export default Element;
