import { React, useEffect, useState } from "react";
import { useStateValue } from "../../../store/StateProvider";
import { Dropdown } from 'react-bootstrap';
import { actionTypes } from "../../../utils/constants";

const DropdownControl = ({ fieldConfig, fieldValidator }) => {
    const [{ formValues }, dispatch] = useStateValue();
    let defaultFieldValue = formValues[fieldConfig.id] ?? fieldConfig.default;
    let fieldClass = "form-select input_plain dropdown-control";
    fieldClass += fieldConfig.class;

    const getFieldLabel = fieldValue => {
        const fieldOption = fieldConfig.options.find(option => option.value === fieldValue);
        if (!fieldOption) {
            return fieldValue;
        }

        return fieldOption.label;
    }

    const [fieldValue, setFieldValue] = useState(defaultFieldValue);
    const [fieldLabel, setFieldLabel] = useState(getFieldLabel(defaultFieldValue));

    const fieldValueChangeHandler = value => {
        if (fieldConfig[actionTypes.OVERWRITE_FIELD_CONFIG] && value !== fieldValue) {
            dispatch({
                type: actionTypes.OVERWRITE_FIELD_CONFIG,
                payload: {
                    targetField: fieldConfig[actionTypes.OVERWRITE_FIELD_CONFIG].targetField,
                    newFieldConfig: fieldConfig[actionTypes.OVERWRITE_FIELD_CONFIG].options[value]
                }
            });
        }

        if (fieldConfig[[actionTypes.RESET_GLOBAL_STATE]] && value !== fieldValue) {
            console.log('entre')
            dispatch({
                type: actionTypes.RESET_GLOBAL_STATE
            })
        }

        setFieldValue(value);
        setFieldLabel(getFieldLabel(value));
        dispatch({
            type: actionTypes.PRESERVE_GLOBAL_STATE,
            payload: { [fieldConfig.id]: value }
        });
    }

    const onBlurEventHandler = () => {
        fieldValidator();
    }

    useEffect(() => {
        if (formValues[fieldConfig.id]) {
            fieldValidator();
        } else {
            setFieldValue(fieldConfig.default);
            setFieldLabel(getFieldLabel(fieldConfig.default));
        }
    }, [formValues[fieldConfig.id]]); // eslint-disable-line

    return (
        <Dropdown
            onBlur={() => onBlurEventHandler()}
        >
            <Dropdown.Toggle
                className={fieldClass}
                name={fieldConfig.name}
                id={fieldConfig.id}
                data-cy={fieldConfig.id}
                value={fieldValue}
            >
                {fieldLabel}
            </Dropdown.Toggle>

            <Dropdown.Menu
                className="menu-dropdown"
            >
                {fieldConfig.options.map((option) => (
                    <Dropdown.Item
                        className="dropdown-option"
                        key={option.value}
                        onClick={() => fieldValueChangeHandler(option.value)}
                    >
                        {option.label}
                    </Dropdown.Item>
                ))}
            </Dropdown.Menu>
        </Dropdown>
    )
}

export default DropdownControl;
