class Address {
  constructor(addressStreet, address2code, countryName) {
    this.address = {};
    this.address.address2code = address2code;
    this.address.addressStreet = addressStreet;
    this.address.country = countryName;
  }

  getData() {
    return this.address;
  }
}

export default Address;
