import React, { useState } from "react";
import { Form } from "react-bootstrap";
import RadioControl from "../InputControls/RadioControl";
import { useStateValue } from "../../../store/StateProvider";
import { getSellerCreationErrors, getFieldValidationErrors } from "../../../utils/validator";
import { customChangeHandler } from "../../../utils/CustomChangeHandler";
import { actionTypes } from "../../../utils/constants";

const InputRadio = ({ fieldConfig }) => {
  const [{ hiddenFields, formValues, sellerCreationErrors }, dispatch] = useStateValue();
  const [errorMessage, setErrorMessage] = useState(getSellerCreationErrors(fieldConfig.id, sellerCreationErrors));
  const [labelMandatory, setLabelMandatory] = useState(fieldConfig.labelMandatory ? true : false);

  const labelClass = `input_label_plain ${fieldConfig.labelClass}`;

  const setError = error => {
    setErrorMessage(error);
  };

  const executeFieldValidation = (value) => {
    const error = getFieldValidationErrors(fieldConfig.validators, { [fieldConfig.id]: value }, fieldConfig.id);
    setError(error);
  };

  const handleChange = (e) => {
    const valueFieldChange = customChangeHandler(e, fieldConfig, formValues);

    if (valueFieldChange) {
      const fieldChange = fieldConfig.onChange.params.field;

      dispatch({
        type: actionTypes.PRESERVE_GLOBAL_STATE,
        payload: { [fieldChange]: valueFieldChange }
      });
    }
  };

  return (
    <Form.Group
      className={`mb3 ${fieldConfig.containerClass} ${hiddenFields.includes(fieldConfig.id) ? 'hidden' : ''}`}
      id={`container_${fieldConfig.id}`}
      controlId={fieldConfig.id}
    >
      <Form.Label className={labelClass}>
        {labelMandatory && !formValues[fieldConfig.id] && (
          <span className="span_mandatory">{fieldConfig.labelMandatory}</span>
        )}
        {fieldConfig.label}
      </Form.Label>
      <Form.Label className="label_description">{fieldConfig.labelDescription}</Form.Label>
      <RadioControl
        fieldConfig={fieldConfig}
        fieldValidator={executeFieldValidation}
        removeErrorMessage={setError.bind(this, null)}
        changeLabelMandatory={setLabelMandatory}
        onChange={handleChange}
      />
      <input
        type="hidden"
        id={fieldConfig.id}
        name={fieldConfig.id}
        value={formValues[fieldConfig.id] ? formValues[fieldConfig.id] : ""}
        readOnly
      />
      <Form.Label className={`label_error ${!!errorMessage ? "show" : "hidden"}`} id={`label_error_${fieldConfig.id}`}>
        <span id={`label_error_message_${fieldConfig.id}`}>{errorMessage}</span>
        <button type="button" onClick={setError.bind(this, null)} className="error_close_button">
          X
        </button>
      </Form.Label>
    </Form.Group>
  );
};

export default InputRadio;
