import React from "react";
import { Button } from "react-bootstrap";

const ResetControl = props => {
    let  fieldClass = "";
    fieldClass += props.fieldConfig.class; 
    
    return (
        <Button 
            variant="danger" 
            className={fieldClass}
            type="reset" 
            name={props.fieldConfig.name}
            id={props.fieldConfig.name}
        >{props.fieldConfig.default}</Button>
    )
}

export default ResetControl;
