import { React, useState, useEffect } from "react";
import { useStateValue } from "../../../store/StateProvider";
import { Typeahead } from "react-bootstrap-typeahead";
import { useTranslation } from 'react-i18next';
import dataSourceAggregator from "../../Integrations/dataSourceAggregator"
import { actionTypes } from "../../../utils/constants";
import constants from "../../../config/constants";

const SearchControl = ({ fieldConfig, fieldValidator, hasError}) => {
    const { t } = useTranslation();
    const [{ country, currentStep, formValues }, dispatch] = useStateValue();
    let dataSource = fieldConfig.dataSource;
    dataSource.globalStateValues = { country, currentStep, formValues };
    const defaultOption = formValues[fieldConfig.name] ?? '';

    let  fieldClass = "input_plain ";
    fieldClass += fieldConfig.class;
    if (hasError) {
        fieldClass += " has_error is-invalid"
    }
     

    let inputProps = {
        className: fieldClass,
        id: fieldConfig.id
    }
    
    const [fieldValue, setFieldValue] = useState(fieldConfig.default);
    const [options, setOptions] = useState([]);
    const [validators, setValidators] = useState(fieldConfig.validators);
    
    const checkRemoteValidations = () => {
        if(fieldConfig.remoteValidation){
            dispatch({
                type: actionTypes.SET_FIELD_MUST_RUN_VALIDATIONS,
                payload: fieldConfig.remoteValidation,
            });
        }
    }

    useEffect(() => {
        const loadOptions = async () => {
            const sourceData = await dataSourceAggregator(fieldConfig.dataSource);
            if (sourceData !== undefined && sourceData !== "" && sourceData !== null) {
                setOptions(sourceData);
                setValidators(() => {
                    return fieldConfig.validators.map(validator => {
                        if(validator.type === constants.VALIDATOR_VALID_OPTION){
                            validator.options = sourceData;
                        }
                        return validator;
                    });
                });
            }
        }

        loadOptions();
    }, [fieldConfig.dataSource, fieldConfig.validators]);

    useEffect(() => {
        if (fieldValue) {
            fieldValidator(validators);
        }
    }, [fieldValue]); // eslint-disable-line

    const fieldValueChangeHandler = event => {
        setFieldValue(event[0]);
        dispatch({
            type: actionTypes.PRESERVE_GLOBAL_STATE,
            payload: { [fieldConfig.id] : event[0] }
        });
        checkRemoteValidations();
    }

    return (
        <Typeahead
            name={fieldConfig.name}
            defaultSelected={[defaultOption]}
            id={fieldConfig.id}
            emptyLabel={t("content.withoutResults")}
            promptText={t("content.searching")}
            searchText={t("content.searching")}
            options={options}
            onChange={fieldValueChangeHandler}
            onBlur={() => fieldValidator(validators)}
            placeholder={fieldConfig.placeHolder}
            inputProps={inputProps}
            paginate={false}
            maxResults={options.length}
        />
    )
}

export default SearchControl;