import { React, useState } from "react";

const DropFileControl = props => {
    let  fieldClass = "image-input has_error";
    fieldClass += props.fieldConfig.class; 
    
    const [fieldValue, setFieldValue] = useState("");
    const fieldValueChangeHandler = event => {
        setFieldValue(event.target.value);
    }

    const executeFieldValidation = () => {
        props.fieldValidator(props.fieldConfig.validators, fieldValue, props.fieldConfig.id);
    }

    return (
        <div className="image-upload"> 
            <label  htmlFor="file_upload">
                <div className="h-100">
                    <div className="dplay-tbl-cell"> 
                        <p className="dropfilecontrol_label_description">
                            {props.fieldConfig.controlLabel[0]}
                            <span className="dropfilecontrol_label_description_sub"> {props.fieldConfig.controlLabel[1]}</span>
                        </p>
                    </div>
                </div>
                <input 
                    type="file" 
                    name={props.fieldConfig.name}
                    id={props.fieldConfig.id}
                    data-cy={props.fieldConfig.id}
                    className={fieldClass} 
                    data-traget-resolution="image_resolution" 
                    onBlur={executeFieldValidation}
                    onChange={fieldValueChangeHandler}
                    onClick={props.removeErrorMessage}
                />
            </label>
        </div>
    )
}

export default DropFileControl;
