import { getBankSectionPerCountry } from "../CountryConfigurations";
import AdobeAnalyticsButtonTags from "../../constants/AdobeAnalyticsButtonTags";
import { actionTypes } from "../../utils/constants";
import constants from "../constants";

const naturalPerson = [
    {
        section: {
            id: 1,
            title: "Información de personas",
            isActive: true,
            stepperNumber: 1,
            stepperLabel: "Personas",
            type: "Accordion",
            activeSubSection: "1-1",
            itShouldHideAccordionNavigators: true,
        },
        subSections: [
            {
                id: "1-1",
                title: "Responsable de la empresa",
                subTitle: "Es la persona dueña del NIT y será el contacto principal de la cuenta en Falabella Seller Center.",
                enabled: true,
                showNavigation: true,
                navigationButtonId: AdobeAnalyticsButtonTags[2].administrator,
                onRenderActions: {
                    subTitle: {
                        handler: "RenderSubTitle",
                        params: {
                            renderSubtitleWith: [
                                constants.INPUT_ID_LEGAL_REPRESENTATIVE_NAME,
                                constants.INPUT_ID_LEGAL_REPRESENTATIVE_LASTNAME
                            ]
                        }
                    }
                },
                onNavigateActions: {
                    next: {
                        handler: constants.ACTION_HANDLER_RENDER_SUBTITLE_AND_FOCUS_SUBSECTION,
                        params: {
                            nextSubSection: "1-2",
                            renderSubtitleWith: [
                                constants.INPUT_ID_LEGAL_REPRESENTATIVE_NAME
                            ]
                        }
                    },
                    previous: ""
                },
                autofill : {
                    message: "Usar esta información para todos los contactos.",
                    affectedSections: [ "1-2", "1-3" ],
                    behavior: {
                        [constants.INPUT_ID_SHIPMENT_WAREHOUSE_CONTACT_NAME] : [constants.INPUT_ID_LEGAL_REPRESENTATIVE_NAME, constants.INPUT_ID_LEGAL_REPRESENTATIVE_LASTNAME],
                        [constants.INPUT_ID_SHIPMENT_WAREHOUSE_CONTACT_PHONE] : constants.INPUT_ID_LEGAL_REPRESENTATIVE_PHONE,
                        [constants.INPUT_ID_SHIPMENT_WAREHOUSE_CONTACT_EMAIL] : constants.INPUT_ID_LEGAL_REPRESENTATIVE_EMAIL,
                        'confirmShipmentWarehouseContactEmail' : "confirmLegalRepEmail",
                        [constants.INPUT_ID_RETURN_WAREHOUSE_CONTACT_NAME] : [constants.INPUT_ID_LEGAL_REPRESENTATIVE_NAME, constants.INPUT_ID_LEGAL_REPRESENTATIVE_LASTNAME],
                        [constants.INPUT_ID_RETURN_WAREHOUSE_CONTACT_PHONE] : constants.INPUT_ID_LEGAL_REPRESENTATIVE_PHONE,
                        [constants.INPUT_ID_RETURN_WAREHOUSE_CONTACT_EMAIL] : constants.INPUT_ID_LEGAL_REPRESENTATIVE_EMAIL,
                        'confirmReturnWarehouseContactEmail' : "confirmLegalRepEmail",
                    }
                },
                fields: [
                    {
                        class: "",
                        containerClass: "",
                        default: "",
                        id: constants.INPUT_ID_LEGAL_REPRESENTATIVE_NAME,
                        label: "Nombre",
                        labelEn: "",
                        labelClass: "",
                        labelDescription: "",
                        name: constants.INPUT_ID_LEGAL_REPRESENTATIVE_NAME,
                        placeHolder: "Ej. Juan",
                        required:true,
                        type: constants.INPUT_TYPE_TEXT,
                        maxLength: "49",
                        customHandler: constants.INPUT_HANDLER_ONLY_LETTERS_AND_SPACES,
                        validators: [
                            {
                                type: constants.VALIDATOR_NOT_EMPTY,
                                errorMessage: "Ingresa un nombre."
                            }
                        ]
                    },
                    {
                        class: "",
                        containerClass: "",
                        default: "",
                        id: constants.INPUT_ID_LEGAL_REPRESENTATIVE_LASTNAME,
                        label: "Apellido",
                        labelEn: "",
                        labelClass: "",
                        labelDescription: "",
                        name: constants.INPUT_ID_LEGAL_REPRESENTATIVE_LASTNAME,
                        placeHolder: "Ej. Olivares",
                        required:true,
                        type: constants.INPUT_TYPE_TEXT,
                        maxLength: "49",
                        customHandler: constants.INPUT_HANDLER_ONLY_LETTERS_AND_SPACES,
                        validators: [
                            {
                                type: constants.VALIDATOR_NOT_EMPTY,
                                errorMessage: "Ingresa un apellido."
                            }
                        ]
                    },
                    {
                        class: "",
                        containerClass: "",
                        dataSource: "",
                        default: "",
                        fakeValue: "",
                        id: constants.INPUT_ID_LEGAL_REPRESENTATIVE_DOCUMENT_TYPE,
                        label: "Tipo de documento",
                        labelClass: "",
                        labelDescription: "",
                        name: constants.INPUT_ID_LEGAL_REPRESENTATIVE_DOCUMENT_TYPE,
                        [actionTypes.OVERWRITE_FIELD_CONFIG]: {
                            targetField: constants.INPUT_ID_LEGAL_REPRESENTATIVE_DOCUMENT_NUMBER,
                            options:{
                                "CC": {
                                    maxLength: "10",
                                    customHandler: constants.INPUT_HANDLER_ONLY_NUMBERS,
                                    validators: [
                                        {
                                            type: constants.VALIDATOR_MIN_LENGTH,
                                            errorMessage: "Debes ingresar un número válido.",
                                            data: {
                                                minLength: 5
                                            }
                                        },
                                        {
                                            type: constants.VALIDATOR_NOT_EMPTY,
                                            errorMessage: "Ingresa un número de documento de identificación."
                                        }
                                    ]
                                },
                                "CE": {
                                    maxLength: "7",
                                    customHandler: constants.INPUT_HANDLER_ONLY_NUMBERS,
                                    validators: [
                                        {
                                            type: constants.VALIDATOR_MIN_LENGTH,
                                            errorMessage: "Debes ingresar un número válido.",
                                            data: {
                                                minLength: 6
                                            }
                                        },
                                        {
                                            type: constants.VALIDATOR_NOT_EMPTY,
                                            errorMessage: "Ingresa un número de documento de identificación."
                                        }
                                    ]
                                },
                                "PAS": {
                                    maxLength: "12",
                                    customHandler: constants.INPUT_HANDLER_ONLY_LETTERS_AND_NUMBERS,
                                    validators: [
                                        {
                                            type: constants.VALIDATOR_MIN_LENGTH,
                                            errorMessage: "Debes ingresar un número válido.",
                                            data: {
                                                minLength: 5
                                            }
                                        },
                                        {
                                            type: constants.VALIDATOR_NOT_EMPTY,
                                            errorMessage: "Ingresa un número de documento de identificación."
                                        }
                                    ]
                                }
                            }
                        },
                        options: [
                            {
                                id: "legalRepDocumentType_1",
                                label: "Cedula de ciudadanía",
                                value: "CC"
                            },
                            {
                                id: "legalRepDocumentType_2",
                                label: "Carnet de extranjería",
                                value: "CE"
                            },
                            {
                                id: "legalRepDocumentType_3",
                                label: "Pasaporte",
                                value: "PAS"
                            },
                        ],
                        placeHolder: "",
                        required: true,
                        type: constants.INPUT_TYPE_DROPDOWN,
                        validators: [
                            {
                                type: constants.VALIDATOR_NOT_EMPTY,
                                errorMessage: "Este campo es obligatorio, selecciona una opción."
                            }
                        ]
                    },
                    {
                        class: "",
                        containerClass: "",
                        default: "",
                        id: constants.INPUT_ID_LEGAL_REPRESENTATIVE_DOCUMENT_NUMBER,
                        label: "Número de documento de identificación",
                        labelEn: "",
                        labelClass: "",
                        labelDescription: "",
                        name: constants.INPUT_ID_LEGAL_REPRESENTATIVE_DOCUMENT_NUMBER,
                        placeHolder: "Ej. 123456789012",
                        required:true,
                        type: constants.INPUT_TYPE_TEXT,
                        maxLength: "12",
                        customHandler: constants.INPUT_HANDLER_ONLY_LETTERS_AND_NUMBERS,
                        validators: [
                            {
                                type: constants.VALIDATOR_NOT_EMPTY,
                                errorMessage: "Ingresa un número de documento de identificación."
                            }
                        ]
                    },
                    {
                        class: "",
                        containerClass: "",
                        dataSource: "",
                        id: constants.INPUT_ID_LEGAL_REPRESENTATIVE_DOCUMENT_FILE,
                        label: "Adjunta una imagen del documento de identificación del responsable de la empresa",
                        labelClass: "",
                        labelDescription: "Cara frontal, donde se encuentra la foto.",
                        boxDescription: "1 Archivo permitido JPG, PNG o PDF.",
                        name: constants.INPUT_ID_LEGAL_REPRESENTATIVE_DOCUMENT_FILE,
                        type: constants.INPUT_TYPE_FILE,
                        required:true,
                        attachTextButton: "Adjunta",
                        attachTextLabel: "o arrastra el archivo",
                        dropTextMessage: "Suelta el archivo en esta área",
                        accept: ".jpg,.jpeg,.png,.pdf",
                        validators: [
                            {
                                type: constants.VALIDATOR_FILE_SIZE,
                                errorMessage: "El archivo excede el límite permitido, no debe superar 3MB.",
                                data: {
                                    maxAllowedSize: 3e6
                                }
                            },
                            {
                                type: constants.VALIDATOR_FILE_TYPE,
                                errorMessage: "Archivo inválido, adjunta solo JPG, PNG o PDF.",
                                data: {
                                    allowedFileTypes: ["jpg", "jpeg", "png", "pdf"]
                                }
                            },
                            {
                                type: constants.VALIDATOR_HAS_CURRENT_VALUE,
                                errorMessage: "Solo puedes subir 1 archivo. Si quieres adjuntar un archivo diferente, tienes que eliminar el actual."
                            },
                            {
                                type: constants.VALIDATOR_NOT_EMPTY,
                                errorMessage: "Debes adjuntar la imagen del documento de identificación."
                            }
                        ]
                    },
                    {
                        class: "field_group_inline",
                        containerClass: "",
                        id: "phoneNumberGroupLegalRep",
                        label: "Teléfono",
                        labelClass: "",
                        name: "phoneNumberGroupLegalRep",
                        type: constants.INPUT_TYPE_FIELDGROUP,
                        subFields: [
                            {
                                class: "field_group_label padding_top_5",
                                containerClass: "field_group_inline_left width_10",
                                id: "legalRepPhoneCountryCode",
                                label: "+",
                                labelClass: "",
                                name: "legalRepPhoneCountryCode",
                                type: constants.INPUT_TYPE_LABEL,
                                customHandler: "renderPhoneCountryCode"
                            },
                            {
                                class: "",
                                containerClass: "field_group_inline_right width_90",
                                default: "",
                                id: constants.INPUT_ID_LEGAL_REPRESENTATIVE_PHONE,
                                label: " ",
                                labelClass: "",
                                name: constants.INPUT_ID_LEGAL_REPRESENTATIVE_PHONE,
                                options: [],
                                order: 2,
                                placeHolder: "Ej. 983456789",
                                required: true,
                                type: constants.INPUT_TYPE_TEXT,
                                maxLength: "10",
                                customHandler: constants.INPUT_HANDLER_ONLY_NUMBERS,
                                remoteValidation: [constants.INPUT_ID_LEGAL_REPRESENTATIVE_DOCUMENT_FILE],
                                validators: [
                                    {
                                        type: constants.VALIDATOR_NOT_EMPTY,
                                        errorMessage: "Ingresa un teléfono."
                                    },
                                    {
                                        type: constants.VALIDATOR_MIN_LENGTH,
                                        errorMessage: "El teléfono debe contener 10 dígitos.",
                                        data: {
                                            minLength: 10
                                        }
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        class: "",
                        containerClass: "",
                        default: "",
                        id: constants.INPUT_ID_LEGAL_REPRESENTATIVE_EMAIL,
                        label: "Correo electrónico",
                        labelEn: "",
                        labelClass: "",
                        labelDescription: "",
                        name: constants.INPUT_ID_LEGAL_REPRESENTATIVE_EMAIL,
                        placeHolder: "Ej. contacto@info.com",
                        required:true,
                        type: constants.INPUT_TYPE_TEXT,
                        customHandler: constants.INPUT_HANDLER_ONLY_VALID_EMAILS_CHARS,
                        maxLength: "120",
                        validators: [
                            {
                                type: constants.VALIDATOR_CONFIRMATION_FIELD_VALUE,
                                errorMessage: "El email ingresado no coincide con su confirmación.",
                                data: {
                                    sourceFieldId: "confirmLegalRepEmail"
                                }
                            },
                            {
                                type: constants.VALIDATOR_EMAIL,
                                errorMessage: "Debes ingresar un correo electrónico válido."
                            },
                            {
                                type: constants.VALIDATOR_MIN_LENGTH,
                                errorMessage: "Debes ingresar al menos 5 caracteres.",
                                data: {
                                    minLength: 5
                                }
                            },
                            {
                                type: constants.VALIDATOR_NOT_EMPTY,
                                errorMessage: "Ingresa un correo electrónico válido."
                            }
                        ],
                        asyncValidators: [
                            {
                                errorMessage: constants.VALIDATION_MESSAGE_SPANISH_FPAY_EXISTING_ACCOUNT,
                                color: "var(--grey-heavy)",
                                backgroundColor: "var(--grey-faded)",
                                type: constants.DATASOURCE_TYPE_API,
                                source: constants.SERVICE_BFF,
                                endpoint: constants.END_POINT_FPAY_EMAIL_VALIDATION,
                                params: [
                                    {
                                        name: "country",
                                        sourceType: "GlobalStateValue",
                                        sourceId: "country"
                                    },
                                    {
                                        name: "email",
                                        sourceType: "GlobalStateValue",
                                        sourceId: constants.INPUT_ID_LEGAL_REPRESENTATIVE_EMAIL
                                    }
                                ]

                            }
                        ]
                    },
                    {
                        class: "",
                        containerClass: "",
                        default: "",
                        id: "confirmLegalRepEmail",
                        label: "Confirma correo electrónico",
                        labelEn: "",
                        labelClass: "",
                        labelDescription: "",
                        name: "confirmLegalRepEmail",
                        placeHolder: "Ej. contacto@info.com",
                        required:true,
                        type: constants.INPUT_TYPE_TEXT,
                        customHandler: constants.INPUT_HANDLER_ONLY_VALID_EMAILS_CHARS,
                        maxLength: "120",
                        validators: [
                            {
                                type: constants.VALIDATOR_EQUAL_FIELD_VALUE,
                                errorMessage: "Por favor revisa este dato, no es igual al correo ingresado en el campo anterior.",
                                data: {
                                    sourceFieldId: constants.INPUT_ID_LEGAL_REPRESENTATIVE_EMAIL
                                }
                            },
                            {
                                type: constants.VALIDATOR_EMAIL,
                                errorMessage: "Debes ingresar un correo electrónico válido."
                            },
                            {
                                type: constants.VALIDATOR_MIN_LENGTH,
                                errorMessage: "Debes ingresar al menos 5 caracteres.",
                                data: {
                                    minLength: 5
                                }
                            },
                            {
                                type: constants.VALIDATOR_NOT_EMPTY,
                                errorMessage: "Ingresa un electrónico."
                            }
                        ]
                    }
                ]
            },
            {
                id: "1-2",
                title: "Contacto bodega de envío",
                subTitle: "",
                enabled: false,
                showNavigation: true,
                navigationButtonId: AdobeAnalyticsButtonTags[2].shipmentWHContact,
                onRenderActions: {
                    subTitle: {
                        handler: "RenderSubTitle",
                        params: {
                            renderSubtitleWith: [
                                constants.INPUT_ID_SHIPMENT_WAREHOUSE_CONTACT_NAME
                            ]
                        }
                    }
                },
                onNavigateActions: {
                    next: {
                        handler: constants.ACTION_HANDLER_RENDER_SUBTITLE_AND_FOCUS_SUBSECTION,
                        params: {
                            nextSubSection: "1-3",
                            renderSubtitleWith: [
                                constants.INPUT_ID_SHIPMENT_WAREHOUSE_CONTACT_NAME
                            ]
                        }
                    },
                    previous: ""
                },
                autofill : {
                    message: "Usar esta información para contacto de bodega de devolución.",
                    affectedSections: [ "1-3" ],
                    behavior: {
                        [constants.INPUT_ID_RETURN_WAREHOUSE_CONTACT_NAME] : constants.INPUT_ID_SHIPMENT_WAREHOUSE_CONTACT_NAME,
                        [constants.INPUT_ID_RETURN_WAREHOUSE_CONTACT_PHONE] : constants.INPUT_ID_SHIPMENT_WAREHOUSE_CONTACT_PHONE,
                        [constants.INPUT_ID_RETURN_WAREHOUSE_CONTACT_EMAIL] : constants.INPUT_ID_SHIPMENT_WAREHOUSE_CONTACT_EMAIL,
                        'confirmReturnWarehouseContactEmail' : "confirmShipmentWarehouseContactEmail"
                    }
                },
                fields: [
                    {
                        class: "",
                        containerClass: "",
                        default: "",
                        id: constants.INPUT_ID_SHIPMENT_WAREHOUSE_CONTACT_NAME,
                        label: "Nombre completo",
                        labelEn: "",
                        labelClass: "",
                        labelDescription: "",
                        name: constants.INPUT_ID_SHIPMENT_WAREHOUSE_CONTACT_NAME,
                        placeHolder: "Ej. Juan Olivares",
                        required:true,
                        type: constants.INPUT_TYPE_TEXT,
                        maxLength: "99",
                        customHandler: constants.INPUT_HANDLER_ONLY_LETTERS_AND_SPACES,
                        validators: [
                            {
                                type: constants.VALIDATOR_NOT_EMPTY,
                                errorMessage: "Ingresa un nombre completo."
                            }
                        ]
                    },
                    {
                        class: "field_group_inline",
                        containerClass: "",
                        id: "phoneNumberGroupShipmentWarehouseContact",
                        label: "Teléfono",
                        labelClass: "",
                        name: "phoneNumberGroupShipmentWarehouseContact",
                        type: constants.INPUT_TYPE_FIELDGROUP,
                        subFields: [
                            {
                                class: "field_group_label padding_top_5",
                                containerClass: "field_group_inline_left width_10",
                                id: "shipmentWarehouseContactPhoneCountryCode",
                                label: "+",
                                labelClass: "",
                                name: "shipmentWarehouseContactPhoneCountryCode",
                                type: constants.INPUT_TYPE_LABEL,
                                customHandler: "renderPhoneCountryCode"
                            },
                            {
                                class: "",
                                containerClass: "field_group_inline_right width_90",
                                default: "",
                                id: constants.INPUT_ID_SHIPMENT_WAREHOUSE_CONTACT_PHONE,
                                label: " ",
                                labelClass: "",
                                name: constants.INPUT_ID_SHIPMENT_WAREHOUSE_CONTACT_PHONE,
                                options: [],
                                order: 2,
                                placeHolder: "Ej. 983456789",
                                required: true,
                                type: constants.INPUT_TYPE_TEXT,
                                maxLength: "10",
                                customHandler: constants.INPUT_HANDLER_ONLY_NUMBERS,
                                validators: [
                                    {
                                        type: constants.VALIDATOR_NOT_EMPTY,
                                        errorMessage: "Ingresa un teléfono."
                                    },
                                    {
                                        type: constants.VALIDATOR_MIN_LENGTH,
                                        errorMessage: "El teléfono debe contener 10 dígitos.",
                                        data: {
                                            minLength: 10
                                        }
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        class: "",
                        containerClass: "",
                        default: "",
                        id: constants.INPUT_ID_SHIPMENT_WAREHOUSE_CONTACT_EMAIL,
                        label: "Correo electrónico",
                        labelEn: "",
                        labelClass: "",
                        labelDescription: "",
                        name: constants.INPUT_ID_SHIPMENT_WAREHOUSE_CONTACT_EMAIL,
                        placeHolder: "Ej. contacto@info.com",
                        required:true,
                        type: constants.INPUT_TYPE_TEXT,
                        customHandler: constants.INPUT_HANDLER_ONLY_VALID_EMAILS_CHARS,
                        maxLength: "120",
                        validators: [
                            {
                                type: constants.VALIDATOR_CONFIRMATION_FIELD_VALUE,
                                errorMessage: "El email ingresado no coincide con su confirmación.",
                                data: {
                                    sourceFieldId: "confirmShipmentWarehouseContactEmail"
                                }
                            },
                            {
                                type: constants.VALIDATOR_EMAIL,
                                errorMessage: "Debes ingresar un correo electrónico válido."
                            },
                            {
                                type: constants.VALIDATOR_MIN_LENGTH,
                                errorMessage: "Debes ingresar al menos 5 caracteres.",
                                data: {
                                    minLength: 5
                                }
                            },
                            {
                                type: constants.VALIDATOR_NOT_EMPTY,
                                errorMessage: "Ingresa un correo electrónico válido."
                            }
                        ],
                        asyncValidators: [
                            {
                                errorMessage: constants.VALIDATION_MESSAGE_SPANISH_FPAY_EXISTING_ACCOUNT,
                                color: "var(--grey-heavy)",
                                backgroundColor: "var(--grey-faded)",
                                type: constants.DATASOURCE_TYPE_API,
                                source: constants.SERVICE_BFF,
                                endpoint: constants.END_POINT_FPAY_EMAIL_VALIDATION,
                                params: [
                                    {
                                        name: "country",
                                        sourceType: "GlobalStateValue",
                                        sourceId: "country"
                                    },
                                    {
                                        name: "email",
                                        sourceType: "GlobalStateValue",
                                        sourceId: constants.INPUT_ID_LEGAL_REPRESENTATIVE_EMAIL
                                    }
                                ]

                            }
                        ]
                    },
                    {
                        class: "",
                        containerClass: "",
                        default: "",
                        id: "confirmShipmentWarehouseContactEmail",
                        label: "Confirma correo electrónico",
                        labelEn: "",
                        labelClass: "",
                        labelDescription: "",
                        name: "confirmShipmentWarehouseContactEmail",
                        placeHolder: "Ej. contacto@info.com",
                        required:true,
                        type: constants.INPUT_TYPE_TEXT,
                        customHandler: constants.INPUT_HANDLER_ONLY_VALID_EMAILS_CHARS,
                        maxLength: "120",
                        validators: [
                            {
                                type: constants.VALIDATOR_EQUAL_FIELD_VALUE,
                                errorMessage: "Por favor revisa este dato, no es igual al correo ingresado en el campo anterior.",
                                data: {
                                    sourceFieldId: constants.INPUT_ID_SHIPMENT_WAREHOUSE_CONTACT_EMAIL
                                }
                            },
                            {
                                type: constants.VALIDATOR_EMAIL,
                                errorMessage: "Debes ingresar un correo electrónico válido."
                            },
                            {
                                type: constants.VALIDATOR_MIN_LENGTH,
                                errorMessage: "Debes ingresar al menos 5 caracteres.",
                                data: {
                                    minLength: 5
                                }
                            },
                            {
                                type: constants.VALIDATOR_NOT_EMPTY,
                                errorMessage: "Ingresa un electrónico."
                            }
                        ]
                    }
                ]
            },
            {
                id: "1-3",
                title: "Contacto bodega de devolución",
                subTitle: "",
                enabled: false,
                showNavigation: false,
                navigationButtonId: AdobeAnalyticsButtonTags[2].returnWHContact,
                onRenderActions: {
                    subTitle: {
                        handler: "RenderSubTitle",
                        params: {
                            renderSubtitleWith: [
                                constants.INPUT_ID_RETURN_WAREHOUSE_CONTACT_NAME
                            ]
                        }
                    }
                },
                onNavigateActions: {
                    next: {
                        handler: constants.ACTION_HANDLER_RENDER_SUBTITLE_AND_ACTIVATE_PAGE_NAVIGATION,
                        params: {
                            currentSubSection: "1-3",
                            renderSubtitleWith: [
                                constants.INPUT_ID_RETURN_WAREHOUSE_CONTACT_NAME
                            ]
                        }
                    },
                    previous: ""
                },
                fields: [
                    {
                        class: "",
                        containerClass: "",
                        default: "",
                        id: constants.INPUT_ID_RETURN_WAREHOUSE_CONTACT_NAME,
                        label: "Nombre completo",
                        labelEn: "",
                        labelClass: "",
                        labelDescription: "",
                        name: constants.INPUT_ID_RETURN_WAREHOUSE_CONTACT_NAME,
                        placeHolder: "Ej. Juan Olivares",
                        required:true,
                        type: constants.INPUT_TYPE_TEXT,
                        maxLength: "99",
                        customHandler: constants.INPUT_HANDLER_ONLY_LETTERS_AND_SPACES,
                        validators: [
                            {
                                type: constants.VALIDATOR_NOT_EMPTY,
                                errorMessage: "Ingresa un nombre completo."
                            }
                        ]
                    },
                    {
                        class: "field_group_inline",
                        containerClass: "",
                        id: "phoneNumberGroupReturnWarehouseContact",
                        label: "Teléfono",
                        labelClass: "",
                        name: "phoneNumberGroupReturnWarehouseContact",
                        type: constants.INPUT_TYPE_FIELDGROUP,
                        subFields: [
                            {
                                class: "field_group_label padding_top_5",
                                containerClass: "field_group_inline_left width_10",
                                id: "returnWarehouseContactPhoneCountryCode",
                                label: "+",
                                labelClass: "",
                                name: "returnWarehouseContactPhoneCountryCode",
                                type: constants.INPUT_TYPE_LABEL,
                                customHandler: "renderPhoneCountryCode"
                            },
                            {
                                class: "",
                                containerClass: "field_group_inline_right width_90",
                                default: "",
                                id: constants.INPUT_ID_RETURN_WAREHOUSE_CONTACT_PHONE,
                                label: " ",
                                labelClass: "",
                                name: constants.INPUT_ID_RETURN_WAREHOUSE_CONTACT_PHONE,
                                options: [],
                                order: 2,
                                placeHolder: "Ej. 983456789",
                                required: true,
                                type: constants.INPUT_TYPE_TEXT,
                                maxLength: "10",
                                customHandler: constants.INPUT_HANDLER_ONLY_NUMBERS,
                                validators: [
                                    {
                                        type: constants.VALIDATOR_NOT_EMPTY,
                                        errorMessage: "Ingresa un teléfono."
                                    },
                                    {
                                        type: constants.VALIDATOR_MIN_LENGTH,
                                        errorMessage: "El teléfono debe contener 10 dígitos.",
                                        data: {
                                            minLength: 10
                                        }
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        class: "",
                        containerClass: "",
                        default: "",
                        id: constants.INPUT_ID_RETURN_WAREHOUSE_CONTACT_EMAIL,
                        label: "Correo electrónico",
                        labelEn: "",
                        labelClass: "",
                        labelDescription: "",
                        name: constants.INPUT_ID_RETURN_WAREHOUSE_CONTACT_EMAIL,
                        placeHolder: "Ej. contacto@info.com",
                        required:true,
                        type: constants.INPUT_TYPE_TEXT,
                        customHandler: constants.INPUT_HANDLER_ONLY_VALID_EMAILS_CHARS,
                        maxLength: "120",
                        validators: [
                            {
                                type: constants.VALIDATOR_CONFIRMATION_FIELD_VALUE,
                                errorMessage: "El email ingresado no coincide con su confirmación.",
                                data: {
                                    sourceFieldId: "confirmReturnWarehouseContactEmail"
                                }
                            },
                            {
                                type: constants.VALIDATOR_EMAIL,
                                errorMessage: "Debes ingresar un correo electrónico válido."
                            },
                            {
                                type: constants.VALIDATOR_MIN_LENGTH,
                                errorMessage: "Debes ingresar al menos 5 caracteres.",
                                data: {
                                    minLength: 5
                                }
                            },
                            {
                                type: constants.VALIDATOR_NOT_EMPTY,
                                errorMessage: "Ingresa un correo electrónico válido."
                            }
                        ]
                    },
                    {
                        class: "",
                        containerClass: "",
                        default: "",
                        id: "confirmReturnWarehouseContactEmail",
                        label: "Confirma correo electrónico",
                        labelEn: "",
                        labelClass: "",
                        labelDescription: "",
                        name: "confirmReturnWarehouseContactEmail",
                        placeHolder: "Ej. contacto@info.com",
                        required:true,
                        type: constants.INPUT_TYPE_TEXT,
                        customHandler: constants.INPUT_HANDLER_ONLY_VALID_EMAILS_CHARS,
                        maxLength: "120",
                        validators: [
                            {
                                type: constants.VALIDATOR_EQUAL_FIELD_VALUE,
                                errorMessage: "Por favor revisa este dato, no es igual al correo ingresado en el campo anterior.",
                                data: {
                                    sourceFieldId: constants.INPUT_ID_RETURN_WAREHOUSE_CONTACT_EMAIL
                                }
                            },
                            {
                                type: constants.VALIDATOR_EMAIL,
                                errorMessage: "Debes ingresar un correo electrónico válido."
                            },
                            {
                                type: constants.VALIDATOR_MIN_LENGTH,
                                errorMessage: "Debes ingresar al menos 5 caracteres.",
                                data: {
                                    minLength: 5
                                }
                            },
                            {
                                type: constants.VALIDATOR_NOT_EMPTY,
                                errorMessage: "Ingresa un electrónico."
                            }
                        ]
                    }
                ]
            }
        ]
    },
    {
        section:{
            id: 2,
            title: "Información de tu empresa",
            isActive: true,
            stepperNumber: 2,
            stepperLabel: "Empresa",
        },
        fields: [
            {
                class: "",
                containerClass: "",
                dataSource: "",
                default: "",
                fakeValue: "",
                id: constants.INPUT_ID_BUSSINESS_REG_NUMBER,
                label: "Número de identificación tributaria (NIT)",
                labelEn: "Company document number",
                labelClass: "",
                labelDescription: "",
                name: constants.INPUT_ID_BUSSINESS_REG_NUMBER,
                order: 1,
                placeHolder: "Ej. 000.000.000-0",
                regex: "",
                required: true,
                type: constants.INPUT_TYPE_TEXT,
                maxLength: "11",
                customHandler: constants.INPUT_HANDLER_ONLY_NUMBERS,
                validators: [
                    {
                        type: constants.VALIDATOR_MIN_LENGTH,
                        errorMessage: "Debes ingresar un número de identificación tributaria válido.",
                        data: {
                            minLength: 5
                        }
                    },
                    {
                        type: constants.VALIDATOR_NOT_EMPTY,
                        errorMessage: "Debes ingresar un número de identificación tributaria válido."
                    }
                ]
            },
            {
                class: "",
                containerClass: "",
                dataSource: "",
                default: "",
                fakeValue: "",
                id: "fantasyName",
                label: "Nombre de fantasía",
                labelEn: "Fantasy name",
                labelClass: "",
                labelDescription: "",
                name: "fantasyName",
                order: 2,
                placeHolder: "Ej. Flores y chocolates florencia",
                regex: "",
                required: true,
                type: constants.INPUT_TYPE_TEXT,
                maxLength: "79",
                customHandler: constants.INPUT_HANDLER_ONLY_LETTERS_NUMERS_SPACES_AND_ESPECIAL,
                validators: [
                    {
                        type: constants.VALIDATOR_NOT_EMPTY,
                        errorMessage: "Debes ingresar el nombre de fantasía de la empresa."
                    },
                    {
                        type: constants.VALIDATOR_REGEX,
                        errorMessage: "No puedes colocar correos electrónicos en este campo.",
                        data: {
                            regex: /^((?![.]com|[.]cl|[.]co|[.]pe)[\s\S])*$/
                        }
                    }
                ]
            },
            {
                class: "search-icon",
                id: "economicActivity",
                label: "Código de actividad económica",
                labelEn: "Economic activity code",
                name: "economicActivity",
                order: 1,
                placeHolder: "Selecciona una opción",
                required: true,
                type: constants.INPUT_TYPE_SEARCH_TEXT_BOX,
                dataSource: {
                    type: constants.DATASOURCE_TYPE_API,
                    source: constants.SERVICE_BFF,
                    endpoint: "economicActivity",
                    params: [
                        {
                            name: "countryCode",
                            sourceType: "GlobalStateValue",
                            sourceId: "country"
                        }
                    ]
                },
                validators: [
                    {
                        type: constants.VALIDATOR_VALID_OPTION,
                        errorMessage: "Debes seleccionar una opción"
                    }
                ]
            },
            {
                class: "",
                containerClass: "",
                dataSource: "",
                id: "documentRUT",
                label: "Adjunta una imagen del documento de Registro Único Tributario",
                labelClass: "",
                labelDescription: "Debe tener una vigencia menor a 1 año e incluir todas las hojas del documento.",
                boxDescription: "1 Archivo permitido JPG, PNG o PDF.",
                name: "documentRUT",
                type: constants.INPUT_TYPE_FILE,
                required:true,
                attachTextButton: "Adjunta",
                attachTextLabel: "o arrastra el archivo",
                dropTextMessage: "Suelta el archivo en esta área",
                accept: ".jpg,.jpeg,.png,.pdf",
                validators: [
                    {
                        type: constants.VALIDATOR_FILE_SIZE,
                        errorMessage: "El archivo excede el límite permitido, no debe superar 3MB.",
                        data: {
                            maxAllowedSize: 3e6
                        }
                    },
                    {
                        type: constants.VALIDATOR_FILE_TYPE,
                        errorMessage: "Archivo inválido, adjunta solo JPG, PNG o PDF.",
                        data: {
                            allowedFileTypes: ["jpg", "jpeg", "png", "pdf"]
                        }
                    },
                    {
                        type: constants.VALIDATOR_HAS_CURRENT_VALUE,
                        errorMessage: "Solo puedes subir 1 archivo. Si quieres adjuntar un archivo diferente, tienes que eliminar el actual."
                    },
                    {
                        type: constants.VALIDATOR_NOT_EMPTY,
                        errorMessage: "Este campo es obligatorio, adjunta la imagen del documento de Registro Único Tributario."
                    }
                ]
            },
            ...getBankSectionPerCountry('CO')
        ]
    },
    {
        section: {
            id: 4,
            title: "Información de direcciones",
            isActive: true,
            stepperNumber: 3,
            stepperLabel: "Direcciones",
            type: "Accordion",
            activeSubSection: "4-1",
            itShouldHideAccordionNavigators: true
        },
        subSections: [
            {
                id: "4-1",
                title: "Dirección comercial",
                subTitle: "",
                enabled: true,
                showNavigation: true,
                navigationButtonId: AdobeAnalyticsButtonTags[3].businessAddress,
                onRenderActions: {
                    subTitle: {
                        handler: "RenderSubTitle",
                        params: {
                            renderSubtitleWith: [
                                "addressHeadOffice",
                                "optionLabel_municipalHeadOffice",
                                "optionLabel_cityHeadOffice"
                            ],
                            subtitleSeparator: ", "
                        }
                    }
                },
                onNavigateActions: {
                    next: {
                        handler: constants.ACTION_HANDLER_RENDER_SUBTITLE_AND_FOCUS_SUBSECTION,
                        params: {
                            nextSubSection: "4-2",
                            renderSubtitleWith: [
                                "addressHeadOffice",
                                "municipalHeadOffice",
                                "cityHeadOffice"
                            ],
                            subtitleSeparator: ", "
                        }
                    },
                    previous: ""
                },
                autofill: {
                    message: "Usar esta información para todas las direcciones.",
                    affectedSections: [ "4-2", "4-3" ],
                    behavior: {
                        'cityShipmentWarehouse' : "cityHeadOffice",
                        'municipalShipmentWarehouse' : "municipalHeadOffice",
                        'addressShipmentWarehouse' : "addressHeadOffice",
                        'cityReturnWarehouse': "cityHeadOffice",
                        'municipalReturnWarehouse': "municipalHeadOffice",
                        'addressReturnWarehouse' : "addressHeadOffice",
                        'optionLabel_municipalShipmentWarehouse' : "optionLabel_municipalHeadOffice",
                        'optionLabel_cityShipmentWarehouse' : "optionLabel_cityHeadOffice",
                        'optionLabel_municipalReturnWarehouse' : "optionLabel_municipalHeadOffice",
                        'optionLabel_cityReturnWarehouse' : "optionLabel_cityHeadOffice",
                    }
                },
                fields: [
                    {
                        class: "search-icon",
                        containerClass: "",
                        default: "",
                        id: "cityHeadOffice",
                        label: "Departamento",
                        labelEn: "",
                        labelClass: "",
                        labelDescription: "",
                        required:true,
                        name: "cityHeadOffice",
                        options: [],
                        placeHolder: "Ingresa un Departamento",
                        type: constants.INPUT_TYPE_DROPDOWN_SEARCH,
                        dataSource: {
                            type: constants.DATASOURCE_TYPE_API,
                            source: constants.SERVICE_BFF,
                            endpoint: "facoCountryCity",
                            params: [
                                {
                                    name: "countryCode",
                                    sourceType: "GlobalStateValue",
                                    sourceId: "country"
                                }
                            ]
                        },
                        validators: [
                            {
                                type: constants.VALIDATOR_NOT_EMPTY,
                                errorMessage: " "
                            }
                        ]
                    },
                    {
                        class: "search-icon",
                        containerClass: "",
                        default: "",
                        id: "municipalHeadOffice",
                        label: "Municipio",
                        labelEn: "",
                        labelClass: "",
                        labelDescription: "",
                        required:true,
                        name: "municipalHeadOffice",
                        options: [],
                        placeHolder: "Ingresa un Municipio",
                        type: constants.INPUT_TYPE_DROPDOWN_SEARCH,
                        dataSource: {
                            type: constants.DATASOURCE_TYPE_API,
                            source: constants.SERVICE_BFF,
                            endpoint: "municipal",
                            params: [
                                {
                                    name: "cityId",
                                    sourceType: "GlobalStateValue",
                                    sourceId: "cityHeadOffice"
                                }
                            ]
                        },
                        validators: [
                            {
                                type: constants.VALIDATOR_NOT_EMPTY,
                                errorMessage: " "
                            }
                        ]
                    },
                    {
                        class: "",
                        containerClass: "",
                        dataSource: "",
                        default: "",
                        fakeValue: "",
                        id: "addressHeadOffice",
                        label: "Calle y número",
                        labelEn: "Street and number",
                        labelClass: "",
                        labelDescription: "",
                        required:true,
                        name: "addressHeadOffice",
                        options: [],
                        placeHolder: "Ingresa el nombre de la calle y número",
                        type: constants.INPUT_TYPE_TEXT,
                        maxLength: "149",
                        customHandler: constants.INPUT_HANDLER_ONLY_LETTERS_NUMBERS_AND_SPACES,
                        validators: [
                            {
                                type: constants.VALIDATOR_NOT_EMPTY,
                                errorMessage: "Ingresa un nombre."
                            }
                        ]
                    }
                ]
            },
            {
                id: "4-2",
                title: "Dirección bodega de envío",
                subTitle: "",
                enabled: false,
                showNavigation: true,
                navigationButtonId: AdobeAnalyticsButtonTags[3].shipmentWH,
                onRenderActions: {
                    subTitle: {
                        handler: "RenderSubTitle",
                        params: {
                            renderSubtitleWith: [
                                "addressShipmentWarehouse",
                                "optionLabel_municipalShipmentWarehouse",
                                "optionLabel_cityShipmentWarehouse"
                            ],
                            subtitleSeparator: ", "
                        }
                    }
                },
                onNavigateActions: {
                    next: {
                        handler: constants.ACTION_HANDLER_RENDER_SUBTITLE_AND_FOCUS_SUBSECTION,
                        params: {
                            nextSubSection: "4-3",
                            renderSubtitleWith: [
                                "addressShipmentWarehouse",
                                "municipalShipmentWarehouse",
                                "cityShipmentWarehouse"
                            ],
                            subtitleSeparator: ", "
                        }
                    },
                    previous: ""
                },
                autofill: {
                    message: "Usar esta información para la dirección de devolución.",
                    affectedSections: [ "4-3" ],
                    behavior: {
                        'cityReturnWarehouse': "cityShipmentWarehouse",
                        'municipalReturnWarehouse': "municipalShipmentWarehouse",
                        'addressReturnWarehouse' : "addressShipmentWarehouse"
                    }
                },
                fields: [
                    {
                        class: "",
                        containerClass: "",
                        default: "",
                        id: "cityShipmentWarehouse",
                        label: "Departamento",
                        labelEn: "",
                        labelClass: "",
                        labelDescription: "",
                        required:true,
                        name: "cityShipmentWarehouse",
                        options: [],
                        placeHolder: "Ingresa un Departamento",
                        type: constants.INPUT_TYPE_DROPDOWN_SEARCH,
                        dataSource: {
                            type: constants.DATASOURCE_TYPE_API,
                            source: constants.SERVICE_BFF,
                            endpoint: "facoCountryCity",
                            params: [
                                {
                                    name: "countryCode",
                                    sourceType: "GlobalStateValue",
                                    sourceId: "country"
                                }
                            ]
                        },
                        validators: [
                            {
                                type: constants.VALIDATOR_NOT_EMPTY,
                                errorMessage: " "
                            }
                        ]
                    },
                    {
                        class: "",
                        containerClass: "",
                        default: "",
                        id: "municipalShipmentWarehouse",
                        label: "Municipio",
                        labelEn: "",
                        labelClass: "",
                        labelDescription: "",
                        required:true,
                        name: "municipalShipmentWarehouse",
                        options: [],
                        placeHolder: "Ingresa un Municipio",
                        type: constants.INPUT_TYPE_DROPDOWN_SEARCH,
                        dataSource: {
                            type: constants.DATASOURCE_TYPE_API,
                            source: constants.SERVICE_BFF,
                            endpoint: "municipal",
                            params: [
                                {
                                    name: "cityId",
                                    sourceType: "GlobalStateValue",
                                    sourceId: "cityShipmentWarehouse"
                                }
                            ]
                        },
                        validators: [
                            {
                                type: constants.VALIDATOR_NOT_EMPTY,
                                errorMessage: " "
                            }
                        ]
                    },
                    {
                        class: "",
                        containerClass: "",
                        dataSource: "",
                        default: "",
                        fakeValue: "",
                        id: "addressShipmentWarehouse",
                        label: "Calle y número",
                        labelEn: "Street and number",
                        labelClass: "",
                        labelDescription: "",
                        required:true,
                        name: "addressShipmentWarehouse",
                        options: [],
                        placeHolder: "Ingresa el nombre de la calle y número",
                        type: constants.INPUT_TYPE_TEXT,
                        maxLength: "149",
                        customHandler: constants.INPUT_HANDLER_ONLY_LETTERS_NUMBERS_AND_SPACES,
                        validators: [
                            {
                                type: constants.VALIDATOR_NOT_EMPTY,
                                errorMessage: "Ingresa un nombre."
                            }
                        ]
                    }
                ]
            },
            {
                id: "4-3",
                title: "Dirección bodega de devolución",
                subTitle: "",
                enabled: false,
                showNavigation: false,
                navigationButtonId: AdobeAnalyticsButtonTags[3].returnWH,
                onRenderActions: {
                    subTitle: {
                        handler: "RenderSubTitle",
                        params: {
                            renderSubtitleWith: [
                                "addressReturnWarehouse",
                                "optionLabel_municipalReturnWarehouse",
                                "optionLabel_cityReturnWarehouse"
                            ],
                            subtitleSeparator: ", "
                        }
                    }
                },
                onNavigateActions: {
                    next: {
                        handler: constants.ACTION_HANDLER_RENDER_SUBTITLE_AND_ACTIVATE_PAGE_NAVIGATION,
                        params: {
                            currentSubSection: "4-3",
                            renderSubtitleWith: [
                                "addressReturnWarehouse",
                                "municipalReturnWarehouse",
                                "cityReturnWarehouse"

                            ],
                            subtitleSeparator: ", "
                        }
                    },
                    previous: ""
                },
                fields: [
                    {
                        class: "",
                        containerClass: "",
                        default: "",
                        id: "cityReturnWarehouse",
                        label: "Departamento",
                        labelEn: "",
                        labelClass: "",
                        labelDescription: "",
                        required:true,
                        name: "cityReturnWarehouse",
                        options: [],
                        placeHolder: "Ingresa un Departamento",
                        type: constants.INPUT_TYPE_DROPDOWN_SEARCH,
                        dataSource: {
                            type: constants.DATASOURCE_TYPE_API,
                            source: constants.SERVICE_BFF,
                            endpoint: "facoCountryCity",
                            params: [
                                {
                                    name: "countryCode",
                                    sourceType: "GlobalStateValue",
                                    sourceId: "country"
                                }
                            ]
                        },
                        validators: [
                            {
                                type: constants.VALIDATOR_NOT_EMPTY,
                                errorMessage: " "
                            }
                        ]
                    },
                    {
                        class: "",
                        containerClass: "",
                        default: "",
                        id: "municipalReturnWarehouse",
                        label: "Municipio",
                        labelEn: "",
                        labelClass: "",
                        labelDescription: "",
                        required:true,
                        name: "municipalReturnWarehouse",
                        options: [],
                        placeHolder: "Ingresa un Municipio",
                        type: constants.INPUT_TYPE_DROPDOWN_SEARCH,
                        dataSource: {
                            type: constants.DATASOURCE_TYPE_API,
                            source: constants.SERVICE_BFF,
                            endpoint: "municipal",
                            params: [
                                {
                                    name: "cityId",
                                    sourceType: "GlobalStateValue",
                                    sourceId: "cityReturnWarehouse"
                                }
                            ]
                        },
                        validators: [
                            {
                                type: constants.VALIDATOR_NOT_EMPTY,
                                errorMessage: " "
                            }
                        ]
                    },
                    {
                        class: "",
                        containerClass: "",
                        dataSource: "",
                        default: "",
                        fakeValue: "",
                        id: "addressReturnWarehouse",
                        label: "Calle y número",
                        labelEn: "Street and number",
                        labelClass: "",
                        labelDescription: "",
                        required:true,
                        name: "addressReturnWarehouse",
                        options: [],
                        placeHolder: "Ingresa el nombre de la calle y número",
                        type: constants.INPUT_TYPE_TEXT,
                        maxLength: "149",
                        customHandler: constants.INPUT_HANDLER_ONLY_LETTERS_NUMBERS_AND_SPACES,
                        validators: [
                            {
                                type: constants.VALIDATOR_NOT_EMPTY,
                                errorMessage: "Ingresa un nombre."
                            }
                        ]
                    }
                ]
            }
        ]
    },
    {
        section: {
            id: 5,
            title: "Declaraciones y condiciones",
            isActive: true,
            stepperNumber: 4,
            stepperLabel: "Declaraciones",
            type: "Accordion",
            activeSubSection: "5-1"
        },
        subSections: [
            {
                id: "5-1",
                title: "Conflicto de interés",
                subTitle: "",
                enabled: true,
                showNavigation: true,
                navigationButtonId: AdobeAnalyticsButtonTags[4].conflictInterest,
                onRenderActions: {
                    subTitle: {
                        handler: "RenderSubTitle",
                        params: {
                            renderSubtitleWith: [
                                "optionLabel_conflictOfInterest"
                            ],
                            subtitleSeparator: ", "
                        }
                    },
                    toggleFieldVisibility: {
                        handler: constants.ACTION_HANDLER_TOGGLE_VISIBILITY,
                        params: {
                            fieldsToToggle: [
                                "personNameWithConflictOfInterest",
                                "typeOfConflict"
                            ],
                            onStateValue: {
                                stateName: "conflictOfInterest",
                                stateValue: constants.SI,
                                action: "show"
                            }
                        }
                    }
                },
                onNavigateActions: {
                    next: {
                        handler: constants.ACTION_HANDLER_FOCUS_SUBSECTION,
                        params: {
                            nextSubSection: "5-2"
                        }
                    },
                    previous: ""
                },
                fields: [
                    {
                        class: "rdb_orange ",
                        containerClass: "",
                        dataSource: "",
                        default: "",
                        id: "conflictOfInterest",
                        labelMandatory: "*",
                        label: "¿Alguna persona de la empresa tiene un conflicto de interés con alguien de Falabella, Linio, Sodimac o Tottus?",
                        labelClass: "",
                        labelDescription: "",
                        name: "conflictOfInterest",
                        options: [
                            {
                                id: "conflictOfInterest_1",
                                value: constants.SI,
                                label: "Sí",
                                labelClass: "rdb_label",
                                onClickActions: [
                                    {
                                        handler: constants.ACTION_HANDLER_TOGGLE_VISIBILITY,
                                        params: {
                                            fieldsToToggle: [
                                                "personNameWithConflictOfInterest",
                                                "typeOfConflict"
                                            ],
                                            action: "show"
                                        }
                                    }
                                ]
                            },
                            {
                                id: "conflictOfInterest_2",
                                value: constants.NO,
                                label: "No",
                                labelClass: "rdb_label",
                                onClickActions: [
                                    {
                                        handler: constants.ACTION_HANDLER_TOGGLE_VISIBILITY,
                                        params: {
                                            fieldsToToggle: [
                                                "personNameWithConflictOfInterest",
                                                "typeOfConflict"
                                            ],
                                            action: "hide"
                                        }
                                    }
                                ]
                            }
                        ],
                        placeHolder: "",
                        required: true,
                        type: constants.INPUT_TYPE_RADIO,
                        validators: [
                            {
                                type: constants.VALIDATOR_NOT_EMPTY,
                                errorMessage: "Por favor seleccione una opción."
                            }
                        ]
                    },
                    {
                        class: "",
                        containerClass: "hidden",
                        default: "",
                        id: "personNameWithConflictOfInterest",
                        label: "¿Cuál es el nombre completo de la persona en tu empresa?",
                        labelClass: "",
                        labelDescription: "",
                        name: "personNameWithConflictOfInterest",
                        placeHolder: "Ej. Juan",
                        isHidden: true,
                        required:false,
                        type: constants.INPUT_TYPE_TEXT,
                        customHandler: constants.INPUT_HANDLER_ONLY_LETTERS_AND_SPACES,
                        validators: [
                            {
                                type: constants.VALIDATOR_NOT_EMPTY,
                                errorMessage: "Debes ingresar el nombre completo.",
                                ifIsChecked: "conflictOfInterest_1"
                            }
                        ]
                    },
                    {
                        class: "",
                        containerClass: "hidden",
                        dataSource: "",
                        default: "",
                        fakeValue: "",
                        id: "typeOfConflict",
                        label: "¿Cuál es la relación que tiene esa persona con alguien del Grupo Falabella?",
                        labelClass: "",
                        labelDescription: "",
                        name: "typeOfConflict",
                        options: [
                            {
                                id: "typeOfConflict_1",
                                label: "De parentesco",
                                value: "De parentesco"
                            },
                            {
                                id: "typeOfConflict_1",
                                label: "De amistad",
                                value: "De amistad"
                            },
                            {
                                id: "typeOfConflict_1",
                                label: "De propiedad",
                                value: "De propiedad"
                            },
                            {
                                id: "typeOfConflict_1",
                                label: "De relaciones profesionales previas",
                                value: "De relaciones profesionales previas"
                            },
                            {
                                id: "typeOfConflict_1",
                                label: "Otros",
                                value: "Otros"
                            }
                        ],
                        placeHolder: "",
                        required: false,
                        type: constants.INPUT_TYPE_DROPDOWN,
                        validators: [
                            {
                                type: constants.VALIDATOR_NOT_EMPTY,
                                errorMessage: "Este campo es obligatorio, selecciona una opción.",
                                ifIsChecked: "conflictOfInterest_1"
                            }
                        ]
                    }
                ]
            },
            {
                id: "5-2",
                title: "Persona Expuesta Políticamente",
                subTitle: "",
                enabled: false,
                showNavigation: true,
                navigationButtonId: AdobeAnalyticsButtonTags[4].personExpPol,
                onRenderActions: {
                    subTitle: {
                        handler: "RenderSubTitle",
                        params: {
                            renderSubtitleWith: [
                                "optionLabel_hasPepRelationship"
                            ],
                            subtitleSeparator: ", "
                        }
                    },
                    toggleFieldVisibility: {
                        handler: constants.ACTION_HANDLER_TOGGLE_VISIBILITY,
                        params: {
                            fieldsToToggle: [
                                "pepName",
                                "relationshipWithPep"
                            ],
                            onStateValue: {
                                stateName: "hasPepRelationship",
                                stateValue: constants.SI,
                                action: "show"
                            }
                        }
                    }
                },
                onNavigateActions: {
                    next: {
                        handler: constants.ACTION_HANDLER_FOCUS_SUBSECTION,
                        params: {
                            nextSubSection: "5-3"
                        }
                    },
                    previous: ""
                },
                fields: [
                    {
                        class: "rdb_orange ",
                        containerClass: "",
                        dataSource: "",
                        default: constants.NO,
                        id: "hasPepRelationship",
                        labelMandatory: "*",
                        label: "¿Alguna persona ejecutiva relevante de la empresa tiene relación con una Persona Políticamente Expuesta (PEP)?",
                        labelClass: "",
                        labelDescription: "",
                        name: "hasPepRelationship",
                        options: [
                            {
                                id: "hasPepRelationship_1",
                                value: constants.SI,
                                label: "Sí",
                                labelClass: "rdb_label",
                                onClickActions: [
                                    {
                                        handler: constants.ACTION_HANDLER_TOGGLE_VISIBILITY,
                                        params: {
                                            fieldsToToggle: [
                                                "pepName",
                                                "relationshipWithPep"
                                            ],
                                            action: "show"
                                        }
                                    }
                                ]
                            },
                            {
                                id: "hasPepRelationship_2",
                                value: constants.NO,
                                label: "No",
                                labelClass: "rdb_label",
                                onClickActions: [
                                    {
                                        handler: constants.ACTION_HANDLER_TOGGLE_VISIBILITY,
                                        params: {
                                            fieldsToToggle: [
                                                "pepName",
                                                "relationshipWithPep"
                                            ],
                                            action: "hide"
                                        }
                                    }
                                ]
                            }
                        ],
                        placeHolder: "",
                        required: true,
                        type: constants.INPUT_TYPE_RADIO,
                        validators: [
                            {
                                type: constants.VALIDATOR_NOT_EMPTY,
                                errorMessage: "Por favor seleccione una opción."
                            }
                        ]
                    },
                    {
                        class: "",
                        containerClass: "hidden",
                        dataSource: "",
                        default: "",
                        id: "pepName",
                        label: "¿Cuál es el nombre completo de la persona en tu empresa?",
                        labelClass: "",
                        labelDescription: "",
                        name: "pepName",
                        placeHolder: "Ej. Juan",
                        required: false,
                        type: constants.INPUT_TYPE_TEXT,
                        customHandler: constants.INPUT_HANDLER_ONLY_LETTERS_AND_SPACES,
                        validators: [
                            {
                                type: constants.VALIDATOR_NOT_EMPTY,
                                errorMessage: "Debes ingresar el nombre completo.",
                                ifIsChecked: "hasPepRelationship_1"
                            }
                        ]
                    },
                    {
                        class: "",
                        containerClass: "hidden",
                        dataSource: "",
                        default: "",
                        id: "relationshipWithPep",
                        label: "¿Cuál es la relación que tiene con la Persona Políticamente Expuesta (PEP)?",
                        labelClass: "",
                        labelDescription: "",
                        name: "relationshipWithPep",
                        options: [
                            {
                                id: "relationshipWithPep_1",
                                label: "Abuelo",
                                value: "Abuelo"
                            },
                            {
                                id: "relationshipWithPep_1",
                                label: "Padre",
                                value: "Padre"
                            },
                            {
                                id: "relationshipWithPep_1",
                                label: "Madre",
                                value: "Madre"
                            },
                            {
                                id: "relationshipWithPep_1",
                                label: "Yo mismo",
                                value: "Yo mismo"
                            },
                            {
                                id: "relationshipWithPep_1",
                                label: "Hermano(a)",
                                value: "Hermano(a)"
                            },
                            {
                                id: "relationshipWithPep_1",
                                label: "Hijo(a)",
                                value: "Hijo(a)"
                            },
                            {
                                id: "relationshipWithPep_1",
                                label: "Nieto",
                                value: "Nieto"
                            }
                        ],
                        placeHolder: "",
                        required: false,
                        type: constants.INPUT_TYPE_DROPDOWN,
                        validators: [
                            {
                                type: constants.VALIDATOR_NOT_EMPTY,
                                errorMessage: "Este campo es obligatorio, selecciona una opción.",
                                ifIsChecked: "hasPepRelationship_1"
                            }
                        ]
                    }
                ]
            },
            {
                id: "5-3",
                title: "Prevención de delitos y libre competencia",
                subTitle: "",
                enabled: false,
                showNavigation: true,
                navigationButtonId: AdobeAnalyticsButtonTags[4].crimePreventionFreeCompetition,
                onRenderActions: {
                    toggleFieldVisibility: {
                        handler: constants.ACTION_HANDLER_TOGGLE_VISIBILITY,
                        params: {
                            fieldsToToggle: [
                                "crimePreventionObservation"
                            ],
                            onStateValue: {
                                stateName: "crimePrevention",
                                stateValue: constants.YES,
                                action: "show"
                            }
                        }
                    }
                },
                onNavigateActions: {
                    next: {
                        handler: constants.ACTION_HANDLER_FOCUS_SUBSECTION,
                        params: {
                            nextSubSection: "5-4"
                        }
                    },
                    previous: ""
                },
                fields: [
                    {
                        class: "rdb_orange ",
                        containerClass: "",
                        default: constants.NO,
                        id: "crimePrevention",
                        label: "",
                        labelClass: "",
                        labelDescription: "",
                        required:true,
                        name: "crimePrevention",
                        options: [
                            {
                                id: "crimePrevention_1",
                                value: constants.NO,
                                labelMandatory: "*",
                                label: "Declaro que ni yo, ni ninguna persona ejecutiva relevante de la empresa, ha tenido jamás la calidad de imputado y/o ha sido investigado, formalizado, acusado o condenado por ninguno de los delitos o acciones descritos en la ",
                                labelLink: {
                                    url: "https://sellercenter.falabella.com/templates/default/files/declaracion_prevencion_de_delitos_ley.pdf",
                                    class: "rdb_label_link",
                                    text: "Declaración de Cumplimiento.",
                                    target: "_blank"
                                },
                                labelClass: "rdb_label",
                                onClickActions: [
                                    {
                                        handler: constants.ACTION_HANDLER_TOGGLE_VISIBILITY,
                                        params: {
                                            fieldsToToggle: [
                                                "crimePreventionObservation"
                                            ],
                                            action: "hide"
                                        }
                                    }
                                ]
                            },
                            {
                                id: "crimePrevention_2",
                                value: constants.YES,
                                labelMandatory: "*",
                                label: "Tengo dudas con este campo, prefiero dejar una observación.",
                                labelClass: "rdb_label",
                                onClickActions: [
                                    {
                                        handler: constants.ACTION_HANDLER_TOGGLE_VISIBILITY,
                                        params: {
                                            fieldsToToggle: [
                                                "crimePreventionObservation"
                                            ],
                                            action: "show"
                                        }
                                    }
                                ]
                            }
                        ],
                        placeHolder: "",
                        type: constants.INPUT_TYPE_RADIO,
                        validators: [
                            {
                                type: constants.VALIDATOR_NOT_EMPTY,
                                errorMessage: "Por favor seleccione una opción."
                            }
                        ]
                    },
                    {
                        class: "",
                        containerClass: "hidden",
                        dataSource: "",
                        default: "",
                        id: "crimePreventionObservation",
                        label: "Observación",
                        labelClass: "",
                        labelDescription: "",
                        name: "crimePreventionObservation",
                        placeHolder: "Ingresa tu observación para que podamos analizar el caso.",
                        required: false,
                        type: constants.INPUT_TYPE_TEXT,
                        validators: [
                            {
                                type: constants.VALIDATOR_NOT_EMPTY,
                                errorMessage: "Para continuar debes ingresar una observación.",
                                ifIsChecked: "crimePrevention_2"
                            }
                        ]
                    },
                ]
            },
            {
                id: "5-4",
                title: "Anexos",
                subTitle: "",
                enabled: false,
                showNavigation: true,
                navigationButtonId: AdobeAnalyticsButtonTags[4].attached,
                onRenderActions: {},
                onNavigateActions: {
                    next: {
                        handler: constants.ACTION_HANDLER_FOCUS_SUBSECTION,
                        params: {
                            nextSubSection: "5-5"
                        }
                    },
                    previous: ""
                },
                fields: [
                    {
                        class: "cb_checkbox",
                        containerClass: "",
                        id: "personalInfoAuthorization",
                        labelClass: "",
                        labelMandatory: "",
                        labelDescription: "",
                        name: "personalInfoAuthorization",
                        value: constants.YES,
                        required: true,
                        placeHolder: "Declaro que he leído y autorizo el uso de mis datos personales de acuerdo a la ",
                        labelLink: {
                            "url": "https://comunicacion.falabella.com/CO/TyC/2023/2023-Autorizacion-Tratamiento-de-Datos-Personales-Vendedores-Colombia.pdf",
                            "text": "Autorización.",
                            "target": "_blank",
                            "class": "rdb_label_link"
                        },
                        type: constants.INPUT_TYPE_CHECKBOX,
                        validators: [{
                            type: constants.VALIDATOR_NOT_EMPTY,
                            errorMessage: "Por favor seleccione una opción."
                        }]
                    },
                    {
                        class: "cb_checkbox",
                        containerClass: "",
                        id: "acceptSearchingCentralRisk",
                        labelClass: "",
                        labelMandatory: "",
                        labelDescription: "",
                        name: "acceptSearchingCentralRisk",
                        value: constants.YES,
                        required: false,
                        placeHolder: "Autorizo la consulta y reporte en ",
                        labelLink: {
                            "url": "https://comunicacion.falabella.com/CO/TyC/2022/2022-AUTORIZACION-CONSULTA-CENTRALES-DE-RIESGO-FACO.pdf",
                            "text": "centrales de riesgo.",
                            "target": "_blank",
                            "class": "rdb_label_link"
                        },
                        type: constants.INPUT_TYPE_CHECKBOX,
                        validators: []
                    }
                ]
            },
            {
                id: "5-5",
                title: "Términos y condiciones",
                subTitle: "",
                enabled: false,
                showNavigation: false,
                navigationButtonId: AdobeAnalyticsButtonTags[4].termsConditions,
                onRenderActions: {},
                onNavigateActions: {
                    next: {
                        handler: constants.ACTION_HANDLER_ACTIVATE_PAGE_NAVIGATION,
                        params: {
                            currentSubSection: "5-5"
                        }
                    },
                    previous: ""
                },
                fields: [
                    {
                        class: "cb_checkbox",
                        containerClass: "",
                        id: "termsAndConditions",
                        labelClass: "",
                        labelMandatory: "*",
                        labelDescription: "",
                        name: "termsAndConditions",
                        value: constants.YES,
                        required: true,
                        placeHolder: "Acepto ",
                        labelLink: {
                            "url": "https://sellercenter.falabella.com/terms_and_conditions_faco_faco",
                            "text": "los términos y condiciones",
                            "target": "_blank",
                            "class": "rdb_label_link"
                        },
                        type: constants.INPUT_TYPE_CHECKBOX,
                        validators: [
                            {
                                type: constants.VALIDATOR_IS_CHECKED,
                                errorMessage: "Por favor acepte términos y condiciones."
                            }
                        ]
                    }
                ]
            }
        ]
    }
];

export default naturalPerson;
