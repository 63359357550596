import React, { useEffect, useState } from "react";
import Alert from "../UI/Elements/Alert";
import { useStateValue } from "../../store/StateProvider";
import { actionTypes } from "../../utils/constants";
import { prepareEmailFpayDataSource } from "../Integrations/serviceDataSource";
import dataSourceAggregator from "../Integrations/dataSourceAggregator";
import constants from "../../config/constants";

const AsyncErrorValidation = ({ trigger, triggererHandler, error, errorHandler, fieldConfig, showOverlay }) => {
  const FPAY_EMAIL_VALIDATION_COUNTRY_LIST = JSON.parse(process.env.REACT_APP_FPAY_VALIDATION);
  const { asyncValidators, id } = fieldConfig;
  const [errorMessage, setErrorMessage] = useState(false);
  const [alertColor, setAlertColor] = useState();
  const [alertBackgroundColor, setAlertBackgroundColor] = useState();
  const [{ formValues, country, currentStep }, dispatch] = useStateValue();

  const setAlertSyle = validator => {
    if (validator.color) {
      setAlertColor(validator.color);
    }

    if (validator.backgroundColor) {
      setAlertBackgroundColor(validator.backgroundColor);
    }
  };

  const toggleOverlay = showOverlay => {
    dispatch({
      type: actionTypes.TOGGLE_OVERLAY,
      payload: showOverlay
    });
  };

  const toggleFollowingButton = resultOfValidations => {
    dispatch({
      type: actionTypes.SET_ASYNC_VALIDATIONS,
      payload: {[currentStep]: !resultOfValidations}
    });
  };

  const handleResponse = response => {
    toggleFollowingButton(!!response);
    toggleOverlay(false);
    setErrorMessage(response);
    errorHandler(!!response);
    triggererHandler(false);
  };

  const getAdditionalValidationErrors = validators => {
    Promise.all(
      validators.map(async validator => {
        switch (validator.endpoint) {
          case constants.END_POINT_FPAY_EMAIL_VALIDATION:
            let response;
            if (
              country in FPAY_EMAIL_VALIDATION_COUNTRY_LIST &&
              FPAY_EMAIL_VALIDATION_COUNTRY_LIST[country] === true
            ) {
              const dataSource = prepareEmailFpayDataSource(
                validator,
                country,
                id,
                formValues
              );
              response = await dataSourceAggregator(dataSource);
            }
            if (response) {
              setAlertSyle(validator);
              return handleResponse(validator.errorMessage);
            }
            break;
          default:
            return handleResponse(null);
        }
        return handleResponse(null);
      })
    );
  };

  useEffect(() => {
    if (trigger) {
      toggleOverlay(showOverlay);

      const getValidations = async () => {
        getAdditionalValidationErrors(asyncValidators);
      };
      getValidations();
    }
  }, [trigger]); // eslint-disable-line

  return (
    <>
      {
        error && 
        errorMessage && 
        <Alert 
          text={errorMessage} 
          color={alertColor} 
          backgroundColor={alertBackgroundColor} 
        />
      }
    </>
  );
};

export default AsyncErrorValidation;
