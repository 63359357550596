import React from "react";
import { Form } from "react-bootstrap";
import DropFileControl from "../InputControls/DropFileControl"
import { useStateValue } from "../../../store/StateProvider";

const InputDropFile = ({ fieldConfig, fieldValidator }) => {
    const [{ hiddenFields }] = useStateValue();

    let labelClass = "input_label_plain ";
    labelClass += fieldConfig.labelClass;

    let labelText = [];
    if (fieldConfig.labelText) {
        labelText = [...fieldConfig.labelText];
    }

    const idField = fieldConfig.id;
    const executeFieldValidation = (validators, fieldValue, fieldId) => {
        return fieldValidator(validators, fieldValue, fieldId);
    }

    const removeErrorMessage = () => {
        fieldValidator([
            {
                type: "RemoveErrorMessage"
            }
        ],
            null,
            idField
        );
    }

    return (
        <Form.Group
            className={`mb3 ${fieldConfig.containerClass} ${hiddenFields.includes(fieldConfig.id) ? 'hidden' : ''}`}
            id={`container_${fieldConfig.id}`}
            controlId={fieldConfig.name}
        >
            <Form.Label className={labelClass}>{fieldConfig.label}</Form.Label>
            <Form.Label className="label_description">{fieldConfig.labelDescription}</Form.Label>
            {labelText.map(text => {
                return (
                    <Form.Label key={Math.random()} className="label_description">{text}</Form.Label>
                )
            })}
            <DropFileControl id={`${fieldConfig.id}`} fieldConfig={fieldConfig} fieldValidator={executeFieldValidation} removeErrorMessage={removeErrorMessage} />
            <Form.Label className="label_error label_error_dropfile hidden" id={`label_error_${fieldConfig.id}`}>
                <span id={`label_error_message_${fieldConfig.id}`}></span>
                <button type="button" onClick={removeErrorMessage} className="error_close_button">X</button>
            </Form.Label>
        </Form.Group>
    )
}

export default InputDropFile;
