import React, { useEffect, useRef, useState } from "react";
import { Accordion, Row, Form } from "react-bootstrap";
import { useStateValue } from "../../../store/StateProvider";
import { actionTypes } from "../../../utils/constants";
import "./AccordionElement.scss";
import FormElement from "../../FormElement/FormElement";
import AccordionNavigation from "./AccordionNavigation";
import AutoFillCheckbox from "../InputControls/AutoFillCheckbox";
import {
  defaultAccordionNavigators,
  defaultAutoFillSections,
  defaultAccordionItemClass,
  getAutoFillBehavior,
  enableAccordionItem,
  activateAllAccordionItems,
  getNextSubSectionFromSectionId,
  getAffectededSectionsByAutofill
} from "../../../utils/formData";

const AccordionElement = ({ section }) => {
  const [
    { formValues, accordionsEnabled, accordionsCompleted, accordionsActivate, currentStep, sellerCreationErrors },
    dispatch
  ] = useStateValue();

  const accordionTitleRef = useRef(null);
  const [activeKey, setActiveKey] = useState(section.section.activeSubSection);
  const [accordionNavigations, setAccordionNavigations] = useState(defaultAccordionNavigators(section.subSections));
  const [autoFillsections, setAutoFillsections] = useState(defaultAutoFillSections(section.subSections));
  const [accordionItemClass, setAccordionItemClass] = useState(
    defaultAccordionItemClass(section.subSections, accordionsEnabled)
  );

  const handleSimulateClickInAccordionTitle = () => {
    if (accordionTitleRef) {
      accordionTitleRef.current.click();
    }
  }

  const dispatchAccordionComplete = sectionId => {
    if (autoFillsections[sectionId]) {
      dispatch({
        type: actionTypes.AUTOFILL_ACTION,
        current: sectionId,
        affected: getAffectededSectionsByAutofill(sectionId, section.subSections)
      });
    } else {
      dispatch({
        type: actionTypes.ACCORDIONS_ACTIVE_COMPLETE,
        current: sectionId,
        affected: getNextSubSectionFromSectionId(sectionId, section.subSections)
      });
    }

    dispatch({
      type: actionTypes.SET_CONFIRMED_VALUES
    });
  };

  const autoFillAction = sectionId => {
    const behavior = getAutoFillBehavior(sectionId, section.subSections);
    const values = Object.fromEntries(
      Object.entries(behavior).map(([sourceField, targetFields]) => {
        if (Array.isArray(targetFields)) {
          let inputValues = "";
          targetFields.forEach(value => {
            inputValues += `${formValues[value]} `;
          });
          return [sourceField, inputValues];
        }
        return [sourceField, formValues[targetFields]];
      })
    );

    dispatch({
      type: actionTypes.PRESERVE_GLOBAL_STATE,
      payload: values
    });
  };

  const handleClickAccordionNavigator = (sectionId, nextSectionId) => {
    dispatchAccordionComplete(sectionId);

    if (!section.section.itShouldHideAccordionNavigators) {
      return setAccordionItemClass(enableAccordionItem(accordionItemClass, nextSectionId));
    }

    if (autoFillsections[sectionId]) {
      setAutoFillsections({});
      setAccordionNavigations([]);
      setAccordionItemClass(activateAllAccordionItems(section.subSections));
      autoFillAction(sectionId);
    } else {
      setAccordionNavigations(accordionNavigations.map(item => (item !== sectionId ? item : null)));
      const updateAutoFillsections = { ...autoFillsections };
      delete updateAutoFillsections[sectionId];
      setAutoFillsections(updateAutoFillsections);
      setAccordionItemClass(enableAccordionItem(accordionItemClass, nextSectionId));
    }
  };

  const handleAutofillAction = (sectionId, checked) => {
    setAutoFillsections({
      ...autoFillsections,
      [sectionId]: checked
    });
  };

  useEffect(() => {
    setAccordionItemClass(defaultAccordionItemClass(section.subSections, accordionsEnabled));
    setAccordionNavigations(defaultAccordionNavigators(section.subSections));
    setAutoFillsections(defaultAutoFillSections(section.subSections));
  }, [section.subSections]); // eslint-disable-line

  useEffect(() => {
    setActiveKey(accordionsActivate[currentStep] ?? section.section.activeSubSection);
  }, [section.section.activeSubSection, accordionsActivate, currentStep]);

  const handleHeaderClick = eventKey => {
    setActiveKey(prevActiveKey => (prevActiveKey === eventKey ? null : eventKey));
  };

  const ToggleButton = ({ children, eventKey }) => {
    return (
      <div id={eventKey} className="accordion_title">
        {children}
      </div>
    );
  };

  const getSubtitle = params => {
    let subTitle = [];
    const subtitleSeparator = params.subtitleSeparator ?? " ";
    subTitle = params.renderSubtitleWith.map(item => {
      return formValues[item] ?? "";
    });

    return subTitle
      .filter(item => item)
      .join(subtitleSeparator)
      .trim();
  };

  const toggleFieldVisibility = (params, subSection) => {
    if (params.onStateValue !== undefined) {
      if (formValues[params.onStateValue.stateName] === params.onStateValue.stateValue) {
        const containerClassName = params.onStateValue.action === "show" ? "visible" : "hidden";
        subSection.fields.map(field => {
          if (params.fieldsToToggle.includes(field.id)) {
            field.containerClass = containerClassName;
          }

          return field;
        });
      }
    }

    return subSection;
  };

  const hasSellerCreationErrorOnSubsection = subSectionId => {
    if (sellerCreationErrors.length) {
      const sectionError = sellerCreationErrors.find(error => {
        return error.section.subSection === subSectionId;
      });

      return !!sectionError;
    }

    return false;
  };

  const renderSubtitle = item => {
    if (item.onRenderActions?.subTitle) {
      const stateSubtitle = getSubtitle(item.onRenderActions.subTitle.params);
      return stateSubtitle !== "" ? stateSubtitle : item.subTitle;
    }

    return item.subTitle;
  }

  return (
    <>
      {section.section.subTitle && (
        <Row className="text-center">
          <h1 className="form_subtitle">{section.section.subTitle}</h1>
        </Row>
      )}
      <Accordion activeKey={activeKey}>
        {section.subSections.map(item => {
          let accordionItemId = `accordionItem-${item.id}`;
          let toggleButtonId = `toggleButton-${item.id}`;
          let idTitle = `accordionTitle_${item.id}`;
          let idSubtitle = `accordionSubtitle_${item.id}`;
          let subTitle = renderSubtitle(item);

          if (item.onRenderActions.toggleFieldVisibility) {
            item = toggleFieldVisibility(item.onRenderActions.toggleFieldVisibility.params, item);
          }

          return (
            <Accordion.Item
              key={item.id}
              eventKey={item.id}
              id={accordionItemId}
              className={accordionItemClass[item.id]}>
              <Accordion.Header onClick={() => handleHeaderClick(item.id)}>
                <div className="accordion-header-left">
                  <ToggleButton eventKey={toggleButtonId}>
                    <h1 id={idTitle}>{item.title}</h1>
                    <h2 id={idSubtitle}>{subTitle}</h2>
                  </ToggleButton>
                </div>

                {hasSellerCreationErrorOnSubsection(item.id) && (
                  <div className="accordion-header-right">
                    <p className="accordion-header-notification">Hay campos que necesitan tu atención</p>
                  </div>
                )}
              </Accordion.Header>
              <Accordion.Body>
                <Form className="form_wrapper">
                  <FormElement
                    section={item}
                  />
                  {item.id in autoFillsections && !accordionsCompleted.includes(item.id) && (
                    <AutoFillCheckbox
                      id={item.id}
                      message={item.autofill.message}
                      handleAutofillAction={handleAutofillAction}
                    />
                  )}
                  {accordionNavigations.includes(item.id) && !accordionsCompleted.includes(item.id) && (
                    <AccordionNavigation
                      section={item}
                      formValues={formValues}
                      activeKeyChanger={setActiveKey}
                      onSimulateClick={handleSimulateClickInAccordionTitle}
                      handleClickAccordionNavigator={handleClickAccordionNavigator}
                      handleSubtitle={getSubtitle}
                    />
                  )}
                </Form>
              </Accordion.Body>
            </Accordion.Item>
          );
        })}
      </Accordion>
    </>
  );
};

export default AccordionElement;
