import constants from "../config/constants";

export const isNullOrEmpty = value => value == null || value === "" || value === undefined;

export const URLWithSearchParams = (inputUrl, searchParams) => {
  const url = new URL(inputUrl);
  url.search = new URLSearchParams(searchParams).toString();
  return url;
};

export const isLegalRut = value => {
  const number = parseInt(value.toString().substring(0, value.length - 1));
  return constants.MIN_NUMBER_RUT_LEGAL_PERSON <= number;
};
