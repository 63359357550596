import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import * as esConstants from "./translations/es/common.json";
import * as enConstants from "./translations/en/common.json";

i18n.use(initReactI18next).init({
  resources: {
    en: {
      translation: enConstants.default
    },
    es: {
      translation: esConstants.default
    }
  },
  lng: process.env.REACT_APP_LANGUAGE,
  fallbackLng: "es",
  interpolation: {
    escapeValue: false
  }
});

export default i18n;
