import React from "react";
import { Form } from "react-bootstrap";

const TextareaControl = props => {
    let  fieldClass = "input_plain ";
    fieldClass += props.fieldConfig.class; 
    
    return (
        <Form.Control 
            className={fieldClass}
            name={props.fieldConfig.name}
            as="textarea" 
            placeholder={props.fieldConfig.placeHolder} 
            defaultValue={props.fieldConfig.default}
        />
    )
}

export default TextareaControl;
