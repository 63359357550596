import { React, useState } from "react";
import { useStateValue } from "../../../store/StateProvider";
import { actionTypes } from "../../../utils/constants";
import { useTranslation } from "react-i18next";
import constants from "../../../config/constants";

const RadioControl = ({ fieldConfig, changeLabelMandatory, fieldValidator, onChange }) => {
    const { t } = useTranslation();
    const [{ formValues }, dispatch] = useStateValue();
    const optionLabelKey = `optionLabel_${[fieldConfig.id]}`;

    let fieldClass = "form-check-input ";
    fieldClass += fieldConfig.class;
    let rdbOptions = fieldConfig.options;

    const labelMandatoryOptionsExists = rdbOptions.filter(rdbOption => rdbOption.labelMandatory ? true : false)
    const [labelMandatoryOptions, setLabelMandatoryOptions] = useState(labelMandatoryOptionsExists.length === 0 ? false : true)
    const [fieldValue, setFieldValue] = useState(formValues[fieldConfig.id] || undefined);
    const fieldValueChangeHandler = event => {
        setFieldValue(event.target.value);
        changeLabelMandatory(event.target.value ? false : true);
        setLabelMandatoryOptions(event.target.value ? false : true);
        dispatch({
            type: actionTypes.PRESERVE_GLOBAL_STATE,
            payload: {
                [fieldConfig.id]: event.target.value,
                [optionLabelKey]: t(event.target.dataset.label)
            }
        });
        fieldValidator(event.target.value);
    }

    const toggleFieldVisibility = params => {
        params.fieldsToToggle.map(fieldId => {
            dispatch({
                type: actionTypes.SET_HIDDEN_FIELDS,
                payload: {
                    id: fieldId,
                    value: params.action === 'hide' ? false : true
                }
            });

            return true;
        });
    }

    return (
        <>
            {rdbOptions.map(rdbOption => {
                const handleOnClickActions = (e) => {
                    onChange(e);

                    fieldValueChangeHandler(e);

                    if (rdbOption.onClickActions === undefined) {
                        return;
                    }

                    rdbOption.onClickActions.map(action => {
                        switch (action.handler) {
                            case constants.ACTION_HANDLER_TOGGLE_VISIBILITY:
                                return toggleFieldVisibility(action.params);
                            default:
                                return true;
                        }
                    });
                }

                return (
                    <label key={rdbOption.id} htmlFor={rdbOption.id} className={rdbOption.labelClass}>
                        <input
                            key={rdbOption.id}
                            className={fieldClass}
                            type="radio"
                            value={rdbOption.value}
                            name={fieldConfig.name}
                            id={rdbOption.id}
                            data-cy={rdbOption.id}
                            data-label={rdbOption.label}
                            onChange={handleOnClickActions}
                            checked={fieldValue === rdbOption.value}
                        />
                        <p className="rdb_label_text">
                            {(labelMandatoryOptions) &&
                                !formValues[rdbOption.id] &&
                                rdbOption.labelMandatory &&
                                <span className="span_mandatory">{rdbOption.labelMandatory}</span>
                            }
                            {t(rdbOption.label)}
                            {(rdbOption.labelLink !== undefined)
                                ? <a href={rdbOption.labelLink.url} target={rdbOption.labelLink.target} className={rdbOption.labelLink.class}>{rdbOption.labelLink.text}</a>
                                : ""
                            }
                        </p>
                        <span className="option_label_description">{rdbOption.labelDescription}</span>
                    </label>
                )
            })}
        </>
    )
}

export default RadioControl;
