import React from "react";
import { Form } from "react-bootstrap";
import { useStateValue } from "../../../store/StateProvider";
import ButtonControl from "../InputControls/ButtonControl"

const InputButton = ({ fieldConfig }) => {
    const [{ hiddenFields }] = useStateValue();

    let labelClass = "input_label_plain ";
    labelClass += fieldConfig.labelClass;

    return (
        <Form.Group
            className={`mb3 ${fieldConfig.containerClass} ${hiddenFields.includes(fieldConfig.id) ? 'hidden' : ''}`}
            id={`container_${fieldConfig.id}`}
            controlId={fieldConfig.name}
        >
            <Form.Label className={labelClass}>{fieldConfig.label}</Form.Label>
            <Form.Label className="label_description">{fieldConfig.labelDescription}</Form.Label>
            <ButtonControl fieldConfig={fieldConfig} />
        </Form.Group>
    )
}

export default InputButton;
