import React, { useState } from "react";
import { Form } from "react-bootstrap";
import FileControl from "../InputControls/FileControl";
import { useStateValue } from "../../../store/StateProvider";
import { getSellerCreationErrors, getFieldValidationErrors } from "../../../utils/validator";

const InputFile = ({ fieldConfig }) => {
  const [{ hiddenFields, sellerCreationErrors }] = useStateValue();
  const [errorMessage, setErrorMessage] = useState(getSellerCreationErrors(fieldConfig.id, sellerCreationErrors));

  const labelClass = `input_label_plain ${fieldConfig.labelClass}`;

  const setError = error => {
    setErrorMessage(error);
  };

  const executeFieldValidation = (validators, fieldValue, fieldId, prevFieldValue) => {
    const error = getFieldValidationErrors(validators, { [fieldConfig.id]: fieldValue }, fieldId, prevFieldValue);
    setError(error);
    return !error;
  };

  return (
    <Form.Group
      className={`mb3 ${fieldConfig.containerClass} ${hiddenFields.includes(fieldConfig.id) ? 'hidden' : ''}`}
      id={`container_${fieldConfig.id}`}
      controlId={fieldConfig.name}
    >
      <Form.Label className={labelClass}>{fieldConfig.label}</Form.Label>
      <Form.Label className="label_description">{fieldConfig.labelDescription}</Form.Label>
      <FileControl
        fieldConfig={fieldConfig}
        fieldValidator={executeFieldValidation}
        removeErrorMessage={setError.bind(this, null)}
        errorMessage={errorMessage}
      />
    </Form.Group>
  );
};

export default InputFile;
