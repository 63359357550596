import React, { useCallback } from 'react';
import { Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import BulletCard from '../Elements/BulletCard';
import CountrySelector from '../Elements/CountrySelector.js';
import iconOk from "../../../static/svgs/icon-ok.svg";
import constants from '../../../config/constants';
import useGeoLocation from "react-ipgeolocation";
import { useEffect, useState } from "react";
import { useStateValue } from '../../../store/StateProvider.js';

const FirstPage = () => {
    const [{ countryPersistent }] = useStateValue();
    const { t } = useTranslation();
    const geoLocation = useGeoLocation();
    const [countryUser, setCountryUser] = useState(countryPersistent ?? null);

    const GetBulletTitleForCountrySelection = () => {
        const { t } = useTranslation();
    
        return t("content.prepareInformation");
    
    }

    const GetBulletsForCountrySelection = () => {
        const { t } = useTranslation();
    
        return [
            {
                icon: iconOk,
                iconAlt: 'ok',
                id: "info_1",
                title: t("content.business"),
                text: t("content.businessDocumentsRequired")
            },
            {
                icon: iconOk,
                iconAlt: 'ok',
                id: "info_2",
                title: t("content.peoples"),
                text: t("content.personalDocumentsRequired")
            },
            {
                icon: iconOk,
                iconAlt: 'ok',
                id: "info_3",
                title: t("content.address"),
                text: t("content.addressRequired")
            },
        ];
    }

    const GetBulletFooterForCountrySelection = useCallback((country) => {

        const defaultFooter = [
            t("content.doubts"),
            {linkText: t("content.manual"), linkUrl: constants.REGISTRATION_GUIDE_URL}
        ];

        const footerByCountry = {
            CL: [
                t("content.doubts"),
                {linkText: t("content.manual"), linkUrl: constants.REGISTRATION_GUIDE_URL},
                t("content.orWriteUs"),
                constants.SUPPORT_EMAIL
            ],
        };
        
        return footerByCountry[country] ?? defaultFooter;
    }, [t]);

    const [bulletFooter, setBulletFooter] = useState(GetBulletFooterForCountrySelection());

    useEffect(() => {
        if(!countryUser) {
            setCountryUser(geoLocation.country);
        }

    }, [geoLocation, countryUser]);

    useEffect(() => {
        const bulletFooter = GetBulletFooterForCountrySelection(countryUser);
        setBulletFooter(bulletFooter);
    }, [countryUser, GetBulletFooterForCountrySelection]);

    return (
        <section className="multi_content">
            <Row>
                <BulletCard
                    colSpec={{ span:5 }}
                    className="d-none d-lg-block"
                    bullets={GetBulletsForCountrySelection()} 
                    title={GetBulletTitleForCountrySelection()}
                    footer={bulletFooter}
                />
                <CountrySelector
                    countryUser={countryUser}
                    setCountryUser={setCountryUser}
                    geoLocationCountry={geoLocation.country}
                    t={t}
                />
            </Row>
        </section>
    )
}

export default FirstPage;