import constants from "../constants";

const taxPayerField = [
    {
        class: "",
        containerClass: "",
        dataSource: "",
        default: "",
        fakeValue: "",
        id: "fcTaxPayerTypeC",
        label: "Tipo de contribuyente",
        labelClass: "",
        labelDescription: "",
        name: "fcTaxPayerTypeC",
        placeHolder: "",
        required: true,
        type: constants.INPUT_TYPE_DROPDOWN,
        options: [
            {
                id: "fcTaxPayerTypeC_1",
                label: "Entidades de régimen tributario especial",
                value: "Entidades de régimen tributario especial"
            },
            {
                id: "fcTaxPayerTypeC_2",
                label: "Gran contribuyente autoretenedor",
                value: "Gran contribuyente autoretenedor"
            },
            {
                id: "fcTaxPayerTypeC_3",
                label: "Gran contribuyente no autoretenedor",
                value: "Gran contribuyente no autoretenedor"
            },
            {
                id: "fcTaxPayerTypeC_4",
                label: "Persona jurídica",
                value: "Persona jurídica"
            },
            {
                id: "fcTaxPayerTypeC_5",
                label: "Persona jurídica autoretenedor",
                value: "Persona jurídica autoretenedor"
            },
            {
                id: "fcTaxPayerTypeC_6",
                label: "Persona jurídica ley 1429 de 2010",
                value: "Persona jurídica ley 1429 de 2010"
            }
        ],
        validators: [
            {
                type: constants.VALIDATOR_NOT_EMPTY,
                errorMessage: "Debes seleccionar una opción."
            }
        ]
    }
];

export default taxPayerField;