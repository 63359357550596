import {RETENTION_TYPE} from "../config/CountryConfigurations";

export const customChangeHandler = (event, fieldConfig, formValues) => {
  const value = event.target.value;

  if(!(fieldConfig.hasOwnProperty('onChange') && fieldConfig.onChange.hasOwnProperty('function'))) return ;

  switch (fieldConfig.onChange.function) {
  case "getRetentionType":
    return getRetentionType({
      isTaxResponsible: value,
      operator: fieldConfig.onChange.params.operator,
      typeOfPerson: formValues.typeOfPerson
    });
  default:
    return "";
  }
};

const getRetentionType = ({ operator, typeOfPerson, isTaxResponsible }) => {
  if(operator && typeOfPerson && isTaxResponsible){
    const retentionType = RETENTION_TYPE[operator][typeOfPerson][isTaxResponsible];
    return Object.keys(retentionType)[0];
  }

  return "";
};
