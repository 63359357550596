import { Col, Row } from 'react-bootstrap';

const BulletCard = props => {
    const { colSpec, bullets, title, footer, className} = props;

    return (
        <Col lg={colSpec} className={className}>
            <div className="content_box lined">
                { title && 
                    <h3>{title}</h3>
                }
                <div className="content_info_bullet_list">
                    { bullets.map((bullet) => (
                        <Row className="bullet" key={bullet.id} >
                            <Col xs={1}>
                                <div className='custom_bullet_marker'></div>
                            </Col>
                            <Col>
                                <h5>{bullet.title}</h5>
                                <h6>{bullet.text}</h6>
                            </Col>
                        </Row>
                    ))}
                </div>
                { footer &&
                    <div className="content_box_footer">
                        <p>
                            {
                                footer.map((part, index) => (
                                typeof part === 'string' ? (
                                    <span key={index}>{part}</span>
                                ) : (
                                    <a
                                    key={index}
                                    target="_blank"
                                    rel="noreferrer noopener"
                                    href={part.linkUrl}>
                                    {part.linkText}
                                    </a>
                                )
                                ))
                            }
                        </p>
                    </div>
                }
            </div>
        </Col>
    )
}

BulletCard.defaultProps = {
    colSpec: '',
    bullets: [],
    className: '',
  };

export default BulletCard;
