import AdobeAnalyticsButtonTags from "../../constants/AdobeAnalyticsButtonTags";
import constants from "../constants";

const FormConfig = [
    {
        section: {
            id: 1,
            title: "",
            subTitle: "¿Cuál es el número de documento de tu empresa?",
            isActive: true
        },
        fields: [
            {
                class: "",
                containerClass: "",
                dataSource: "",
                default: "",
                fakeValue: "",
                id: constants.INPUT_ID_BUSSINESS_REG_NUMBER,
                label: "Número de documento de la empresa -Perú flow-",
                labelEn: "Company document number",
                labelClass: "",
                labelDescription: "",
                name: constants.INPUT_ID_BUSSINESS_REG_NUMBER,
                options: [],
                order: 1,
                placeHolder: "Ej. 00.000.000-0",
                regex: "",
                required: true,
                type: constants.INPUT_TYPE_TEXT,
                maxLength: "9",
                customHandler: constants.INPUT_HANDLER_FORMATTED_RUT_LEGAL_PERSON,
                validators: [
                    {
                        type: constants.VALIDATOR_RUT_IS_LEGAL_PERSON,
                        errorMessage: "Por el momento sólo se permite el registro de personas jurídicas.",
                    },
                    {
                        type: constants.VALIDATOR_RUT,
                        errorMessage: "El número de documento RUT no es válido.",
                    },
                    {
                        type: constants.VALIDATOR_MIN_LENGTH,
                        errorMessage: "Debes ingresar al menos 9 caracteres.",
                        data: {
                            minLength: 9
                        }
                    },
                    {
                        type: constants.VALIDATOR_NOT_EMPTY,
                        errorMessage: "Este campo es obligatorio, ingresa el número de documento de la empresa."
                    }
                ]
            }
        ]
    },
    {
        section:{
            id: 2,
            title: "Información de tu empresa",
            isActive: true,
            stepperNumber: 1,
            stepperLabel: "Empresa",
        },
        fields: [
            {
                class: "",
                containerClass: "",
                dataSource: "",
                default: "",
                fakeValue: "",
                id: "shopName",
                label: "Razón Social",
                labelEn: "Shop Name",
                labelClass: "",
                labelDescription: "",
                name: "shopName",
                options: [],
                order: 1,
                placeHolder: "Ej. Razón Social",
                regex: "",
                required: true,
                type: constants.INPUT_TYPE_TEXT,                
                maxLength: "80",
                validators: [
                    {
                        type: constants.VALIDATOR_NOT_EMPTY,
                        errorMessage: "Ingresa una razón social"
                    },
                    {
                        type: constants.VALIDATOR_REGEX,
                        errorMessage: "Sólo se admiten números, caractéres especiales &,'. y letras sin tílde.",
                        data: {
                            regex: /^[\w ]+[a-zA-Z0-9.&,']+$/
                        }
                    }
                ]
            },            
            {
                class: "",
                containerClass: "",
                dataSource: "",
                default: "",
                fakeValue: "",
                id: "fantasyName",
                label: "Nombre de fantasía",
                labelEn: "Fantasy name",
                labelClass: "",
                labelDescription: "",
                name: "fantasyName",
                options: [],
                order: 2,
                placeHolder: "Ingresa nombre comercial de tu empresa",
                regex: "",
                required: true,
                type: constants.INPUT_TYPE_TEXT,                
                maxLength: "80",
                validators: [
                    {
                        type: constants.VALIDATOR_NOT_EMPTY,
                        errorMessage: "Ingresa un nombre de fantasía"
                    },
                    {
                        type: constants.VALIDATOR_REGEX,
                        errorMessage: "Sólo se admiten números, caractéres especiales &,'. y letras sin tílde.",
                        data: {
                            regex: /^[\w ]+[a-zA-Z0-9.&,']+$/
                        }
                    },
                    {
                        type: constants.VALIDATOR_REGEX,
                        errorMessage: "No puedes colocar correos electrónicos en este campo.",
                        data: {
                            regex: /^((?![.]com|[.]cl|[.]co|[.]pe)[\s\S])*$/
                        }
                    }
                ]
            },
            {
                class: "search-icon",
                id: "economicActivity",
                label: "Código de actividad económica",
                labelEn: "Economic activity code",
                name: "economicActivity",
                order: 1,
                placeHolder: "Selecciona una opción",
                required: true,
                type: constants.INPUT_TYPE_SEARCH_TEXT_BOX,
                dataSource: {
                    type: constants.DATASOURCE_TYPE_API,
                    source: constants.SERVICE_BFF,
                    endpoint: "economicActivity",
                    params: [
                        {
                            name: "countryCode",
                            sourceType: "GlobalStateValue",
                            sourceId: "country"
                        }
                    ]
                },
                validators: [
                    {
                        type: constants.VALIDATOR_VALID_OPTION,
                        errorMessage: "Por favor seleccione una opción válida."
                    }
                ]
            },
        ]
    },
    {
        section: {
            id: 3,
            title: "Información de personas",
            isActive: true,
            stepperNumber: 2,
            stepperLabel: "Personas",
            type: "Accordion",
            activeSubSection: "3-1",
            itShouldHideAccordionNavigators: true,
        },
        subSections: [
            {
                id: "3-1",
                title: "Responsable administración de la cuenta",
                subTitle: "Será el contacto principal de la cuenta en Falabella Seller Center.",
                enabled: true,
                showNavigation: true,
                navigationButtonId: AdobeAnalyticsButtonTags[2].administrator,
                onRenderActions: {
                    subTitle: {
                        handler: "RenderSubTitle",
                        params: {
                            renderSubtitleWith: [
                                constants.INPUT_ID_ADMIN_FIRST_NAME,
                                constants.INPUT_ID_ADMIN_LAST_NAME
                            ]
                        }
                    }
                },
                onNavigateActions: {
                    next: {
                        handler: "RenderSubTitleAndFocusSubsection",
                        params: {
                            nextSubSection: "3-2",
                            renderSubtitleWith: [
                                constants.INPUT_ID_ADMIN_FIRST_NAME,
                                constants.INPUT_ID_ADMIN_LAST_NAME
                            ]
                        }
                    },
                    previous: ""
                },
                autofill : {
                    message: "Usar esta información para todos los contactos.",
                    behavior: {
                        [constants.INPUT_ID_LEGAL_REPRESENTATIVE_NAME]: constants.INPUT_ID_ADMIN_FIRST_NAME,
                        [constants.INPUT_ID_LEGAL_REPRESENTATIVE_LASTNAME]: constants.INPUT_ID_ADMIN_LAST_NAME,
                        [constants.INPUT_ID_LEGAL_REPRESENTATIVE_DOCUMENT_NUMBER]: constants.INPUT_ID_ADMIN_DOCUMENT_NUMBER,
                        [constants.INPUT_ID_LEGAL_REPRESENTATIVE_PHONE]: constants.INPUT_ID_ADMIN_PHONE,
                        [constants.INPUT_ID_LEGAL_REPRESENTATIVE_EMAIL]: constants.INPUT_ID_ADMIN_EMAIL,
                        'confirmLegalRepEmail': "confirmAdminEmail",
                        [constants.INPUT_ID_SHIPMENT_WAREHOUSE_CONTACT_NAME] : [constants.INPUT_ID_ADMIN_FIRST_NAME, constants.INPUT_ID_ADMIN_LAST_NAME],
                        [constants.INPUT_ID_SHIPMENT_WAREHOUSE_CONTACT_PHONE] : constants.INPUT_ID_ADMIN_PHONE,
                        [constants.INPUT_ID_SHIPMENT_WAREHOUSE_CONTACT_EMAIL] : constants.INPUT_ID_ADMIN_EMAIL,
                        'confirmShipmentWarehouseContactEmail' : "confirmAdminEmail",
                        [constants.INPUT_ID_RETURN_WAREHOUSE_CONTACT_NAME] : [constants.INPUT_ID_ADMIN_FIRST_NAME, constants.INPUT_ID_ADMIN_LAST_NAME],
                        [constants.INPUT_ID_RETURN_WAREHOUSE_CONTACT_PHONE] : constants.INPUT_ID_ADMIN_PHONE,
                        [constants.INPUT_ID_RETURN_WAREHOUSE_CONTACT_EMAIL] : constants.INPUT_ID_ADMIN_EMAIL,
                        'confirmReturnWarehouseContactEmail' : "confirmAdminEmail",
                    }
                },
                fields:[
                    {
                        class: "",
                        containerClass: "",
                        default: "",
                        id: constants.INPUT_ID_ADMIN_FIRST_NAME,
                        label: "Nombre",
                        labelEn: "Name",
                        labelClass: "",
                        labelDescription: "",
                        name: constants.INPUT_ID_ADMIN_FIRST_NAME,
                        placeHolder: "Ej. Juan",
                        required:true,
                        type: constants.INPUT_TYPE_TEXT,
                        maxLength: "49",
                        customHandler: constants.INPUT_HANDLER_ONLY_LETTERS_AND_SPACES,
                        validators: [
                            {
                                type: constants.VALIDATOR_NOT_EMPTY,
                                errorMessage: "Ingresa un nombre"
                            }
                        ]
                    },
                    {
                        class: "",
                        containerClass: "",
                        default: "",
                        id: constants.INPUT_ID_ADMIN_LAST_NAME,
                        label: "Apellido",
                        labelEn: "",
                        labelClass: "",
                        labelDescription: "",
                        name: constants.INPUT_ID_ADMIN_LAST_NAME,
                        placeHolder: "Ej. Olivares",
                        required:true,
                        type: constants.INPUT_TYPE_TEXT,
                        maxLength: "49",
                        customHandler: constants.INPUT_HANDLER_ONLY_LETTERS_AND_SPACES,
                        validators: [
                            {
                                type: constants.VALIDATOR_NOT_EMPTY,
                                errorMessage: "Ingresa un apellido"
                            }
                        ]
                    },
                    {
                        class: "",
                        containerClass: "",
                        default: "",
                        id: constants.INPUT_ID_ADMIN_DOCUMENT_NUMBER,
                        label: "Número de documento de identificación",
                        labelEn: "",
                        labelClass: "",
                        labelDescription: "",
                        name: constants.INPUT_ID_ADMIN_DOCUMENT_NUMBER,
                        placeHolder: "Ej. 00.000.000-k",
                        required:true,
                        type: constants.INPUT_TYPE_TEXT,
                        maxLength: "9",
                        customHandler: constants.INPUT_HANDLER_FORMATTED_RUT_NATURAL_PERSON,
                        validators: [
                            {
                                type: constants.VALIDATOR_RUT_IS_NATURAL_PERSON,
                                errorMessage: "Sólo puedes ingresar un RUT de persona natural.",
                            },
                            {
                                type: constants.VALIDATOR_RUT,
                                errorMessage: "El número de documento RUT no es válido.",
                            },
                            {
                                type: constants.VALIDATOR_MIN_LENGTH,
                                errorMessage: "Debes ingresar al menos 8 caracteres.",
                                data: {
                                    minLength: 8
                                }
                            },
                            {
                                type: constants.VALIDATOR_NOT_EMPTY,
                                errorMessage: "Ingresa un número de documento de identificación"
                            }
                        ]
                    },
                    {
                        class: "field_group_inline",
                        containerClass: "",
                        id: "phoneNumberGroupAdmin",
                        label: "Teléfono",
                        labelClass: "",
                        name: "phoneNumberGroupAdmin",
                        type: constants.INPUT_TYPE_FIELDGROUP,
                        subFields: [
                            {
                                class: "field_group_label padding_top_5",
                                containerClass: "field_group_inline_left width_10",
                                id: "adminPhoneCountryCode",
                                label: "+",
                                labelClass: "",
                                name: "adminPhoneCountryCode",
                                type: constants.INPUT_TYPE_LABEL,
                                customHandler: "renderPhoneCountryCode"
                            },
                            {
                                class: "",
                                containerClass: "field_group_inline_right width_90",
                                default: "",
                                id: constants.INPUT_ID_ADMIN_PHONE,
                                label: " ",
                                labelClass: "",
                                name: constants.INPUT_ID_ADMIN_PHONE,
                                options: [],
                                order: 2,
                                placeHolder: "Ej. 983456789",
                                required: true,
                                type: constants.INPUT_TYPE_TEXT,
                                maxLength: "9",
                                customHandler: constants.INPUT_HANDLER_ONLY_NUMBERS,
                                validators: [
                                    {
                                        type: constants.VALIDATOR_NOT_EMPTY,
                                        errorMessage: "Ingresa un teléfono"
                                    },
                                    {
                                        type: constants.VALIDATOR_MIN_LENGTH,
                                        errorMessage: "El teléfono debe contener 9 dígitos.",
                                        data: {
                                            minLength: 9
                                        }
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        class: "",
                        containerClass: "",
                        default: "",
                        id: constants.INPUT_ID_ADMIN_EMAIL,
                        label: "Correo electrónico",
                        labelEn: "",
                        labelClass: "",
                        labelDescription: "",
                        name: constants.INPUT_ID_ADMIN_EMAIL,
                        placeHolder: "Ej. contacto@info.com",
                        required:true,
                        type: constants.INPUT_TYPE_TEXT,
                        validators: [
                            {
                                type: constants.VALIDATOR_EMAIL,
                                errorMessage: "El email ingresado no es válido."
                            },
                            {
                                type: constants.VALIDATOR_NOT_EMPTY,
                                errorMessage: "Ingresa un correo electrónico válido"
                            }
                        ]
                    },
                    {
                        class: "",
                        containerClass: "",
                        default: "",
                        id: "confirmAdminEmail",
                        label: "Confirma correo electrónico",
                        labelEn: "",
                        labelClass: "",
                        labelDescription: "",
                        name: "confirmAdminEmail",
                        placeHolder: "Ej. contacto@info.com",
                        required:true,
                        type: constants.INPUT_TYPE_TEXT,
                        validators: [
                            {
                                type: constants.VALIDATOR_EQUAL_FIELD_VALUE,
                                errorMessage: "Por favor revisa este dato, no es igual al correo ingresado en el campo anterior.",
                                data: {
                                    sourceFieldId: "adminEmail"
                                }
                            },
                            {
                                type: constants.VALIDATOR_EMAIL,
                                errorMessage: "El email ingresado no es válido."
                            },
                            {
                                type: constants.VALIDATOR_NOT_EMPTY,
                                errorMessage: "Ingresa un electrónico"
                            }
                        ]
                    }
                ]
            },
            {
                id: "3-2",
                title: "Representante legal de la empresa",
                subTitle: "Será el contacto principal de la cuenta Fpay, donde recibirán los pagos.",
                enabled: false,
                showNavigation: true,
                navigationButtonId: AdobeAnalyticsButtonTags[2].legal,
                onRenderActions: {
                    subTitle: {
                        handler: "RenderSubTitle",
                        params: {
                            renderSubtitleWith: [
                                constants.INPUT_ID_LEGAL_REPRESENTATIVE_NAME
                            ]
                        }
                    }
                },
                onNavigateActions: {
                    next: {
                        handler: "RenderSubTitleAndFocusSubsection",
                        params: {
                            nextSubSection: "3-3",
                            renderSubtitleWith: [
                                constants.INPUT_ID_LEGAL_REPRESENTATIVE_NAME
                            ]
                        }
                    },
                    previous: ""
                },
                autofill : {
                    message: "Usar esta información para los contactos de bodega de envío y devolución.",
                    behavior: {
                        [constants.INPUT_ID_SHIPMENT_WAREHOUSE_CONTACT_NAME] : [constants.INPUT_ID_LEGAL_REPRESENTATIVE_NAME, constants.INPUT_ID_LEGAL_REPRESENTATIVE_LASTNAME],
                        [constants.INPUT_ID_SHIPMENT_WAREHOUSE_CONTACT_PHONE] : constants.INPUT_ID_LEGAL_REPRESENTATIVE_PHONE,
                        [constants.INPUT_ID_SHIPMENT_WAREHOUSE_CONTACT_EMAIL] : constants.INPUT_ID_LEGAL_REPRESENTATIVE_EMAIL,
                        'confirmShipmentWarehouseContactEmail' : "confirmLegalRepEmail",
                        [constants.INPUT_ID_RETURN_WAREHOUSE_CONTACT_NAME] : [constants.INPUT_ID_LEGAL_REPRESENTATIVE_NAME, constants.INPUT_ID_LEGAL_REPRESENTATIVE_LASTNAME],
                        [constants.INPUT_ID_RETURN_WAREHOUSE_CONTACT_PHONE] : constants.INPUT_ID_LEGAL_REPRESENTATIVE_PHONE,
                        [constants.INPUT_ID_RETURN_WAREHOUSE_CONTACT_EMAIL] : constants.INPUT_ID_LEGAL_REPRESENTATIVE_EMAIL,
                        'confirmReturnWarehouseContactEmail' : "confirmLegalRepEmail",
                    }
                },
                fields: [
                    {
                        class: "",
                        containerClass: "",
                        default: "",
                        id: constants.INPUT_ID_LEGAL_REPRESENTATIVE_NAME,
                        label: "Nombre",
                        labelEn: "",
                        labelClass: "",
                        labelDescription: "",
                        name: constants.INPUT_ID_LEGAL_REPRESENTATIVE_NAME,
                        placeHolder: "Ej. Juan",
                        required:true,
                        type: constants.INPUT_TYPE_TEXT,
                        maxLength: "49",
                        customHandler: constants.INPUT_HANDLER_ONLY_LETTERS_AND_SPACES,
                        validators: [
                            {
                                type: constants.VALIDATOR_NOT_EMPTY,
                                errorMessage: "Ingresa un nombre"
                            }
                        ]
                    },
                    {
                        class: "",
                        containerClass: "",
                        default: "",
                        id: constants.INPUT_ID_LEGAL_REPRESENTATIVE_LASTNAME,
                        label: "Apellido",
                        labelEn: "",
                        labelClass: "",
                        labelDescription: "",
                        name: constants.INPUT_ID_LEGAL_REPRESENTATIVE_LASTNAME,
                        placeHolder: "Ej. Olivares",
                        required:true,
                        type: constants.INPUT_TYPE_TEXT,
                        maxLength: "49",
                        customHandler: constants.INPUT_HANDLER_ONLY_LETTERS_AND_SPACES,
                        validators: [
                            {
                                type: constants.VALIDATOR_NOT_EMPTY,
                                errorMessage: "Ingresa un apellido"
                            }
                        ]
                    },
                    {
                        class: "",
                        containerClass: "",
                        default: "",
                        id: constants.INPUT_ID_LEGAL_REPRESENTATIVE_DOCUMENT_NUMBER,
                        label: "Número de documento de identificación",
                        labelEn: "",
                        labelClass: "",
                        labelDescription: "",
                        name: constants.INPUT_ID_LEGAL_REPRESENTATIVE_DOCUMENT_NUMBER,
                        placeHolder: "Ej. 00.000.000-k",
                        required:true,
                        type: constants.INPUT_TYPE_TEXT,
                        maxLength: "9",
                        customHandler: constants.INPUT_HANDLER_FORMATTED_RUT_NATURAL_PERSON,
                        validators: [
                            {
                                type: constants.VALIDATOR_RUT_IS_NATURAL_PERSON,
                                errorMessage: "Sólo puedes ingresar un RUT de persona natural.",
                            },
                            {
                                type: constants.VALIDATOR_RUT,
                                errorMessage: "El número de documento RUT no es válido.",
                            },
                            {
                                type: constants.VALIDATOR_MIN_LENGTH,
                                errorMessage: "Debes ingresar al menos 8 caracteres.",
                                data: {
                                    minLength: 8
                                }
                            },
                            {
                                type: constants.VALIDATOR_NOT_EMPTY,
                                errorMessage: "Ingresa un número de documento de identificación"
                            }
                        ]
                    },
                    {
                        class: "",
                        containerClass: "",
                        dataSource: "",
                        id: constants.INPUT_ID_LEGAL_REPRESENTATIVE_DOCUMENT_FILE,
                        label: "Adjunta una imagen del documento de identificación",
                        labelClass: "",
                        labelDescription: "1 Archivo permitido JPG, PNG o PDF.",
                        name: constants.INPUT_ID_LEGAL_REPRESENTATIVE_DOCUMENT_FILE,
                        placeHolder: "Adjunta una imagen del documento de identificación",
                        type: constants.INPUT_TYPE_FILE,
                        attachTextButton: "Adjunta",
                        attachTextLabel: "o arrastra el archivo",
                        dropTextMessage: "Suelta el archivo en esta área",
                        accept: ".jpg,.jpeg,.png,.pdf"
                    },
                    {
                        class: "field_group_inline",
                        containerClass: "",
                        id: "phoneNumberGroupLegalRep",
                        label: "Teléfono",
                        labelClass: "",
                        name: "phoneNumberGroupLegalRep",
                        type: constants.INPUT_TYPE_FIELDGROUP,
                        subFields: [
                            {
                                class: "field_group_label padding_top_5",
                                containerClass: "field_group_inline_left width_10",
                                id: "legalRepPhoneCountryCode",
                                label: "+",
                                labelClass: "",
                                name: "legalRepPhoneCountryCode",
                                type: constants.INPUT_TYPE_LABEL,
                                customHandler: "renderPhoneCountryCode"
                            },
                            {
                                class: "",
                                containerClass: "field_group_inline_right width_90",
                                default: "",
                                id: constants.INPUT_ID_LEGAL_REPRESENTATIVE_PHONE,
                                label: " ",
                                labelClass: "",
                                name: constants.INPUT_ID_LEGAL_REPRESENTATIVE_PHONE,
                                options: [],
                                order: 2,
                                placeHolder: "Ej. 983456789",
                                required: true,
                                type: constants.INPUT_TYPE_TEXT,
                                maxLength: "9",
                                customHandler: constants.INPUT_HANDLER_ONLY_NUMBERS,
                                validators: [
                                    {
                                        type: constants.VALIDATOR_NOT_EMPTY,
                                        errorMessage: "Ingresa un teléfono"
                                    },
                                    {
                                        type: constants.VALIDATOR_MIN_LENGTH,
                                        errorMessage: "El teléfono debe contener 9 dígitos.",
                                        data: {
                                            minLength: 9
                                        }
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        class: "",
                        containerClass: "",
                        default: "",
                        id: constants.INPUT_ID_LEGAL_REPRESENTATIVE_EMAIL,
                        label: "Correo electrónico",
                        labelEn: "",
                        labelClass: "",
                        labelDescription: "",
                        name: constants.INPUT_ID_LEGAL_REPRESENTATIVE_EMAIL,
                        placeHolder: "Ej. contacto@info.com",
                        required:true,
                        type: constants.INPUT_TYPE_TEXT,
                        validators: [
                            {
                                type: constants.VALIDATOR_EMAIL,
                                errorMessage: "El email ingresado no es válido."
                            },
                            {
                                type: constants.VALIDATOR_NOT_EMPTY,
                                errorMessage: "Ingresa un correo electrónico válido"
                            }
                        ]
                    },
                    {
                        class: "",
                        containerClass: "",
                        default: "",
                        id: "confirmLegalRepEmail",
                        label: "Confirma correo electrónico",
                        labelEn: "",
                        labelClass: "",
                        labelDescription: "",
                        name: "confirmLegalRepEmail",
                        placeHolder: "Ej. contacto@info.com",
                        required:true,
                        type: constants.INPUT_TYPE_TEXT,
                        validators: [
                            {
                                type: constants.VALIDATOR_EQUAL_FIELD_VALUE,
                                errorMessage: "Por favor revisa este dato, no es igual al correo ingresado en el campo anterior.",
                                data: {
                                    sourceFieldId: constants.INPUT_ID_LEGAL_REPRESENTATIVE_EMAIL
                                }
                            },
                            {
                                type: constants.VALIDATOR_EMAIL,
                                errorMessage: "El email ingresado no es válido."
                            },
                            {
                                type: constants.VALIDATOR_NOT_EMPTY,
                                errorMessage: "Ingresa un electrónico"
                            }
                        ]
                    }
                ]
            },
            {
                id: "3-3",
                title: "Contacto bodega de envío",
                subTitle: "",
                enabled: false,
                showNavigation: true,
                navigationButtonId: AdobeAnalyticsButtonTags[2].shipmentWHContact,
                onRenderActions: {
                    subTitle: {
                        handler: "RenderSubTitle",
                        params: {
                            renderSubtitleWith: [
                                constants.INPUT_ID_SHIPMENT_WAREHOUSE_CONTACT_NAME
                            ]
                        }
                    }
                },
                onNavigateActions: {
                    next: {
                        handler: "RenderSubTitleAndFocusSubsection",
                        params: {
                            nextSubSection: "3-4",
                            renderSubtitleWith: [
                                constants.INPUT_ID_SHIPMENT_WAREHOUSE_CONTACT_NAME
                            ]
                        }
                    },
                    previous: ""
                },
                autofill : {
                    message: "Usar esta información para contacto de bodega de devolución.",
                    behavior: {
                        [constants.INPUT_ID_RETURN_WAREHOUSE_CONTACT_NAME] : constants.INPUT_ID_SHIPMENT_WAREHOUSE_CONTACT_NAME,
                        [constants.INPUT_ID_RETURN_WAREHOUSE_CONTACT_PHONE] : constants.INPUT_ID_SHIPMENT_WAREHOUSE_CONTACT_PHONE,
                        [constants.INPUT_ID_RETURN_WAREHOUSE_CONTACT_EMAIL] : constants.INPUT_ID_SHIPMENT_WAREHOUSE_CONTACT_EMAIL,
                        'confirmReturnWarehouseContactEmail' : "confirmShipmentWarehouseContactEmail"
                    }
                },
                fields: [
                    {
                        class: "",
                        containerClass: "",
                        default: "",
                        id: constants.INPUT_ID_SHIPMENT_WAREHOUSE_CONTACT_NAME,
                        label: "Nombre Completo",
                        labelEn: "",
                        labelClass: "",
                        labelDescription: "",
                        name: constants.INPUT_ID_SHIPMENT_WAREHOUSE_CONTACT_NAME,
                        placeHolder: "Ej. Juan Olivares",
                        required:true,
                        type: constants.INPUT_TYPE_TEXT,
                        maxLength: "49",
                        customHandler: constants.INPUT_HANDLER_ONLY_LETTERS_AND_SPACES,
                        validators: [
                            {
                                type: constants.VALIDATOR_NOT_EMPTY,
                                errorMessage: "Ingresa un nombre completo"
                            }
                        ]
                    },
                    {
                        class: "field_group_inline",
                        containerClass: "",
                        id: "phoneNumberGroupShipmentWarehouseContact",
                        label: "Teléfono",
                        labelClass: "",
                        name: "phoneNumberGroupShipmentWarehouseContact",
                        type: constants.INPUT_TYPE_FIELDGROUP,
                        subFields: [
                            {
                                class: "field_group_label padding_top_5",
                                containerClass: "field_group_inline_left width_10",
                                id: "shipmentWarehouseContactPhoneCountryCode",
                                label: "+",
                                labelClass: "",
                                name: "shipmentWarehouseContactPhoneCountryCode",
                                type: constants.INPUT_TYPE_LABEL,
                                customHandler: "renderPhoneCountryCode"
                            },
                            {
                                class: "",
                                containerClass: "field_group_inline_right width_90",
                                default: "",
                                id: constants.INPUT_ID_SHIPMENT_WAREHOUSE_CONTACT_PHONE,
                                label: " ",
                                labelClass: "",
                                name: constants.INPUT_ID_SHIPMENT_WAREHOUSE_CONTACT_PHONE,
                                options: [],
                                order: 2,
                                placeHolder: "Ej. 983456789",
                                required: true,
                                type: constants.INPUT_TYPE_TEXT,
                                maxLength: "9",
                                customHandler: constants.INPUT_HANDLER_ONLY_NUMBERS,
                                validators: [
                                    {
                                        type: constants.VALIDATOR_NOT_EMPTY,
                                        errorMessage: "Ingresa un teléfono"
                                    },
                                    {
                                        type: constants.VALIDATOR_MIN_LENGTH,
                                        errorMessage: "El teléfono debe contener 9 dígitos.",
                                        data: {
                                            minLength: 9
                                        }
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        class: "",
                        containerClass: "",
                        default: "",
                        id: constants.INPUT_ID_SHIPMENT_WAREHOUSE_CONTACT_EMAIL,
                        label: "Correo electrónico",
                        labelEn: "",
                        labelClass: "",
                        labelDescription: "",
                        name: constants.INPUT_ID_SHIPMENT_WAREHOUSE_CONTACT_EMAIL,
                        placeHolder: "Ej. contacto@info.com",
                        required:true,
                        type: constants.INPUT_TYPE_TEXT,
                        validators: [
                            {
                                type: constants.VALIDATOR_EMAIL,
                                errorMessage: "El email ingresado no es válido."
                            },
                            {
                                type: constants.VALIDATOR_NOT_EMPTY,
                                errorMessage: "Ingresa un correo electrónico válido"
                            }
                        ]
                    },
                    {
                        class: "",
                        containerClass: "",
                        default: "",
                        id: "confirmShipmentWarehouseContactEmail",
                        label: "Confirma correo electrónico",
                        labelEn: "",
                        labelClass: "",
                        labelDescription: "",
                        name: "confirmShipmentWarehouseContactEmail",
                        placeHolder: "Ej. contacto@info.com",
                        required:true,
                        type: constants.INPUT_TYPE_TEXT,
                        validators: [
                            {
                                type: constants.VALIDATOR_EQUAL_FIELD_VALUE,
                                errorMessage: "Por favor revisa este dato, no es igual al correo ingresado en el campo anterior.",
                                data: {
                                    sourceFieldId: constants.INPUT_ID_SHIPMENT_WAREHOUSE_CONTACT_EMAIL
                                }
                            },
                            {
                                type: constants.VALIDATOR_EMAIL,
                                errorMessage: "El email ingresado no es válido."
                            },
                            {
                                type: constants.VALIDATOR_NOT_EMPTY,
                                errorMessage: "Ingresa un electrónico"
                            }
                        ]
                    }
                ]
            },
            {
                id: "3-4",
                title: "Contacto bodega de devolución",
                subTitle: "",
                enabled: false,
                showNavigation: false,
                navigationButtonId: AdobeAnalyticsButtonTags[2].returnWHContact,
                onRenderActions: {
                    subTitle: {
                        handler: "RenderSubTitle",
                        params: {
                            renderSubtitleWith: [
                                constants.INPUT_ID_RETURN_WAREHOUSE_CONTACT_NAME
                            ]
                        }
                    }
                },
                onNavigateActions: {
                    next: {
                        handler: constants.ACTION_HANDLER_RENDER_SUBTITLE_AND_ACTIVATE_PAGE_NAVIGATION,
                        params: {
                            currentSubSection: "3-4",
                            renderSubtitleWith: [
                                constants.INPUT_ID_RETURN_WAREHOUSE_CONTACT_NAME
                            ]
                        }
                    },
                    previous: ""
                },
                fields: [
                    {
                        class: "",
                        containerClass: "",
                        default: "",
                        id: constants.INPUT_ID_RETURN_WAREHOUSE_CONTACT_NAME,
                        label: "Nombre Completo",
                        labelEn: "",
                        labelClass: "",
                        labelDescription: "",
                        name: constants.INPUT_ID_RETURN_WAREHOUSE_CONTACT_NAME,
                        placeHolder: "Ej. Juan Olivares",
                        required:true,
                        type: constants.INPUT_TYPE_TEXT,
                        maxLength: "49",
                        customHandler: constants.INPUT_HANDLER_ONLY_LETTERS_AND_SPACES,
                        validators: [
                            {
                                type: constants.VALIDATOR_NOT_EMPTY,
                                errorMessage: "Ingresa un nombre completo"
                            }
                        ]
                    },
                    {
                        class: "field_group_inline",
                        containerClass: "",
                        id: "phoneNumberGroupReturnWarehouseContact",
                        label: "Teléfono",
                        labelClass: "",
                        name: "phoneNumberGroupReturnWarehouseContact",
                        type: constants.INPUT_TYPE_FIELDGROUP,
                        subFields: [
                            {
                                class: "field_group_label padding_top_5",
                                containerClass: "field_group_inline_left width_10",
                                id: "returnWarehouseContactPhoneCountryCode",
                                label: "+",
                                labelClass: "",
                                name: "returnWarehouseContactPhoneCountryCode",
                                type: constants.INPUT_TYPE_LABEL,
                                customHandler: "renderPhoneCountryCode"
                            },
                            {
                                class: "",
                                containerClass: "field_group_inline_right width_90",
                                default: "",
                                id: constants.INPUT_ID_RETURN_WAREHOUSE_CONTACT_PHONE,
                                label: " ",
                                labelClass: "",
                                name: constants.INPUT_ID_RETURN_WAREHOUSE_CONTACT_PHONE,
                                options: [],
                                order: 2,
                                placeHolder: "Ej. 983456789",
                                required: true,
                                type: constants.INPUT_TYPE_TEXT,
                                maxLength: "9",
                                customHandler: constants.INPUT_HANDLER_ONLY_NUMBERS,
                                validators: [
                                    {
                                        type: constants.VALIDATOR_NOT_EMPTY,
                                        errorMessage: "Ingresa un teléfono"
                                    },
                                    {
                                        type: constants.VALIDATOR_MIN_LENGTH,
                                        errorMessage: "El teléfono debe contener 9 dígitos.",
                                        data: {
                                            minLength: 9
                                        }
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        class: "",
                        containerClass: "",
                        default: "",
                        id: constants.INPUT_ID_RETURN_WAREHOUSE_CONTACT_EMAIL,
                        label: "Correo electrónico",
                        labelEn: "",
                        labelClass: "",
                        labelDescription: "",
                        name: constants.INPUT_ID_RETURN_WAREHOUSE_CONTACT_EMAIL,
                        placeHolder: "Ej. contacto@info.com",
                        required:true,
                        type: constants.INPUT_TYPE_TEXT,
                        validators: [
                            {
                                type: constants.VALIDATOR_EMAIL,
                                errorMessage: "El email ingresado no es válido."
                            },
                            {
                                type: constants.VALIDATOR_NOT_EMPTY,
                                errorMessage: "Ingresa un correo electrónico válido"
                            }
                        ]
                    },
                    {
                        class: "",
                        containerClass: "",
                        default: "",
                        id: "confirmReturnWarehouseContactEmail",
                        label: "Confirma correo electrónico",
                        labelEn: "",
                        labelClass: "",
                        labelDescription: "",
                        name: "confirmReturnWarehouseContactEmail",
                        placeHolder: "Ej. contacto@info.com",
                        required:true,
                        type: constants.INPUT_TYPE_TEXT,
                        validators: [
                            {
                                type: constants.VALIDATOR_EQUAL_FIELD_VALUE,
                                errorMessage: "Por favor revisa este dato, no es igual al correo ingresado en el campo anterior.",
                                data: {
                                    sourceFieldId: constants.INPUT_ID_RETURN_WAREHOUSE_CONTACT_EMAIL
                                }
                            },
                            {
                                type: constants.VALIDATOR_EMAIL,
                                errorMessage: "El email ingresado no es válido."
                            },
                            {
                                type: constants.VALIDATOR_NOT_EMPTY,
                                errorMessage: "Ingresa un electrónico"
                            }
                        ]
                    }
                ]
            }
        ]
    },
    {
        section: {
            id: 4,
            title: "Información de direcciones",
            isActive: true,
            stepperNumber: 3,
            stepperLabel: "Direcciones",
            type: "Accordion",
            activeSubSection: "4-1",
            itShouldHideAccordionNavigators: true
        },
        subSections: [
            {
                id: "4-1",
                title: "Dirección casa matriz",
                subTitle: "",
                enabled: true,
                showNavigation: true,
                navigationButtonId: AdobeAnalyticsButtonTags[3].casaMatriz,
                onRenderActions: {
                    subTitle: {
                        handler: "RenderSubTitle",
                        params: {
                            renderSubtitleWith: [
                                "addressHeadOffice",
                                "optionLabel_municipalHeadOffice",
                                "optionLabel_regionHeadOffice"
                            ],
                            subtitleSeparator: ", "
                        }
                    }
                },
                onNavigateActions: {
                    next: {
                        handler: "RenderSubTitleAndFocusSubsection",
                        params: {
                            nextSubSection: "4-2",
                            renderSubtitleWith: [
                                "addressHeadOffice",
                                "municipalHeadOffice",
                                "regionHeadOffice"
                            ],
                            subtitleSeparator: ", "
                        }
                    },
                    previous: ""
                },
                autofill: {
                    message: "Usar esta información para todas las direcciones.",
                    behavior: {
                        'regionShipmentWarehouse' : "regionHeadOffice",
                        'municipalShipmentWarehouse' : "municipalHeadOffice",
                        'addressShipmentWarehouse' : "addressHeadOffice",
                        'regionReturnWarehouse': "regionHeadOffice",
                        'municipalReturnWarehouse': "municipalHeadOffice",
                        'addressReturnWarehouse' : "addressHeadOffice"
                    }
                },
                fields: [
                    {
                        class: "search-icon",
                        containerClass: "",
                        default: "",
                        id: "regionHeadOffice",
                        label: "Región",
                        labelEn: "",
                        labelClass: "",
                        labelDescription: "",
                        name: "regionHeadOffice",
                        onChangeActions: [
                            {
                                handler: "loadOptions",
                                params: {
                                    targetId: "municipalHeadOffice",
                                    dataSource: {
                                        type: constants.DATASOURCE_TYPE_API,
                                        source: constants.SERVICE_BFF,
                                        endpoint: "faclRegionMunicipals",
                                        params: [
                                            {
                                                name: "regionId",
                                                sourceType: "FormFieldValue",
                                                sourceId: "regionHeadOffice"
                                            }
                                        ]
                                    }
                                }
                            }
                        ],
                        options: [],
                        placeHolder: "Ingresa una región",
                        type: constants.INPUT_TYPE_DROPDOWN_SEARCH,
                        dataSource: {
                            type: constants.DATASOURCE_TYPE_API,
                            source: constants.SERVICE_BFF,
                            endpoint: "regions",
                            params: [
                                {
                                    name: "countryCode",
                                    sourceType: "GlobalStateValue",
                                    sourceId: "country"
                                }
                            ]
                        },
                        validators: [
                            {
                                type: constants.VALIDATOR_NOT_EMPTY,
                                errorMessage: "Este campo es obligatorio, ingresa el número de documento de la empresa."
                            }
                        ]
                    },                  
                    {
                        class: "search-icon",
                        containerClass: "",
                        default: "",
                        id: "municipalHeadOffice",
                        label: "Comuna",
                        labelEn: "",
                        labelClass: "",
                        labelDescription: "",
                        name: "municipalHeadOffice",
                        options: [],
                        placeHolder: "Ingresa una comuna",
                        type: constants.INPUT_TYPE_DROPDOWN_SEARCH,
                        dataSource: {
                            type: constants.DATASOURCE_TYPE_API,
                            source: constants.SERVICE_BFF,
                            endpoint: "faclRegionMunicipals",
                            params: [
                                {
                                    name: "regionId",
                                    sourceType: "GlobalStateValue",
                                    sourceId: "regionHeadOffice"
                                }
                            ]
                        },
                        validators: [
                            {
                                type: constants.VALIDATOR_NOT_EMPTY,
                                errorMessage: "Este campo es obligatorio, ingresa el número de documento de la empresa."
                            }
                        ]
                    },
                    {
                        class: "",
                        containerClass: "",
                        dataSource: "",
                        default: "",
                        fakeValue: "",
                        id: "addressHeadOffice",
                        label: "Calle y número",
                        labelEn: "Street and number",
                        labelClass: "",
                        labelDescription: "",
                        name: "addressHeadOffice",
                        options: [],
                        placeHolder: "Ingresa el nombre de la calle y número",
                        type: constants.INPUT_TYPE_TEXT,
                        maxLength: "149",
                        customHandler: constants.INPUT_HANDLER_ONLY_LETTERS_NUMBERS_AND_SPACES,
                        validators: [
                            {
                                type: constants.VALIDATOR_NOT_EMPTY,
                                errorMessage: "Ingresa un nombre"
                            }
                        ]
                    }
                ]
            },
            {
                id: "4-2",
                title: "Dirección bodega de envío",
                subTitle: "",
                enabled: false,
                showNavigation: true,
                navigationButtonId: AdobeAnalyticsButtonTags[3].shipmentWH,
                onRenderActions: {
                    subTitle: {
                        handler: "RenderSubTitle",
                        params: {
                            renderSubtitleWith: [
                                "addressShipmentWarehouse",
                                "optionLabel_municipalShipmentWarehouse",
                                "optionLabel_regionShipmentWarehouse"
                            ],
                            subtitleSeparator: ", "
                        }
                    }
                },
                onNavigateActions: {
                    next: {
                        handler: "RenderSubTitleAndFocusSubsection",
                        params: {
                            nextSubSection: "4-3",
                            renderSubtitleWith: [
                                "addressShipmentWarehouse",
                                "municipalShipmentWarehouse",
                                "regionShipmentWarehouse"
                            ],
                            subtitleSeparator: ", "
                        }
                    },
                    previous: ""
                },
                autofill: {
                    message: "Usar esta información para la dirección de devolución.",
                    behavior: {
                        'regionReturnWarehouse': "regionShipmentWarehouse",
                        'municipalReturnWarehouse': "municipalShipmentWarehouse",
                        'addressReturnWarehouse' : "addressShipmentWarehouse"
                    }
                },
                fields: [
                    {
                        class: "search-icon",
                        containerClass: "",
                        default: "",
                        id: "regionShipmentWarehouse",
                        label: "Región",
                        labelEn: "",
                        labelClass: "",
                        labelDescription: "",
                        name: "regionShipmentWarehouse",
                        onChangeActions: [
                            {
                                handler: "loadOptions",
                                params: {
                                    targetId: "municipalShipmentWarehouse",
                                    dataSource: {
                                        type: constants.DATASOURCE_TYPE_API,
                                        source: constants.SERVICE_BFF,
                                        endpoint: "faclRegionMunicipals",
                                        params: [
                                            {
                                                name: "regionId",
                                                sourceType: "FormFieldValue",
                                                sourceId: "regionShipmentWarehouse"
                                            }
                                        ]
                                    }
                                }
                            }
                        ],
                        options: [],
                        placeHolder: "Ingresa una región",
                        type: constants.INPUT_TYPE_DROPDOWN_SEARCH,
                        dataSource: {
                            type: constants.DATASOURCE_TYPE_API,
                            source: constants.SERVICE_BFF,
                            endpoint: "regions",
                            params: [
                                {
                                    name: "countryCode",
                                    sourceType: "GlobalStateValue",
                                    sourceId: "country"
                                }
                            ]
                        },
                        validators: [
                            {
                                type: constants.VALIDATOR_NOT_EMPTY,
                                errorMessage: "Este campo es obligatorio, ingresa el número de documento de la empresa."
                            }
                        ]
                    },                  
                    {
                        class: "search-icon",
                        containerClass: "",
                        default: "",
                        id: "municipalShipmentWarehouse",
                        label: "Comuna",
                        labelEn: "",
                        labelClass: "",
                        labelDescription: "",
                        name: "municipalShipmentWarehouse",
                        options: [],
                        placeHolder: "Ingresa una comuna",
                        type: constants.INPUT_TYPE_DROPDOWN_SEARCH,
                        dataSource: {
                            type: constants.DATASOURCE_TYPE_API,
                            source: constants.SERVICE_BFF,
                            endpoint: "faclRegionMunicipals",
                            params: [
                                {
                                    name: "regionId",
                                    sourceType: "GlobalStateValue",
                                    sourceId: "regionShipmentWarehouse"
                                }
                            ]
                        },
                        validators: [
                            {
                                type: constants.VALIDATOR_NOT_EMPTY,
                                errorMessage: "Este campo es obligatorio, ingresa el número de documento de la empresa."
                            }
                        ]
                    },
                    {
                        class: "",
                        containerClass: "",
                        dataSource: "",
                        default: "",
                        fakeValue: "",
                        id: "addressShipmentWarehouse",
                        label: "Calle y número",
                        labelEn: "Street and number",
                        labelClass: "",
                        labelDescription: "",
                        name: "addressShipmentWarehouse",
                        options: [],
                        placeHolder: "Ingresa el nombre de la calle y número",
                        type: constants.INPUT_TYPE_TEXT,
                        maxLength: "149",
                        customHandler: constants.INPUT_HANDLER_ONLY_LETTERS_NUMBERS_AND_SPACES,
                        validators: [
                            {
                                type: constants.VALIDATOR_NOT_EMPTY,
                                errorMessage: "Ingresa un nombre"
                            }
                        ]
                    }
                ]
            },
            {
                id: "4-3",
                title: "Dirección bodega de devolución",
                subTitle: "",
                enabled: false,
                showNavigation: false,
                navigationButtonId: AdobeAnalyticsButtonTags[3].returnWH,
                onRenderActions: {
                    subTitle: {
                        handler: "RenderSubTitle",
                        params: {
                            renderSubtitleWith: [
                                "addressReturnWarehouse",
                                "optionLabel_municipalReturnWarehouse",
                                "optionLabel_regionReturnWarehouse"
                            ],
                            subtitleSeparator: ", "
                        }
                    }
                },
                onNavigateActions: {
                    next: {
                        handler: constants.ACTION_HANDLER_RENDER_SUBTITLE_AND_ACTIVATE_PAGE_NAVIGATION,
                        params: {
                            currentSubSection: "4-3",
                            renderSubtitleWith: [
                                "addressReturnWarehouse",
                                "municipalReturnWarehouse",
                                "regionReturnWarehouse"
                                
                            ],
                            subtitleSeparator: ", "
                        }
                    },
                    previous: ""
                },
                fields: [
                    {
                        class: "search-icon",
                        containerClass: "",
                        default: "",
                        id: "regionReturnWarehouse",
                        label: "Región",
                        labelEn: "",
                        labelClass: "",
                        labelDescription: "",
                        name: "regionReturnWarehouse",
                        onChangeActions: [
                            {
                                handler: "loadOptions",
                                params: {
                                    targetId: "municipalReturnWarehouse",
                                    dataSource: {
                                        type: constants.DATASOURCE_TYPE_API,
                                        source: constants.SERVICE_BFF,
                                        endpoint: "faclRegionMunicipals",
                                        params: [
                                            {
                                                name: "regionId",
                                                sourceType: "FormFieldValue",
                                                sourceId: "regionReturnWarehouse"
                                            }
                                        ]
                                    }
                                }
                            }
                        ],
                        options: [],
                        placeHolder: "Ingresa una región",
                        type: constants.INPUT_TYPE_DROPDOWN_SEARCH,
                        dataSource: {
                            type: constants.DATASOURCE_TYPE_API,
                            source: constants.SERVICE_BFF,
                            endpoint: "regions",
                            params: [
                                {
                                    name: "countryCode",
                                    sourceType: "GlobalStateValue",
                                    sourceId: "country"
                                }
                            ]
                        },
                        validators: [
                            {
                                type: constants.VALIDATOR_NOT_EMPTY,
                                errorMessage: "Este campo es obligatorio, ingresa el número de documento de la empresa."
                            }
                        ]
                    },                  
                    {
                        class: "search-icon",
                        containerClass: "",
                        default: "",
                        id: "municipalReturnWarehouse",
                        label: "Comuna",
                        labelEn: "",
                        labelClass: "",
                        labelDescription: "",
                        name: "municipalReturnWarehouse",
                        options: [],
                        placeHolder: "Ingresa una comuna",
                        type: constants.INPUT_TYPE_DROPDOWN_SEARCH,
                        dataSource: {
                            type: constants.DATASOURCE_TYPE_API,
                            source: constants.SERVICE_BFF,
                            endpoint: "faclRegionMunicipals",
                            params: [
                                {
                                    name: "regionId",
                                    sourceType: "GlobalStateValue",
                                    sourceId: "regionReturnWarehouse"
                                }
                            ]
                        },
                        validators: [
                            {
                                type: constants.VALIDATOR_NOT_EMPTY,
                                errorMessage: "Este campo es obligatorio, ingresa el número de documento de la empresa."
                            }
                        ]
                    },
                    {
                        class: "",
                        containerClass: "",
                        dataSource: "",
                        default: "",
                        fakeValue: "",
                        id: "addressReturnWarehouse",
                        label: "Calle y número",
                        labelEn: "Street and number",
                        labelClass: "",
                        labelDescription: "",
                        name: "addressReturnWarehouse",
                        options: [],
                        placeHolder: "Ingresa el nombre de la calle y número",
                        type: constants.INPUT_TYPE_TEXT,
                        maxLength: "149",
                        customHandler: constants.INPUT_HANDLER_ONLY_LETTERS_NUMBERS_AND_SPACES,
                        validators: [
                            {
                                type: constants.VALIDATOR_NOT_EMPTY,
                                errorMessage: "Ingresa un nombre"
                            }
                        ]
                    }
                ]
            }
        ]
    },
    {
        section: {
            id: 5,
            title: "Declaraciones y condiciones",
            isActive: true,
            stepperNumber: 4,
            stepperLabel: "Declaraciones",
            type: "Accordion",
            activeSubSection: "5-1"
        },
        subSections: [
            {
                id: "5-1",
                title: "Conflicto de interés",
                subTitle: "",
                enabled: true,
                showNavigation: true,
                navigationButtonId: AdobeAnalyticsButtonTags[4].conflictInterest,
                onRenderActions: {
                    subTitle: {
                        handler: "RenderSubTitle",
                        params: {
                            renderSubtitleWith: [
                                "optionLabel_conflictOfInterest"
                            ],
                            subtitleSeparator: ", "
                        }
                    },
                    toggleFieldVisibility: {
                        handler: constants.ACTION_HANDLER_TOGGLE_VISIBILITY,
                        params: {
                            fieldsToToggle: [
                                "personNameWithConflictOfInterest",
                                "typeOfConflict"
                            ],
                            onStateValue: {
                                stateName: "conflictOfInterest",
                                stateValue: "Yes",
                                action: "show"
                            }
                        }
                    }
                },
                onNavigateActions: {
                    next: {
                        handler: constants.ACTION_HANDLER_FOCUS_SUBSECTION,
                        params: {
                            nextSubSection: "5-2"
                        }
                    },
                    previous: ""
                },
                fields: [
                    {
                        class: "rdb_orange ",
                        containerClass: "",
                        dataSource: "",
                        default: "",
                        id: "conflictOfInterest",
                        labelMandatory: "*",
                        label: "¿Alguna persona de la empresa tiene un conflicto de interés con alguien de Falabella, Linio, Sodimac o Tottus?",
                        labelClass: "",
                        labelDescription: "",
                        name: "conflictOfInterest",
                        options: [
                            {
                                id: "conflictOfInterest_1",
                                value: "Yes",
                                label: "Sí",
                                labelClass: "rdb_label",
                                onClickActions: [
                                    {
                                        handler: constants.ACTION_HANDLER_TOGGLE_VISIBILITY,
                                        params: {
                                            fieldsToToggle: [
                                                "personNameWithConflictOfInterest",
                                                "typeOfConflict"
                                            ],
                                           action: "show"
                                        }
                                    }
                                ]
                            },
                            {
                                id: "conflictOfInterest_2",
                                value: "No",
                                label: "No",
                                labelClass: "rdb_label",
                                onClickActions: [
                                    {
                                        handler: constants.ACTION_HANDLER_TOGGLE_VISIBILITY,
                                        params: {
                                            fieldsToToggle: [
                                                "personNameWithConflictOfInterest",
                                                "typeOfConflict"
                                            ],
                                           action: "hide"
                                        }
                                    }
                                ]
                            }
                        ],
                        placeHolder: "",
                        required: true,
                        type: constants.INPUT_TYPE_RADIO,
                        validators: [
                            {
                                type: constants.VALIDATOR_NOT_EMPTY,
                                errorMessage: "Por favor seleccione una opción"
                            }
                        ]
                    },
                    {
                        class: "",
                        containerClass: "hidden",
                        default: "",
                        id: "personNameWithConflictOfInterest",
                        label: "¿Cuál es el nombre completo de la persona en tu empresa?",
                        labelClass: "",
                        labelDescription: "",
                        name: "personNameWithConflictOfInterest",
                        placeHolder: "Ej. Juan",
                        isHidden: true,
                        type: constants.INPUT_TYPE_TEXT,
                        customHandler: constants.INPUT_HANDLER_ONLY_LETTERS_AND_SPACES,
                        validators: [
                            {
                                type: constants.VALIDATOR_NOT_EMPTY,
                                errorMessage: "Debes ingresar el nombre completo.",
                                ifIsChecked: "conflictOfInterest_1"
                            }
                        ]
                    },
                    {
                        class: "",
                        containerClass: "hidden",
                        dataSource: "",
                        default: "",
                        fakeValue: "",
                        id: "typeOfConflict",
                        label: "¿Cuál es la relación que tiene esa persona con alguien del Grupo Falabella?",
                        labelClass: "",
                        labelDescription: "",
                        name: "typeOfConflict",
                        options: [
                            {
                                id: "typeOfConflict_1",
                                label: "Selecciona una opción",
                                value: ""
                            },
                            {
                                id: "typeOfConflict_1",
                                label: "Abuela o abuelo",
                                value: "Abuela o abuelo"
                            },
                            {
                                id: "typeOfConflict_1",
                                label: "Hija o Hijo",
                                value: "Hija o Hijo"
                            },
                            {
                                id: "typeOfConflict_1",
                                label: "Hermana o Hermano",
                                value: "Hermana o Hermano"
                            },
                            {
                                id: "typeOfConflict_1",
                                label: "Madre o Padre",
                                value: "Madre o Padre"
                            },
                            {
                                id: "typeOfConflict_1",
                                label: "Nieta o Nieto",
                                value: "Nieta o Nieto"
                            },
                            {
                                id: "typeOfConflict_1",
                                label: "Cónyuge",
                                value: "Cónyuge"
                            },
                            {
                                id: "typeOfConflict_1",
                                label: "Soy yo",
                                value: "Soy yo"
                            }
                        ],
                        placeHolder: "",
                        required: true,
                        type: constants.INPUT_TYPE_DROPDOWN,
                        validators: [
                            {
                                type: constants.VALIDATOR_NOT_EMPTY,
                                errorMessage: "Este campo es obligatorio, selecciona una opción.",
                                ifIsChecked: "conflictOfInterest_1"
                            }
                        ]
                    }
                ]
            },
            {
                id: "5-2",
                title: "Persona Expuesta Políticamente",
                subTitle: "",
                enabled: false,
                showNavigation: true,
                navigationButtonId: AdobeAnalyticsButtonTags[4].personExpPol,
                onRenderActions: {
                    subTitle: {
                        handler: "RenderSubTitle",
                        params: {
                            renderSubtitleWith: [
                                "optionLabel_hasPepRelationship"
                            ],
                            subtitleSeparator: ", "
                        }
                    },
                    toggleFieldVisibility: {
                        handler: constants.ACTION_HANDLER_TOGGLE_VISIBILITY,
                        params: {
                            fieldsToToggle: [
                                "pepName",
                                "relationshipWithPep"
                            ],
                            onStateValue: {
                                stateName: "hasPepRelationship",
                                stateValue: "Yes",
                                action: "show"
                            }
                        }
                    }
                },
                onNavigateActions: {
                    next: {
                        handler: constants.ACTION_HANDLER_FOCUS_SUBSECTION,
                        params: {
                            nextSubSection: "5-3"
                        }
                    },
                    previous: ""
                },
                fields: [
                    {
                        class: "rdb_orange ",
                        containerClass: "",
                        dataSource: "",
                        default: "No",
                        id: "hasPepRelationship",
                        labelMandatory: "*",
                        label: "¿Alguna persona ejecutiva relevante de la empresa tiene relación con una Persona Políticamente Expuesta (PEP)?",
                        labelClass: "",
                        labelDescription: "",
                        name: "hasPepRelationship",
                        options: [
                            {
                                id: "hasPepRelationship_1",
                                value: "Yes",
                                label: "Sí",
                                labelClass: "rdb_label",
                                onClickActions: [
                                    {
                                        handler: constants.ACTION_HANDLER_TOGGLE_VISIBILITY,
                                        params: {
                                            fieldsToToggle: [
                                                "pepName",
                                                "relationshipWithPep"
                                            ],
                                           action: "show"
                                        }
                                    }
                                ]
                            },
                            {
                                id: "hasPepRelationship_2",
                                value: "No",
                                label: "No",
                                labelClass: "rdb_label",
                                onClickActions: [
                                    {
                                        handler: constants.ACTION_HANDLER_TOGGLE_VISIBILITY,
                                        params: {
                                            fieldsToToggle: [
                                                "pepName",
                                                "relationshipWithPep"
                                            ],
                                           action: "hide"
                                        }
                                    }
                                ]
                            }
                        ],
                        placeHolder: "",
                        required: true,
                        type: constants.INPUT_TYPE_RADIO,
                        validators: [
                            {
                                type: constants.VALIDATOR_NOT_EMPTY,
                                errorMessage: "Por favor seleccione una opción"
                            }
                        ]
                    },
                    {
                        class: "",
                        containerClass: "hidden",
                        dataSource: "",
                        default: "",
                        id: "pepName",
                        label: "¿Cuál es el nombre completo de la persona en tu empresa?",
                        labelClass: "",
                        labelDescription: "",
                        name: "pepName",
                        placeHolder: "Ej. Juan",
                        required: true,
                        type: constants.INPUT_TYPE_TEXT,
                        customHandler: constants.INPUT_HANDLER_ONLY_LETTERS_AND_SPACES,
                        validators: [
                            {
                                type: constants.VALIDATOR_NOT_EMPTY,
                                errorMessage: "Debes ingresar el nombre completo.",
                                ifIsChecked: "hasPepRelationship_1"
                            }
                        ]
                    },
                    {
                        class: "",
                        containerClass: "hidden",
                        dataSource: "",
                        default: "",
                        id: "relationshipWithPep",
                        label: "¿Cuál es la relación que tiene con la Persona Políticamente Expuesta (PEP)?",
                        labelClass: "",
                        labelDescription: "",
                        name: "relationshipWithPep",
                        options: [
                            {
                                id: "relationshipWithPep_1",
                                label: "Selecciona una opción",
                                value: ""
                            },
                            {
                                id: "relationshipWithPep_1",
                                label: "Abuela o abuelo",
                                value: "Abuela o abuelo"
                            },
                            {
                                id: "relationshipWithPep_1",
                                label: "Hija o Hijo",
                                value: "Hija o Hijo"
                            },
                            {
                                id: "relationshipWithPep_1",
                                label: "Hermana o Hermano",
                                value: "Hermana o Hermano"
                            },
                            {
                                id: "relationshipWithPep_1",
                                label: "Madre o Padre",
                                value: "Madre o Padre"
                            },
                            {
                                id: "relationshipWithPep_1",
                                label: "Nieta o Nieto",
                                value: "Nieta o Nieto"
                            },
                            {
                                id: "relationshipWithPep_1",
                                label: "Cónyuge",
                                value: "Cónyuge"
                            },
                            {
                                id: "relationshipWithPep_1",
                                label: "Soy yo",
                                value: "Soy yo"
                            }
                        ],
                        placeHolder: "",
                        required: true,
                        type: constants.INPUT_TYPE_DROPDOWN,
                        validators: [
                            {
                                type: constants.VALIDATOR_NOT_EMPTY,
                                errorMessage: "Este campo es obligatorio, selecciona una opción.",
                                ifIsChecked: "hasPepRelationship_1"
                            }
                        ]
                    }
                ]
            },
            {
                id: "5-3",
                title: "Prevención de delitos y libre competencia",
                subTitle: "",
                enabled: false,
                showNavigation: true,
                navigationButtonId: AdobeAnalyticsButtonTags[4].prevDelibComp,
                onRenderActions: {
                    toggleFieldVisibility: {
                        handler: constants.ACTION_HANDLER_TOGGLE_VISIBILITY,
                        params: {
                            fieldsToToggle: [
                                "crimePreventionObservation"
                            ],
                            onStateValue: {
                                stateName: "crimePrevention",
                                stateValue: "Yes",
                                action: "show"
                            }
                        }
                    }
                },
                onNavigateActions: {
                    next: {
                        handler: constants.ACTION_HANDLER_FOCUS_SUBSECTION,
                        params: {
                            nextSubSection: "5-4"
                        }
                    },
                    previous: ""
                },
                fields: [
                    {
                        class: "rdb_orange ",
                        containerClass: "",
                        default: "No",
                        id: "crimePrevention",
                        label: "",
                        labelClass: "",
                        labelDescription: "",
                        name: "crimePrevention",
                        options: [
                            {
                                id: "crimePrevention_1",
                                value: "No",
                                labelMandatory: "*",
                                label: "Declaro que ni yo, ni ninguna persona ejecutiva relevante de la empresa, ha tenido jamás la calidad de imputado y/o ha sido investigado, formalizado, acusado o condenado por ninguno de los delitos o acciones descritos en la ",
                                labelLink: {
                                    url: "https://sellercenter.falabella.com/templates/default/files/declaracion_prevencion_de_delitos_ley.pdf",
                                    class: "rdb_label_link",
                                    text: "Declaración de Cumplimiento.",
                                    target: "_blank"
                                },
                                labelClass: "rdb_label",
                                onClickActions: [
                                    {
                                        handler: constants.ACTION_HANDLER_TOGGLE_VISIBILITY,
                                        params: {
                                            fieldsToToggle: [
                                                "crimePreventionObservation"
                                            ],
                                           action: "hide"
                                        }
                                    }
                                ]
                            },
                            {
                                id: "crimePrevention_2",
                                value: "Yes",
                                labelMandatory: "*",
                                label: "Tengo dudas con este campo, prefiero dejar una observación.",
                                labelClass: "rdb_label",
                                onClickActions: [
                                    {
                                        handler: constants.ACTION_HANDLER_TOGGLE_VISIBILITY,
                                        params: {
                                            fieldsToToggle: [
                                                "crimePreventionObservation"
                                            ],
                                           action: "show"
                                        }
                                    }
                                ]
                            }
                        ],
                        placeHolder: "",
                        type: constants.INPUT_TYPE_RADIO,
                        validators: [
                            {
                                type: constants.VALIDATOR_NOT_EMPTY,
                                errorMessage: "Por favor seleccione una opción"
                            }
                        ]
                    },
                    {
                        class: "",
                        containerClass: "hidden",
                        dataSource: "",
                        default: "",
                        id: "crimePreventionObservation",
                        label: "Observación",
                        labelClass: "",
                        labelDescription: "",
                        name: "crimePreventionObservation",
                        placeHolder: "Ingresa tu observación para que podamos analizar el caso. ",
                        required: true,
                        type: constants.INPUT_TYPE_TEXT,
                        validators: [
                            {
                                type: constants.VALIDATOR_NOT_EMPTY,
                                errorMessage: "Para continuar debes ingresar una observación.",
                                ifIsChecked: "crimePrevention_2"
                            }
                        ]
                    },
                ]
            },
            {
                id: "5-4",
                title: "Términos y condiciones",
                subTitle: "",
                enabled: false,
                showNavigation: false,
                navigationButtonId: AdobeAnalyticsButtonTags[4].termsConditions,
                onRenderActions: {},
                onNavigateActions: {
                    next: {
                        handler: constants.ACTION_HANDLER_ACTIVATE_PAGE_NAVIGATION,
                        params: {
                            currentSubSection: "5-4"
                        }
                    },
                    previous: ""
                },
                fields: [
                    {
                        class: "cb_checkbox",
                        containerClass: "",
                        id: "termsAndConditions",
                        labelClass: "",
                        labelMandatory: "*",
                        labelDescription: "",
                        name: "termsAndConditions",
                        value: "Yes",
                        placeHolder: "Acepto ",                       
                        labelLink: {
                            "url": "https://sellercenter.falabella.com/terms_and_conditions_facl_es_facl",
                            "text": "los términos y condiciones", 
                            "target": "_blank",
                            "class": "rdb_label_link"
                        },
                        type: constants.INPUT_TYPE_CHECKBOX,
                        validators: [
                            {
                                type: constants.VALIDATOR_IS_CHECKED,
                                errorMessage: "Por favor acepte términos y condiciones"
                            }
                        ]
                    }
                ]
            }
        ]
    }
];

export default FormConfig;
