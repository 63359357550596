import React, { useState } from "react";
import { Form } from "react-bootstrap";

const AutoFillCheckbox = ({id, message, handleAutofillAction}) => {

    const [checked, setChecked] = useState(false);

    const handleChange = () => {
        handleAutofillAction(id, !checked);
        setChecked(!checked);
    }


    return (
        <Form.Group className="mb3" controlId={`autofill-${id}`} id={`container_${id}`}>
            <label htmlFor={`autofill-${id}`} className="autofill">
                <input
                    type="checkbox" 
                    className="form-check-input cb_checkbox"
                    name={`autofill-${id}`}
                    id={`autofill-${id}`}
                    label={message}
                    checked={checked}
                    onChange={handleChange}
                />
                <span className="cb_label_text">
                    {message}
                </span>
            </label>
        </Form.Group>
    )
}

export default AutoFillCheckbox;
