class CustomercareGps {
  constructor(latitude, longitude) {
    this.customercareGps = {
      latitude: latitude,
      longitude: longitude
    };
  }

  getData() {
    return this.customercareGps;
  }
}

export default CustomercareGps;
