import constants from "./constants";
import chileFlag from "../static/svgs/chileFlag.svg";
import { useTranslation } from "react-i18next";
import colombiaBankSection from "./CO/bankSection";
import taxPayerField from "./CO/taxPayerTypeField";
import { actionTypes } from "../utils/constants";

const FLOWS_ENABLED = JSON.parse(process.env.REACT_APP_FLOWS_ENABLED);
const BANK_FLOWS = JSON.parse(process.env.REACT_APP_BANK_FLOWS);
const DISCLAIMER_MESSAGE_ENABLED = JSON.parse(process.env.REACT_APP_DISCLAIMER_MESSAGE_ENABLED);
const DISCLAIMER_MESSAGE_CONFIGURATION = process.env.REACT_APP_DISCLAIMER_MESSAGE_CONFIGURATION ? JSON.parse(process.env.REACT_APP_DISCLAIMER_MESSAGE_CONFIGURATION) : null;
const LABEL = "label";
const VALUE = "value";

const COLOMBIA_VALID_OPTIONS = {
  LEGAL_PERSON: {
    [LABEL]: constants.LEGAL_PERSON_LABEL,
    [VALUE]: constants.LEGAL_PERSON_VALUE
  },
  NATURAL_PERSON: {
    [LABEL]: constants.NATURAL_PERSON_LABEL,
    [VALUE]: constants.NATURAL_PERSON_VALUE
  }
};

const BANK_SECTIONS = {
  CO: colombiaBankSection
};

export const getColombiaTaxpayerField = () => {
  return process.env.REACT_APP_SHOW_CO_TAXPAYER_FIELD === "true" ? taxPayerField : [];
};

export const getBankSectionPerCountry = country => {
  return isEnabledBankFlowPerCountry(country) ? BANK_SECTIONS[country] : [];
};

export const RETENTION_TYPE = {
  faco: {
    [constants.NATURAL_PERSON_LABEL]: {
      Yes: {
        9: "Persona natural régimen común"
      },
      No: {
        5: "Persona natural no responsable de IVA"
      }
    }
  }
};

export const getEnabledTypeOfPersonOptionsPerCountry = country => {
  let enabledTypesOfPerson = [];
  Object.entries(FLOWS_ENABLED[country]).forEach(([typeOfPerson, enabled]) => {
    if (enabled) {
      enabledTypesOfPerson.push(COLOMBIA_VALID_OPTIONS[typeOfPerson]);
    }
  });
  return enabledTypesOfPerson;
};

export const countryHaveAnyFlowDisabled = country => {
  if (country in FLOWS_ENABLED) {
    return Object.values(FLOWS_ENABLED[country]).some(enabled => !enabled);
  }
  return false;
};

export const getDisabledTypeOfPersonLabelPerCountry = country => {
  let enabledTypesOfPerson = [];
  Object.entries(FLOWS_ENABLED[country]).forEach(([typeOfPerson, enabled]) => {
    if (!enabled) {
      enabledTypesOfPerson.push(COLOMBIA_VALID_OPTIONS[typeOfPerson][LABEL]);
    }
  });

  return enabledTypesOfPerson.length === 1 ? enabledTypesOfPerson.toString() : enabledTypesOfPerson.join("o ");
};

export const alertFlowDisabledText = (country, linkClassName = "mustard-link") => {
  const flowDisabeld = getDisabledTypeOfPersonLabelPerCountry(country);

  if (flowDisabeld === COLOMBIA_VALID_OPTIONS.NATURAL_PERSON[LABEL]) {
    return `De momento el flujo de registro de persona natural se encuentra deshabilitado.`;
  }

  return `Seguimos trabajando en la nueva experiencia de registro. Si quieres vender como ${flowDisabeld}, <a href="${process.env.REACT_APP_OLD_FORM_URL}" class="${linkClassName}">ingresa al antiguo formulario.</a>`;
};

export const isMessageDisclaimerEnabledPerCountry = country => {
  if(country in DISCLAIMER_MESSAGE_ENABLED) {
    return DISCLAIMER_MESSAGE_ENABLED[country];
  }
  return false;
}

export const getBannedCategoriesPerCountry = country => {
  if(DISCLAIMER_MESSAGE_CONFIGURATION && country in DISCLAIMER_MESSAGE_CONFIGURATION){
    return DISCLAIMER_MESSAGE_CONFIGURATION[country]['bannedCategories'];
  }

  return constants.DEFAULT_DISCLAIMER_MESSAGE_CONFIGURATION[country]['bannedCategories'];
}

export const getExternalFormUrlPerCountry = country => {
  if(DISCLAIMER_MESSAGE_CONFIGURATION && country in DISCLAIMER_MESSAGE_CONFIGURATION){
    return DISCLAIMER_MESSAGE_CONFIGURATION[country]['formUrl'];
  }

  return constants.DEFAULT_DISCLAIMER_MESSAGE_CONFIGURATION[country]['formUrl'];
}

export const alertDisclaimerMessageText = (country, linkClassName = "mustard-link") => {
  const bannedCategories = getBannedCategoriesPerCountry(country);
  const externalFormUrl = getExternalFormUrlPerCountry(country);

  return `¡Gracias por tu interés en ser parte de falabella.com! Si estás interesado en vender productos en las categorías de ${bannedCategories}, completa el registro y adicional diligencia  <a href="${externalFormUrl}" class="${linkClassName}">este formulario</a> para que nuestros equipos evalúen tu solicitud. <br> Ten en cuenta que estas categorías solo serán habilitadas una vez tu solicitud sea aprobada.`;
};

const isEnabledBankFlowPerCountry = country => {
  if (country in BANK_SECTIONS && country in BANK_FLOWS) {
    return BANK_FLOWS[country];
  }

  return false;
};

const getCountryNameByCode = countryCode => {
  const validCountries = getAvailableCountries();
  const country = validCountries[countryCode];
  if (country === undefined || !country) {
    return "";
  }

  return country;
};

const getAvailableCountries = () => {
  let availableCountries = {};
  Object.entries(FLOWS_ENABLED).forEach(([country, options]) => {
    for (let key in options) {
      if (options.hasOwnProperty(key) && options[key]) {
        availableCountries[country] = constants.VALID_COUNTRIES[country];
        break;
      }
    }
  });

  return availableCountries;
};

const getValidCountryDropdownOptions = () => {
  let options = [];

  for (const countryCode in getAvailableCountries()) {
    const country = constants.VALID_COUNTRIES[countryCode];
    options.push({
      id: `countryCode_${country.countryCode}`,
      label: country.name,
      value: country.countryCode,
      thumbnail: country.logo
    });
  }

  return options;
};

const CountryConfigurations = defaultCountryCode => {
  const { t } = useTranslation();
  let defaultCountry = "";
  let defaultCountryName = t("country.selectCountry");
  let defaultCountryThumbnail = chileFlag;

  if (defaultCountryCode !== undefined) {
    defaultCountry = getCountryNameByCode(defaultCountryCode);
  }

  if (defaultCountry === undefined || defaultCountry === "") {
    defaultCountryCode = 0;
  } else {
    defaultCountryName = defaultCountry.name;
    defaultCountryThumbnail = defaultCountry.logo;
  }

  const countrySelectorConfig = {
    class: "",
    containerClass: "field_group_inline_left width_15",
    dataSource: "",
    default: "+91",
    fakeValue: "",
    id: "countryCode",
    label: "",
    labelEn: "",
    labelClass: "",
    labelDescription: "",
    name: "countryCode",
    options: getValidCountryDropdownOptions(),
    defaultOption: {
      id: "country-selector",
      label: defaultCountryName,
      value: defaultCountryCode,
      thumbnail: defaultCountryThumbnail
    },
    order: 1,
    placeHolder: "Select country",
    regex: "",
    required: true,
    type: constants.INPUT_TYPE_THUMBNAILDROPDOWN,
    validators: []
  };

  return countrySelectorConfig;
};

export const TypeOfPersonConfigurations = countrySelected => {
  const personSelectorConfig = {
    class: "",
    containerClass: "field_group_inline_left width_15",
    dataSource: "",
    default: "",
    fakeValue: "",
    id: constants.TYPE_OF_PERSON,
    label: "",
    labelEn: "",
    labelClass: "",
    labelDescription: "",
    name: constants.TYPE_OF_PERSON,
    options: getEnabledTypeOfPersonOptionsPerCountry(countrySelected),
    defaultOption: {},
    order: 1,
    placeHolder: "Select option",
    regex: "",
    required: true,
    type: constants.INPUT_TYPE_DROPDOWN,
    validators: [
      {
        type: constants.VALIDATOR_NOT_EMPTY,
        errorMessage: "Debes seleccionar una opción."
      }
    ],
    [actionTypes.RESET_GLOBAL_STATE]: true
  };

  return personSelectorConfig;
};

export default CountryConfigurations;
