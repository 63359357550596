import serviceTest from "./serviceTest";
import serviceBff from "./serviceBff";
import constants from "../../config/constants";

const getParams = dataSource => {
    let params = {};

    dataSource.params.map((param) => {
        switch (param.sourceType) {
            case constants.PARAM_TYPE_STATIC_VALUE:
                params[param.name] = param.value;
                break;
            case constants.PARAM_TYPE_GLOBAL_STATE_VALUE:
                params[param.name] = (dataSource.globalStateValues[param.sourceId]!== undefined) ? dataSource.globalStateValues[param.sourceId] : "";
                break;
            default:
                return false;
        }

        return false;
    });

    return params;
}

const fetchSourceData =  async dataSource => {
    const inputData = getParams(dataSource);
    switch (dataSource.source) {
        case constants.SERVICE_TEST:
            return await serviceTest(dataSource.endpoint, inputData);
        case constants.SERVICE_BFF:
            return await serviceBff(dataSource.endpoint, inputData);
        default:
            return "";
    }
}

const dataSourceAggregator = async dataSource => {
    switch (dataSource.type) {
        case constants.DATASOURCE_TYPE_JSON:
            return (dataSource.source !== undefined) ? dataSource.source : [];
        case constants.DATASOURCE_TYPE_API:
            return await fetchSourceData(dataSource);
        default:
            return "";
    }
}

export default dataSourceAggregator;