import React, { useState } from "react";
import { Form } from "react-bootstrap";
import { useStateValue } from "../../../store/StateProvider";
import DropdownSearchControl from "../InputControls/DropdownSearchControl";
import { getSellerCreationErrors, getFieldValidationErrors } from "../../../utils/validator";

const InputDropdownSearch = ({ fieldConfig }) => {
  const [{ hiddenFields, sellerCreationErrors, formValues }] = useStateValue();
  const [errorMessage, setErrorMessage] = useState(getSellerCreationErrors(fieldConfig.id, sellerCreationErrors));

  const labelClass = `input_label_plain ${fieldConfig.labelClass}`;

  const setError = error => {
    setErrorMessage(error);
  };

  const executeFieldValidation = () => {
    const error = getFieldValidationErrors(fieldConfig.validators, formValues, fieldConfig.id);
    setError(error);
  };

  return (
    <Form.Group
      className={`mb3 ${fieldConfig.containerClass} ${hiddenFields.includes(fieldConfig.id) ? 'hidden' : ''}`}
      id={`container_${fieldConfig.id}`}
      controlId={fieldConfig.name}
    >
      <Form.Label className={labelClass}>{fieldConfig.label}</Form.Label>
      <Form.Label className="label_description">{fieldConfig.labelDescription}</Form.Label>
      <DropdownSearchControl
        fieldConfig={fieldConfig}
        fieldValidator={executeFieldValidation}
        removeErrorMessage={setError.bind(this, null)}
        hasError={!!errorMessage}
      />
      <Form.Label className={`label_error ${!!errorMessage ? "show" : "hidden"}`} id={`label_error_${fieldConfig.id}`}>
        <span id={`label_error_message_${fieldConfig.id}`}>{errorMessage}</span>
      </Form.Label>
    </Form.Group>
  );
};

export default InputDropdownSearch;
